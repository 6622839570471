import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import Swal from 'sweetalert2';
import {ApiService} from '@services/api.service';
import {ZoneService} from '@pages/seating-zone/zone.service';

@Component({
  selector: 'app-seating-zone-list',
  templateUrl: './seating-zone-list.component.html',
  styleUrls: ['./seating-zone-list.component.scss']
})
export class SeatingZoneListComponent implements OnInit, OnDestroy {
  isInitialLoading: boolean = false;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  zoneList: {zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string}[] = [];

  constructor(private apiService: ApiService, private zoneService: ZoneService) { }

  ngOnInit(): void {
    this.isInitialLoading = true;
    this.dtOptions ={
      responsive: true,
      autoWidth: false,
      columnDefs: [
        { responsivePriority: 0, targets: -1 }
      ],
      dom: 'Bfrtipl',
      buttons: ["copy",
        {
          extend: 'csvHtml5',
          title: 'MRS_All_Zones'
        },
        {
          extend: 'excelHtml5',
          title: 'MRS_All_Zones'
        },
        {
          extend: 'pdfHtml5',
          messageBottom: 'Church of the Visitation Seremban. All Rights Reserved.',
          title: 'MRS_All_Zones'
        },
        "print",
        "colvis"]
    }
    this.apiService.fetchAllSeatingZones().subscribe(resData =>{
      this.isInitialLoading = false;
      this.zoneService.setZones(resData);
      this.zoneList = this.zoneService.getZones();
      this.dtTrigger.next();
    }, errorMessage => {
      this.isInitialLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
