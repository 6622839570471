import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-seating-zone',
  templateUrl: './seating-zone.component.html',
  styleUrls: ['./seating-zone.component.scss']
})
export class SeatingZoneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
