<div class="card card-outline card-primary">
    <div class="card-header text-center">
      <a [routerLink]="['/']" class='h1'><b>MRS</b>admin</a>
      <p class='mt-0'>Church of the Visitation Seremban</p>
    </div>
    <div class="card-body">
        <p class="login-box-msg">
            You forgot your password? <br>Contact Julian Yong to reset.
        </p>
<!--        <form [formGroup]="forgotPasswordForm" (ngSubmit)="forgotPassword()">-->
<!--            <div class="input-group mb-3">-->
<!--                <input-->
<!--                    formControlName="email"-->
<!--                    type="email"-->
<!--                    class="form-control"-->
<!--                    placeholder="Email"-->
<!--                />-->
<!--                <div class="input-group-append">-->
<!--                    <div class="input-group-text">-->
<!--                        <span class="fas fa-envelope"></span>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="row">-->
<!--                <div class="col-12">-->
<!--                    <app-button [type]="'submit'" [block]="true">-->
<!--                        Request new password-->
<!--                    </app-button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </form>-->
        <p class="mt-3 mb-1">
            <a [routerLink]="['/login']">Login</a>
        </p>
    </div>
</div>
