<div class='card card-outline card-primary'>
  <div class='card-header text-center'>
    <img class='img-fluid mb-2'
      [src]="'assets/img/COV_Logo_3.png'"
      style='width: 200px'
    /><br>
    <h1><b>MRS</b>admin</h1>
    <p class='mt-0'>Mass Registration System Admin Portal</p>
  </div>
  <div class='card-body'>
    <p class='login-box-msg'>Sign in with your credentials</p>

    <form [formGroup]='loginForm' (ngSubmit)='loginByAuth()'>
      <div class='input-group mb-3' [hidden]='loginData'>
        <input
          formControlName='email'
          type='email'
          class='form-control'
          placeholder='Email'
          #email
        />
        <div class='input-group-append'>
          <div class='input-group-text'>
            <span class='fas fa-envelope'></span>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf='loginData' style='text-align: center'>
        <p class='mb-0'><b>{{ loginData.name }}</b></p>
        <span class="badge badge-pill badge-info">{{ loginData.email }}</span>
        <input type="hidden" formControlName='hiddenEmail' id='hiddenEmail'>
      </div>
      <div class='input-group mb-3'>
        <input
          formControlName='password'
          type='password'
          class='form-control'
          placeholder='Password'
          #password
        />
        <div class='input-group-append'>
          <div class='input-group-text'>
            <span class='fas fa-lock'></span>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class='col-7'>
          <p class='mb-1'>
            <a [routerLink]="['/forgot-password']">I forgot my password</a>
          </p>
          <p class='mb-1' *ngIf='loginData'>
            <a [href]='' style='cursor: pointer' (click)='switchUser()' class='mr-auto'><i class='fas fa-exchange-alt'></i> Switch User</a>
          </p>
        </div>
        <!-- /.col -->
        <div class='col-5'>
          <app-button
            [type]="'submit'"
            [block]='true'
            [loading]='isAuthLoading'
          >
            Sign In
          </app-button>
        </div>
        <!-- /.col -->
      </div>
    </form>
    <p class='mt-3 mb-0 fa-pull-right text-muted'>
      Version: 2.2
    </p>
  </div>
  <!-- /.login-card-body -->
</div>
