import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApiService} from '@services/api.service';
import {Mass} from '@/model/mass.model';
import {map, tap} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {MassService} from '@pages/dashboard/mass.service';
import {Subscription} from 'rxjs';
import {User} from '@/model/user.model';
import {AppService} from '@services/app.service';

@Component({
  selector: 'app-mass-detail',
  templateUrl: './mass-detail.component.html',
  styleUrls: ['./mass-detail.component.scss']
})
export class MassDetailComponent implements OnInit, OnDestroy {
  mass: Mass;
  user: User;
  massid: string;
  massRegisteredSubscription: Subscription;
  massRegistered: number;
  dateToday: Date;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router, private massService: MassService,
              private appService: AppService) { }

  ngOnDestroy(): void {
        this.massRegisteredSubscription.unsubscribe();
    }

  ngOnInit(): void {
    this.dateToday = new Date();
    this.user = this.appService.user.getValue();
    this.route.params.subscribe(
      (params: Params) =>{
        this.massid = params.massid;
        this.getMassDetails();
      })
    this.massRegisteredSubscription = this.massService.massRegistered.subscribe(resData =>{
      this.massRegistered = resData;
    })
  }

  onClose(){
    this.router.navigate(['/admin/dashboard']);
  }

  onEditMass(){
    this.router.navigate(['edit'], {relativeTo: this.route});
  }

  getMassDetails(){
    this.apiService.fetchSingleMass(this.massid).pipe(map(
      mass => {
        return {...mass};
      }
      ),
      tap(mass =>{
        this.mass = mass;
      })).subscribe(resData => {
        this.massService.setCurrentSelectedMass(this.mass);
    }, errorMessage => {
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then((result) => {
        this.router.navigate(['/admin/dashboard']);
      });
    });
  }

  disableGenerateReport(mass_datetime: string){
    return  new Date() < new Date(mass_datetime);
  }

}
