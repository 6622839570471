<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class='row'>
      <div class='col-lg-12 col-12'>
        <div class='card'>
          <img [src]="'assets/img/seating-zone.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
          <div class="card-img-overlay">
            <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>Tags</h1>
            <ol class='breadcrumb float-sm-left'>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
              <li class="breadcrumb-item active">Tags</li>
            </ol>
          </div>
          <div class='card-body' style='z-index: 20; position: relative'>
            <app-loading-news *ngIf='isInitialLoading'></app-loading-news>
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped dataTable dtr-inline">
              <thead>
              <tr>
                <th>ID</th>
                <th>Tag Name</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let tag of tagList; let i = index">
                <td>{{ tag.tag_id }}</td>
                <td>{{ tag.tag_name }}</td>
                <td><button class='btn btn-primary mr-2 mb-1' (click)='open(content, tag.tag_id)'>Edit</button>
                  <button class='btn btn-danger' (click)='onDeleteTag(tag.tag_id)'>
                    <i class="fas fa-trash-alt"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <button mat-fab color="primary" style='position: fixed; bottom: 5rem; right: 2rem; z-index: 1000' (click)='open(content, "-1")'>
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modalTag">{{ editMode ? 'Update Tag' : 'Create Tag' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeUpdateTagModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]='updateTagForm' autocomplete='off'>
      <div class='form-group'>
        <label for='tag_name'>Tag Name <span style='color: red'>*</span></label>
        <input type='text' id='tag_name' formControlName='tag_name' class='form-control'>
      </div>
      <div class='alert alert-default-info' *ngIf='!updateTagForm.valid'>
        Form is not valid. Please check the form before saving.
      </div>
      <div class='alert alert-default-info' *ngIf='updateTagForm.valid && !updateTagForm.dirty'>
        There are no changes made yet.
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <app-button
      [type]="'button'"
      [block]='false'
      [color]="'primary'"
      [loading]='isUpdateLoading'
      [disabled]='!updateTagForm.valid || !updateTagForm.dirty'
      (click)='onUpdateTag()'>
      {{ editMode ? 'Update Tag' : 'Create Tag' }}
    </app-button>
<!--    <button type="button" class="btn btn-primary" (click)="onUpdateTag()" [disabled]='!updateTagForm.valid || !updateTagForm.dirty'>{{ editMode ? 'Update Tag' : 'Create Tag' }}</button>-->
    <button type="button" class="btn btn-outline-dark" (click)="closeUpdateTagModal()">Close</button>
  </div>
</ng-template>
