<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <app-loading-news *ngIf='isLoading'></app-loading-news>
    <div class='row' >
      <div class='col-lg-12 col-12'>
        <div class='card card-primary'>
          <img [src]="'assets/img/mass-management.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
          <div class="card-img-overlay" *ngIf='mass || !editMode'>
            <div class='row'>
              <div class='col-lg-6 col-8'>
                <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>{{ editMode ? 'Edit Mass' : 'Create Mass' }}</h1>
              </div>
              <div class='col-lg-6 col-4'>
                <button type="button" class="btn btn-light fa-pull-right" title="Remove" (click)='onClose()'>
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div class='d-none d-sm-block'>
              <ol class='breadcrumb float-sm-left'>
                <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Dashboard (All Masses)</a></li>
                <li class="breadcrumb-item" *ngIf='mass && editMode'><a [routerLink]="['../']">{{ mass.mass_desc }}</a></li>
                <li class="breadcrumb-item active">{{ editMode ? 'Edit Mass' : 'Create Mass' }}</li>
              </ol>
            </div>
          </div>
<!--          <div class='card-body' style='z-index: 20; position: relative'>-->
          <div class='card-header' *ngIf='mass && editMode'>
            <h3 class='card-title'>You are currently editing:</h3>
          </div>
          <!-- /.card-header -->
          <div class='card-body' *ngIf='mass && editMode'>
            <div class='row'>
              <div class='col-lg-5 col-12'>
                <strong><i class='fas fa-align-justify mr-1'></i> Description</strong>

                <p class='text-muted'>
                  {{ mass.mass_desc }}
                </p>
              </div>

              <div class='col-lg-4 col-12'>
                <strong><i class='fas fa-language mr-1'></i> Language</strong>

                <p class='text-muted'>
                  {{ mass.mass_lang }}
                </p>
              </div>

              <div class='col-lg-3 col-12'>
                <strong><i class='fas fa-calendar-alt mr-1'></i> Date & Time</strong>

                <p class='text-muted'>
                  {{ mass.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}
                </p>
              </div>
            </div>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
      <div class='col-lg-12 col-12' *ngIf='!isLoading'>
        <form [formGroup]='editMassForm' (ngSubmit)='onSubmit()'>
          <div class='row'>
            <div class='col-lg-4'>
              <div class='card card-primary'>
                <div class='card-body' style='display: block;'>
                  <h5>Basic Details</h5>
                  <div class='form-group'>
                    <label for='mass_desc'>Description <span style='color: red'>*</span></label>
                    <input type='text' id='mass_desc' formControlName='mass_desc' class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_lang'>Language <span style='color: red'>*</span></label>
                    <input type='text' id='mass_lang' formControlName='mass_lang' class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_datetime'>Date & Time <span style='color: red'>*</span></label>
                    <input type='datetime-local' id='mass_datetime' formControlName='mass_datetime' class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_prefix'>Prefix <span style='color: red'>*</span></label>
                    <input type='text' id='mass_prefix' formControlName='mass_prefix' class='form-control'>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
            <div class='col-lg-4'>
              <div class='card card-primary'>
                <div class='card-body' style='display: block;'>
                  <h5>Settings</h5>
                  <div class='form-group'>
                    <label for='mass_visibility'>Visibility <span style='color: red'>*</span></label>
                    <select class='custom-select' id='mass_visibility' formControlName='mass_visibility'>
                      <option value='' disabled>Choose mass visibility</option>
                      <option *ngFor='let visibility of visibilities'
                              [ngValue]='visibility'>{{visibility == 'P' ? 'Public' : 'Specific Group'}}</option>
                    </select>
                  </div>
                  <div class='form-group'>
                    <label for='mass_available'>Registration Availability <span style='color: red'>*</span></label>
                    <select class='custom-select' id='mass_available' formControlName='mass_available'>
                      <option value='' disabled>Choose mass availability</option>
                      <option *ngFor='let decision of yesNo'
                              [ngValue]='decision'>{{decision == 'Y' ? 'Available' : 'Not Available'}}</option>
                    </select>
                  </div>
                  <div class='form-group'>
                    <label for='mass_volunteer'>Volunteer Registration Availability <span style='color: red'>*</span></label>
                    <select class='custom-select' id='mass_volunteer' formControlName='mass_volunteer'>
                      <option value='' disabled>Choose an option</option>
                      <option *ngFor='let decision of yesNo'
                              [ngValue]='decision'>{{decision == 'Y' ? 'Available' : 'Not Available'}}</option>
                    </select>
                  </div>
                  <div class='form-group'>
                    <label for='mass_checkin'>Check-In Availability <span style='color: red'>*</span></label>
                    <select class='custom-select' id='mass_checkin' formControlName='mass_checkin'>
                      <option value='' disabled>Choose an option</option>
                      <option *ngFor='let decision of yesNo'
                              [ngValue]='decision'>{{decision == 'Y' ? 'Available' : 'Not Available'}}</option>
                    </select>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
            <div class='col-lg-4'>
              <div class='card card-primary'>
                <div class='card-body' style='display: block;'>
                  <h5>Constraints</h5>
                  <div class='form-group'>
                    <label for='mass_maxcap'>Maximum Capacity <span style='color: red'>*</span></label>
                    <input type='text' id='mass_maxcap' formControlName='mass_maxcap' class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_lastmincap'>Walk-In Capacity <span style='color: red'>*</span></label>
                    <input type='text' id='mass_lastmincap' formControlName='mass_lastmincap' class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_minage'>Minimum Age <span style='color: red'>*</span></label>
                    <input type='text' id='mass_minage' formControlName='mass_minage' class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_maxage'>Maximum Age <span style='color: red'>*</span></label>
                    <input type='text' id='mass_maxage' formControlName='mass_maxage' class='form-control'>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
            <div class='col-lg-4'>
              <div class='card card-primary'>
                <div class='card-body' style='display: block;'>
                  <h5>Automation</h5>
                  <div class='form-group'>
                    <label for='mass_startdatetime'>Registration Start <span style='color: red'>*</span></label>
                    <input type='datetime-local' id='mass_startdatetime' formControlName='mass_startdatetime'
                           class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_enddatetime'>Registration End <span style='color: red'>*</span></label>
                    <input type='datetime-local' id='mass_enddatetime' formControlName='mass_enddatetime'
                           class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_selfcheckstartdatetime'>Self Check-in Start <span style='color: red'>*</span></label>
                    <input type='datetime-local' id='mass_selfcheckstartdatetime' formControlName='mass_selfcheckstartdatetime'
                           class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_selfcheckenddatetime'>Self Check-in End <span style='color: red'>*</span></label>
                    <input type='datetime-local' id='mass_selfcheckenddatetime' formControlName='mass_selfcheckenddatetime'
                           class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_startcheckin'>Check-In Start <span style='color: red'>*</span></label>
                    <input type='datetime-local' id='mass_startcheckin' formControlName='mass_startcheckin'
                           class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_endcheckin'>Check-In End <span style='color: red'>*</span></label>
                    <input type='datetime-local' id='mass_endcheckin' formControlName='mass_endcheckin'
                           class='form-control'>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>

            </div>
            <div class='col-lg-4'>
              <div class='card card-primary'>
                <div class='card-body' style='display: block;'>
                  <h5>Descriptive Text</h5>
                  <div class='form-group'>
                    <label for='mass_starttext'>Registration Start Text</label>
                    <input type='text' id='mass_starttext' formControlName='mass_starttext' class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_endtext'>Registration End Text</label>
                    <input type='text' id='mass_endtext' formControlName='mass_endtext' class='form-control'>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
            <div class='col-lg-4'>
              <div class='card card-primary'>
                <div class='card-body' style='display: block;'>
                  <h5>Additional Settings</h5>
                  <div class='form-group'>
                    <label for='mass_checkinbuffer'>Check-In Buffer <span style='color: red'>*</span></label>
                    <input type='text' id='mass_checkinbuffer' formControlName='mass_checkinbuffer' class='form-control'>
                  </div>
                  <div class='form-group'>
                    <label for='mass_allowcancel'>Allow Self-Cancellation <span style='color: red'>*</span></label>
                    <select class='custom-select' id='mass_allowcancel' formControlName='mass_allowcancel'>
                      <option value='' disabled>Choose an option</option>
                      <option *ngFor='let decision of yesNo'
                              [ngValue]='decision'>{{decision == 'Y' ? 'Yes' : 'No'}}</option>
                    </select>
                  </div>
                  <div class='form-group'>
                    <label for='mass_api'>API Endpoint <span style='color: red'>*</span></label>
                    <select class='custom-select' id='mass_api' formControlName='mass_api'>
                      <option value='' disabled>Choose an option</option>
                      <option *ngFor='let decision of yesNo'
                              [ngValue]='decision'>{{decision == 'Y' ? 'On' : 'Off'}}</option>
                    </select>
                  </div>
                  <div class='form-group'>
                    <label for='mass_archived'>Archived <span style='color: red'>*</span></label>
                    <select class='custom-select' id='mass_archived' formControlName='mass_archived'>
                      <option value='' disabled>Choose an option</option>
                      <option *ngFor='let decision of yesNo'
                              [ngValue]='decision'>{{decision == 'Y' ? 'Yes' : 'No'}}</option>
                    </select>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
              <div class='card card-primary'>
                <div class='card-body' style='display: block;'>
                  <h5>Actions</h5>
                  <div class='row'>
                    <div class='col-lg-12 mb-3' *ngIf='!this.editMode'>
                      <button type='button' class='btn btn-block btn-info' (click)='open()'>Copy Previous Settings</button>
                    </div>
                  </div>
                  <div class='alert alert-default-info' *ngIf='!editMassForm.valid'>
                    Form is not valid. Please check the form before updating.
                  </div>
                  <div class='alert alert-default-info' *ngIf='editMassForm.valid && !editMassForm.dirty'>
                    There are no changes made yet.
                  </div>
                  <!--              <button type='submit' class='btn btn-success mr-3' [disabled]='!editMassForm.valid'>Update</button>-->
                  <div class='row'>
                    <div class='col-lg-6'>
                      <app-button class='mb-1'
                        [type]="'submit'"
                        [block]='true'
                        [color]="'success'"
                        [loading]='isUpdateLoading'
                        [disabled]='!editMassForm.valid || !editMassForm.dirty && !copyMassMode'>
                        Save
                      </app-button>
                    </div>
                    <div class='col-lg-6'>
                      <button type='button' class='btn btn-block btn-danger' (click)='onClose()'>Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
              <a [routerLink]="" (click)="onDelete()" *ngIf='editMode' class='mb-2 fa-pull-right'>Delete This Mass</a>
            </div>
          </div>


        </form>
      </div>
      <div class='col-lg-12 col-12' *ngIf='editMode; else warningMessage'>
        <div class='card'>
          <div class='card-body'>
            <h5>Those who have registered for this Mass cannot register for:</h5>
            <table class="table" *ngIf='massRestrictions.length > 0'>
              <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Restricted Mass</th>
                <th style="width: 20px">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor='let mass of massRestrictions; let i = index'>
                <td>{{ i + 1 }}.</td>
                <td>{{ mass.mass_desc }} ({{mass.mass_lang}}) - {{ mass.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}</td>
                <td><button class='btn btn-danger' (click)='onDeleteMassRestriction(mass.restrict_mass_id)'><i class="fas fa-trash-alt"></i></button></td>
              </tr>
              </tbody>
            </table>
            <div style="text-align: center;" *ngIf='massRestrictions.length == 0'>
              <img [src]="'assets/img/noresfound.gif'" height="80" class="mb-2" alt="" loading="lazy">
              <h5>Mass has no restrictions</h5>
            </div>
            <button class='btn btn-primary btn-block' (click)='showRestrictionForm()' *ngIf='!massRestrictionFormVisible'>Assign New Restriction</button>
            <form [formGroup]='massRestrictionForm' (ngSubmit)='onAssignMassRestriction()' *ngIf='massRestrictionFormVisible' autocomplete='off'>
              <div class='form-group'>
                <label for='mass_restriction'>Please Select</label>
                <select class='custom-select' id='mass_restriction' formControlName='mass_restriction'>
                  <option value='' disabled>Choose an option</option>
                  <option *ngFor='let mass of availableMassRestriction'
                          [ngValue]='mass.mass_id'>{{ mass.mass_desc }} ({{mass.mass_lang}}) - {{ mass.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}</option>
                </select>
              </div>
              <div class='row fa-pull-right'>
                <div class='col-lg-6 col-6'>
                  <app-button
                    [type]="'submit'"
                    [block]='false'
                    [color]="'success'"
                    [loading]='isAssignLoading'
                    [disabled]='!massRestrictionForm.valid || !massRestrictionForm.dirty'>
                    Save
                  </app-button>
                </div>
                <div class='col-lg-6 col-6'>
                  <button type='button' class='btn btn-danger' (click)='onCloseMassRestriction()'>Cancel</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      <ng-template #warningMessage>
        <div class='col-lg-12 col-12'>
          <div class='card'>
            <div class='card-body'>
              Mass Restrictions can only be assigned after mass is created.
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modalCopyMassDetails">Create Mass with previous settings</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeCopyMassModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]='copyMassForm' autocomplete='off'>
      <div class='form-group'>
        <label for='archived_mass'>Please Select</label>
        <select class='custom-select' id='archived_mass' formControlName='archived_mass'>
          <option value='' disabled>Choose an option</option>
          <option *ngFor='let mass of allMasses; let i = index'
                  [ngValue]=i>{{ mass.mass_desc }} ({{mass.mass_lang}}) - {{ mass.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}</option>
        </select>
      </div>
      <div class='alert alert-default-info' *ngIf='!copyMassForm.valid'>
        Form is not valid. Please check the form before saving.
      </div>
      <div class='alert alert-default-info' *ngIf='copyMassForm.valid && !copyMassForm.dirty'>
        There are no changes made yet.
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <app-button
      [type]="'button'"
      [block]='false'
      [color]="'primary'"
      [loading]='isUpdateLoading'
      [disabled]='!copyMassForm.valid || !copyMassForm.dirty'
      (click)='onCopyMass()'>
      Copy Settings
    </app-button>
    <!--    <button type="button" class="btn btn-primary" (click)="onUpdateTag()" [disabled]='!updateTagForm.valid || !updateTagForm.dirty'>{{ editMode ? 'Update Tag' : 'Create Tag' }}</button>-->
    <button type="button" class="btn btn-outline-dark" (click)="closeCopyMassModal()">Use Blank Form</button>
  </div>
</ng-template>

