import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {ActivatedRoute, Params, Router} from '@angular/router';
import { v4 as uuidv4 } from 'uuid';
import {ApiService} from '@services/api.service';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  editMode = false;
  editUserForm: FormGroup;

  assignUserLevelForm: FormGroup;
  userLevelFormVisible: boolean = false;
  availableUserLevel: {level_id: string, level_desc: string}[]= [];
  isAssignLevelLoading: boolean = false;

  assignUserGroupForm: FormGroup;
  userGroupFormVisible: boolean = false;
  availableUserGroup: {vg_id: string, vg_name: string}[]= [];
  isAssignGroupLoading: boolean = false;

  isUpdateLoading: boolean = false;
  userid: string;
  userProfile: {user_name: string, user_phone: string, user_email: string, user_password: string, user_accountstatus: string};
  userAccessLevel: {level_id: string, level_desc: string}[] = [];
  userVolunteerGroups: {vg_id: string, vg_name: string, vg_desc: string}[] = [];
  accountstatus = ['A', 'D'];

  changeUserPasswordForm: FormGroup;
  changePasswordModalRef : NgbModalRef;

  constructor(private router: Router, private route: ActivatedRoute, private apiService: ApiService, private modalService: NgbModal, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => {
        this.userid = params.userid;
        this.editMode = params.userid != null;
        if(this.editMode){
          this.apiService.fetchSingleUserDetails(this.userid).subscribe(resData => {
            this.userProfile = {"user_name" : resData.user_name, "user_phone": resData.user_phone, "user_email": resData.user_email, "user_password": resData.user_password,
              "user_accountstatus": resData.user_accountstatus};
            this.getUserAccessLevels();
            this.getUserVolunteerGroups();
            this.initForm();
          }, errorMessage => {
            Swal.fire('Opps...', errorMessage.error.message, 'error').then(() => {
              this.router.navigate(['/admin/user-management']);
            })
            console.log(errorMessage.error.message);
          });
        }else{
          this.initForm();
        }
      }
    )
  }

  private getUserAccessLevels(){
    this.apiService.fetchSingleUserAccessLevels(this.userid).subscribe(resData =>{
      this.userAccessLevel = resData;
    }, errorMessage => {
      Swal.fire('Opps...', errorMessage.error.message, 'error').then(() => {
        this.router.navigate(['/admin/user-management']);
      })
      console.log(errorMessage.error.message);
    })
  }

  private getUserVolunteerGroups(){
    this.apiService.fetchSingleUserVolunteerGroups(this.userid).subscribe(resData =>{
      this.userVolunteerGroups = resData;
    }, errorMessage => {
      Swal.fire('Opps...', errorMessage.error.message, 'error').then(() => {
        this.router.navigate(['/admin/user-management']);
      })
      console.log(errorMessage.error.message);
    })
  }

  private initForm(){
    let user_name = '';
    let user_phone = '+6';
    let user_email = '';
    let user_password = '';
    let user_accountstatus = '';
    if(this.editMode){
      user_name = this.userProfile.user_name;
      user_phone = this.userProfile.user_phone;
      user_email = this.userProfile.user_email;
      user_password = this.userProfile.user_password;
      user_accountstatus = this.userProfile.user_accountstatus;
      this.changeUserPasswordForm = new FormGroup({
        user_changePassword: new FormControl(''),
        user_confirmPassword: new FormControl('')
      })
    }
    this.editUserForm = new FormGroup({
      user_name: new FormControl(user_name, [Validators.required, Validators.minLength(6)]),
      user_phone: new FormControl(user_phone, [Validators.required]),
      user_email: new FormControl(user_email, [Validators.required, Validators.email]),
      user_password: new FormControl(user_password, [Validators.required, Validators.minLength(6)]),
      user_accountstatus: new FormControl(user_accountstatus, Validators.required),
    });
  }

  onSubmit(){
    if (/\+60[0-9]{8,9}/g.test(this.editUserForm.value.user_phone) == false) {
      Swal.fire({
        title: 'Opps...',
        html: 'Please enter a valid phone number. <br><strong>Guidelines:</strong><br> - Please do not include spaces<br> - Landline Phone Number Example: +6066045678<br> - Mobile Phone Number Example: +60123456789',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
      return;
    }
    this.isUpdateLoading = true;
    if(!this.editMode){
      const jsonBody: Object ={
        "user_id" : uuidv4(),
        "user_name" : this.editUserForm.value.user_name,
        "user_phone" : this.editUserForm.value.user_phone,
        "user_email" : this.editUserForm.value.user_email,
        "user_password" : this.editUserForm.value.user_password,
        "user_accountstatus" : this.editUserForm.value.user_accountstatus
      }
      this.apiService.createSingleUser(jsonBody).subscribe(resData =>{
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: "User created!",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['/admin/user-management']);
        })
      }, errorMessage =>{
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      })
    }else{
      const jsonBody: Object ={
        "user_id" : this.userid,
        "user_name" : this.editUserForm.value.user_name,
        "user_phone" : this.editUserForm.value.user_phone,
        "user_email" : this.editUserForm.value.user_email,
        "user_accountstatus" : this.editUserForm.value.user_accountstatus
      }
      this.apiService.updateSingleUser(jsonBody).subscribe(resData =>{
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: "User updated!",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['/admin/user-management']);
        })
      }, errorMessage =>{
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      })
    }
  }

  onClose(){
    if(!this.editUserForm.dirty){
      this.router.navigate(['../'], {relativeTo: this.route});
    }else{
      Swal.fire({
        title: 'Leave without saving?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, leave without saving!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['../'], {relativeTo: this.route});
        }
      })
    }
  }

  showUserLevelForm(){
    this.apiService.fetchSingleUserAvailableAccessLevels(this.userid).subscribe(resData => {
      this.userLevelFormVisible = true;
      this.availableUserLevel = resData;
      this.assignUserLevelForm = new FormGroup({
        user_accesslevel: new FormControl(this.availableUserLevel, Validators.required)
      });
    },errorMessage =>{
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onAssignUserLevel(){
    this.isAssignLevelLoading = true;
    const jsonBody: Object ={
      "user_id" : this.userid,
      "access_level" : this.assignUserLevelForm.value.user_accesslevel
    }
    this.apiService.assignSingleUserAccessLevel(jsonBody).subscribe(resData => {
      this.isAssignLevelLoading = false;
      this.toastr.success('Access level assigned to user');
      this.getUserAccessLevels();
      this.onCloseAssignLevel();
    },errorMessage =>{
      this.isAssignLevelLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onDeleteUserLevel(level_id: string){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonBody: Object ={
          "user_id" : this.userid,
          "access_level" : level_id
        }
        this.apiService.removeSingleUserAccessLevel(jsonBody).subscribe(resData => {
          this.toastr.success('Access level removed from user');
          this.getUserAccessLevels();
        },errorMessage =>{
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        })
      }
    })
  }

  onCloseAssignLevel(){
    this.userLevelFormVisible = false;
  }

  showUserGroupForm(){
    this.apiService.fetchSingleUserAvailableGroups(this.userid).subscribe(resData => {
      this.userGroupFormVisible = true;
      this.availableUserGroup = resData;
      this.assignUserGroupForm = new FormGroup({
        user_volunteergroup: new FormControl(this.availableUserGroup, Validators.required)
      });
    },errorMessage =>{
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onAssignUserGroup(){
    this.isAssignGroupLoading = true;
    const jsonBody: Object ={
      "user_id" : this.userid,
      "group_id" : this.assignUserGroupForm.value.user_volunteergroup
    }
    this.apiService.assignSingleUserGroup(jsonBody).subscribe(resData => {
      this.isAssignGroupLoading = false;
      this.toastr.success('Volunteer group assigned to user');
      this.getUserVolunteerGroups();
      this.onCloseAssignGroup();
    },errorMessage =>{
      this.isAssignGroupLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onDeleteUserGroup(vg_id: string){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonBody: Object ={
          "user_id" : this.userid,
          "group_id" : vg_id
        }
        this.apiService.removeSingleUserGroup(jsonBody).subscribe(resData => {
          this.toastr.success('Volunteer Group removed from user');
          this.getUserVolunteerGroups();
        },errorMessage =>{
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        })
      }
    })
  }

  onCloseAssignGroup(){
    this.userGroupFormVisible = false;
  }

  open(content) {
    this.changePasswordModalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modalChangePassword',
      scrollable: true
    });
  }

  onChangePassword(){
    let new_password = this.changeUserPasswordForm.value.user_changePassword.trim();
    let confirm_password = this.changeUserPasswordForm.value.user_confirmPassword.trim();
    if (new_password.length < 6) {
      Swal.fire({
        title: 'Opps...',
        html: 'Password must be at least 6 characters long.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
      return;
    }
    if (new_password !== confirm_password) {
      Swal.fire({
        title: 'Opps...',
        html: 'Password not the same.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
      return;
    }
    const jsonBody: Object = {
      "user_id" : this.userid,
      "user_password" : new_password
    }
    this.apiService.changeUserPassword(jsonBody).subscribe(resData =>{
      Swal.fire({
        title: 'Done!',
        html: 'Password updated.',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
      this.closeChangePwdModal();
    }, errorMessage =>{
      Swal.fire({
        title: 'Opps...',
        html: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
      this.changeUserPasswordForm.reset();
    })
  }

  closeChangePwdModal(){
    this.changePasswordModalRef.close();
    this.changeUserPasswordForm.reset();
  }
}
