<app-loading-news *ngIf='isLoading'></app-loading-news>
<div class='card-body' *ngIf='!isLoading'>
  <div *ngIf='errorMessage'>
    <h3>Sorry!</h3>
    <p [innerHTML]='errorMessage'></p>
    <div class='row'>
      <div class='col-12 col-lg-12'>
        <a class="btn btn-block btn-primary" href="https://visitationseremban.org/index.php/mass-registration" role="button">Mass Schedule</a>
      </div>
    </div>
  </div>
  <div *ngIf='!errorMessage && isSuccess'>
    <div class='card justify-content-center mb-0 rounded-0' style='background-color: #5cb85c; height: 80px; text-align: center'>
      <h4 style='color: white' class='mb-1'>Registration Confirmed!</h4>
      <p style='color: white'><strong>{{ selectedPublicMass.mass_desc }}</strong></p>
    </div>
    <div style='border: 2px solid #5cb85c'>
      <p class='text-center mb-0'><strong>This is your Registration Code:</strong></p>
      <h1 class='text-center mb-0'><span
        class='badge badge-secondary'>{{ selectedPublicMass.mass_prefix }}{{ regString.regCode }}</span></h1>
      <hr>
      <h5 class='text-center'><strong>{{ regString.regName }}</strong></h5>
      <p class='text-center mb-0'>
        <strong>{{ selectedPublicMass.mass_lang }} {{ selectedPublicMass.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}</strong>
      </p>
      <p class='text-center mb-0'>Kindly Screenshot This Page To Ease On-site Verification
        Process</p>
      <p class='text-center mb-0'>
        <qrcode [qrdata]='regString.regId' [width]='256' [errorCorrectionLevel]="'H'"></qrcode>
      </p>
    </div>
    <hr>
    <p *ngIf='regString.email !== ""'>A confirmation email has been sent to {{ regString.email }}</p>
    <form [formGroup]='publicEmailForm' (ngSubmit)='onEmailSubmit()'>
      <div class='form-group mb-2'>
        <label *ngIf='regString.email !== ""'>(optional) You can send this confirmation to another email</label>
        <label *ngIf='regString.email === ""'>(optional) You can send this confirmation to your email</label>
        <input type='text' class='form-control' aria-label='' placeholder='Enter Your Email Address'
               formControlName='email_confirm'>
      </div>
      <app-button
        [type]="'submit'"
        [block]='false'
        [color]="'success'"
        [loading]='isEmailLoading'
        [disabled]='!publicEmailForm.valid'>
        Send Confirmation Email
      </app-button>
    </form>
    <hr>
    <h3>Notes</h3>
    <p>- If you have submitted incorrect details, kindly <strong>do not</strong> submit the form again. You can use this
      <a href='https://forms.gle/1kRxdBsikPvsHF346' target='_blank'>online form</a> to contact us. We will update your
      details accordingly.</p>
    <p>- Do come AT LEAST 30 MINS BEFORE Mass/ Service begins.</p>
    <p>- The GATE WILL BE CLOSED 5 MINS before the Mass/ Service begins.</p>
    <button class='btn btn-primary btn-block' (click)='reload()'>Done</button>
  </div>
  <div *ngIf='!errorMessage && !isSuccess'>
    <div class='alert alert-warning mb-1' role='alert'>
      <b>MANDATORY wearing of Face Masks at ALL TIMES, covering MOUTH, NOSE and CHIN.</b>
      Jesus would wear a mask because wearing a mask is an act of love.
      God sees how you wear a mask to protect not only your family, but other families too.
      God sees how you feel the hot sticky condensation on your face and endure it so that others may survive
      this pandemic.
      <b>Jesus had compassion and empathy. Do You?</b>
    </div>
    <img [src]="'assets/img/wearMasks_vr2.jpg'" class='img-fluid rounded mx-auto d-block mb-2'>
    <h3>Mass Registration</h3>
    <form [formGroup]='publicRegistrationForm' (ngSubmit)='onSubmit()' autocomplete='off'>
      <div class='pb-3'>
        <label>{{ massLabel }}</label>
        <label *ngFor='let mass of massList'>
          <input class='card-input-element d-none' type='radio' name='reg_mass' formControlName='reg_mass'
                 [value]='mass.mass_id'>
          <div class='card card-body bg-light align-items-center mb-1'>
            <div>
              <span class='badge badge badge-secondary mr-1'>{{ mass.mass_desc }}</span>
              <span class="badge badge badge-primary mr-1">{{ mass.mass_datetime | dateTimeFormatFilter: 'DD MMMM YYYY' }}</span>
              <p class='mb-0 mass-details d-block d-sm-none'>{{ mass.mass_lang }}<br>{{ mass.mass_datetime | dateTimeFormatFilter: 'hh:mm A' }}</p>
              <p class='mb-0 mass-details d-none d-sm-block'>{{ mass.mass_lang }} {{ mass.mass_datetime | dateTimeFormatFilter: 'hh:mm A' }}</p>
              <h6 class='d-none d-sm-block'>Available
                Seats: {{ displayEstimateSeats(+mass.mass_registered, +mass.mass_maxcap) }}</h6>
            </div>
          </div>
        </label>
      </div>
      <div class='pb-3'>
        <label for='reg_name'>Full Name<span class='text-danger'>*</span></label>
        <input type='text' class='form-control form-control-lg' id='reg_name' placeholder='Enter Your Full Name'
               formControlName='reg_name'>
      </div>
      <div class='pb-3'>
        <label for='reg_birthdate'>Birthdate (YYYY/MM/DD)<span class='text-danger'>*</span></label>
        <input type='text' class='form-control form-control-lg' id='reg_birthdate' placeholder='YYYY/MM/DD'
               formControlName='reg_birthdate'
               [dropSpecialCharacters]='false' mask='0000/M0/d0'>
      </div>
      <div class='pb-3'>
        <label for='reg_phone' id='phoneInputLabel'>Phone Number<span class='text-danger'>*</span></label>
        <input type='tel' class='form-control form-control-lg' id='reg_phone' value='+6'
               placeholder='Enter Your Phone Number' formControlName='reg_phone'>
      </div>
      <div class='pb-3'>
        <label for='reg_bec'>BEC/Zone/Area<span class='text-danger'>*</span></label>
        <input type='text' class='form-control form-control-lg' id='reg_bec'
               placeholder='Enter Your Zone/BEC/Area' formControlName='reg_bec'>
      </div>
      <div class='pb-3'>
        <label for='reg_email'>(optional) Email Address</label>
        <input type='email' class='form-control form-control-lg' id='reg_email'
               placeholder='Enter Your Email Address' formControlName='reg_email'>
      </div>
      <div class='pb-3'>
        <label for='reg_remarks'>(optional) Do you have difficulty walking/ OKU/ disabled?</label>
        <input type='text' class='form-control form-control-lg' id='reg_remarks' placeholder=''
               formControlName='reg_remarks'>
      </div>
      <div class='form-check mt-3 mb-3'>
        <input type='checkbox' class='form-check-input' id='reg_pp' formControlName='reg_pp'>
        <label class='form-check-label' for='reg_pp'><p>
          By checking on this box, you hereby acknowledge that you agree to the processing of your Personal Data
          as stipulated in our Privacy Policy Notice, and that you agree to our Terms of Use.
        </p><a href='http://visitationseremban.org/index.php/privacy-policy' target='_blank'>Privacy Policy
          Notice</a> | <a href='http://visitationseremban.org/index.php/terms-of-use' target='_blank'>Terms of
          Use</a></label>
      </div>
      <div class='form-check mt-3 mb-3'>
        <input type='checkbox' class='form-check-input' id='reg_tnc' formControlName='reg_tnc'>
        <label class='form-check-label' for='reg_tnc'>I hereby declare that the above submitted data are correct
          at the time of submission.</label>
      </div>
      <div class='form-check mt-3 mb-3'>
        <input type='checkbox' class='form-check-input' id='reg_health' formControlName='reg_health'>
        <label class='form-check-label' for='reg_health'>
          I hereby acknowledge that in the last 14 days I have not:
          <ul>
            <li>Been exhibiting any Covid-19 symptoms (including but not limited to fever, cough, shortness of
              breath, sore throat)
            </li>
            <li>Had contact with potential/confirmed COVID 19 patient directly or indirectly.</li>
          </ul>
        </label>
      </div>
<!--      <div class='form-check mt-3 mb-3'>-->
<!--        <input type='checkbox' class='form-check-input' id='reg_vaccine' formControlName='reg_vaccine'>-->
<!--        <label class='form-check-label' for='reg_vaccine'>-->
<!--          I have received 2 doses of Pfizer/ Astrazeneca/ Sinovac vaccine and completed at least 14 days from the date of the second dose-->
<!--          OR 1 dose of Johnson & Johnson/ Cansino vaccine and completed at least 28 days from the date of vaccination.-->
<!--        </label>-->
<!--      </div>-->
      <hr>
      <app-button
        [type]="'submit'"
        [block]='true'
        [color]="'primary'"
        [loading]='isSubmitLoading'>
        Submit
      </app-button>
    </form>
  </div>

</div>


