import { Component, OnInit } from '@angular/core';
import {Mass} from '@/model/mass.model';
import {Subscription} from 'rxjs';
import {MassService} from '@pages/dashboard/mass.service';

@Component({
  selector: 'app-mass-archived-list',
  templateUrl: './mass-archived-list.component.html',
  styleUrls: ['./mass-archived-list.component.scss']
})
export class MassArchivedListComponent implements OnInit {
  masses: Mass[] = [];
  massSub: Subscription;

  constructor(private massService: MassService) {
  }

  ngOnInit(): void {
    this.massSub = this.massService.archivedMassesChanged.subscribe(
      (masses: Mass[]) =>{
        this.masses = masses;
      }
    )
  }

  ngOnDestroy(): void {
    this.massSub.unsubscribe();
  }


}
