<div class='tab-pane'>
  <ul class="list-group" *ngIf='profileAccessLevels.length > 0; else noData'>
    <li class="list-group-item" *ngFor='let accessLevel of profileAccessLevels'>{{ accessLevel }}</li>
  </ul>
  <ng-template #noData>
    <div style="text-align: center;">
      <img [src]="'assets/img/noresfound.gif'" height="80" class="mb-2" alt="" loading="lazy">
      <h5>No Access Level Granted</h5>
    </div>
  </ng-template>
</div>
