<app-loading-spinner *ngIf='isLoading'></app-loading-spinner>
<div class='card-body' *ngIf='registrantCancelData'>
  <h3>Mass Registration Self-Cancellation</h3>
  <div class='pb-3'>
    <label for='user-name' class='mb-1'></label>
    <h4 id='user-name'>{{ registrantCancelData.reg_name }}</h4>
  </div>
  <div class='pb-3'>
    <label for='user-mass' class='mb-1'>Registered Mass</label>
    <p id='user-mass'>{{ registrantCancelData.mass_desc }}<br>{{ registrantCancelData.mass_lang }}<br>{{ registrantCancelData.mass_datetime | dateTimeFormatFilter: 'DD MMMM YYYY hh:mm A' }}</p>
  </div>
  <div class='pb-3'>
    <label for='user-timestamp' class='mb-1'>Registered On</label>
    <p id='user-timestamp'>{{ registrantCancelData.reg_datetime}}</p>
  </div>
  <form [formGroup]='publicRegistrationCancelForm' (ngSubmit)='onSubmit()' autocomplete='off'>
    <div class='form-check mt-3 mb-3'>
      <input type='checkbox' class='form-check-input' id='reg_pp' formControlName='reg_pp'>
      <label class='form-check-label' for='reg_pp'><p>
        By checking on this box, you hereby acknowledge that you agree to the processing of your Personal Data
        as stipulated in our Privacy Policy Notice, and that you agree to our Terms of Use.
      </p><a href='http://visitationseremban.org/index.php/privacy-policy' target='_blank'>Privacy Policy
        Notice</a> | <a href='http://visitationseremban.org/index.php/terms-of-use' target='_blank'>Terms of
        Use</a></label>
    </div>
    <div class='alert alert-danger' role='alert'>
      <div class='form-check mt-3 mb-3'>
        <input type='checkbox' class='form-check-input' id='reg_tnc' formControlName='reg_tnc'>
        <label class='form-check-label' for='reg_tnc'>By checking on this box, you hereby acknowledge that
          cancelling this registration for
          the above registrant is NOT REVERSIBLE and the Mass Registration Team will not be held responsible of this
          action.</label>
      </div>
      <app-button
        [type]="'submit'"
        [block]='true'
        [color]="'dark'"
        [loading]='isSubmitLoading'>
        Yes, Cancel This Registration
      </app-button>
    </div>
  </form>

</div>
