import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  HostBinding, ViewChild, ElementRef, AfterViewInit
} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {AppService} from '@services/app.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
    @HostBinding('class') class = 'login-box';
    public loginForm: FormGroup;
    public isAuthLoading = false;
    public isGoogleLoading = false;
    public isFacebookLoading = false;
    loginData: {email: string, name: string} = null;
    @ViewChild('email', {static: true}) emailInput: ElementRef;
    @ViewChild('password', {static: true}) passwordInput: ElementRef;


    constructor(
      private router: Router,
        private renderer: Renderer2,
        private toastr: ToastrService,
        private appService: AppService
    ) {}

    ngOnInit() {
        this.renderer.addClass(
            document.querySelector('app-root'),
            'login-page'
        );
        this.loginForm = new FormGroup({
            email: new FormControl(null),
            hiddenEmail: new FormControl(null),
            password: new FormControl(null, Validators.required),

        });
      this.loginData = JSON.parse(localStorage.getItem('userLoginData'));
    }

  ngAfterViewInit(): void {
    if(this.loginData){
      this.loginForm.patchValue({
        hiddenEmail: this.loginData.email
      })
      this.loginForm.controls['hiddenEmail'].setValidators(Validators.required);
      this.passwordInput.nativeElement.focus();
    }else{
      this.loginForm.controls['email'].setValidators(Validators.required);
      this.emailInput.nativeElement.focus();
    }
  }

    loginByAuth() {
        if (this.loginForm.valid) {
            this.isAuthLoading = true;
            this.appService.login(this.loginForm.value).subscribe(resData => {
              this.isAuthLoading = false;
              this.router.navigate(['/admin/dashboard']);
            },
              errorMessage => {
                this.toastr.error(errorMessage.error.message);
                this.isAuthLoading = false;
              });
        } else {
            this.toastr.error('Form is not valid!');
        }
    }

    async loginByGoogle() {
        this.isGoogleLoading = true;
        await this.appService.loginByGoogle();
        this.isGoogleLoading = false;
    }

    async loginByFacebook() {
        this.isFacebookLoading = true;
        await this.appService.loginByFacebook();
        this.isFacebookLoading = false;
    }

    switchUser(){
      localStorage.removeItem('userLoginData');
      this.loginData = null;
      this.emailInput.nativeElement.focus();
    }

    ngOnDestroy() {
        this.renderer.removeClass(
            document.querySelector('app-root'),
            'login-page'
        );
    }
}
