<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class='col-lg-12 col-12'>
        <div class='card'>
          <img [src]="'assets/img/archived.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
          <div class="card-img-overlay">
            <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>Archived</h1>
            <ol class='breadcrumb float-sm-left'>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
              <li class="breadcrumb-item active">Archived</li>
            </ol>
          </div>
          <div class='card-body' *ngIf='masses.length === 0' style='text-align: center'>
            <img [src]="'assets/img/noresfound.gif'" height="80" class="mb-2" alt="" loading="lazy">
            <h5>Nothing to show.</h5>
          </div>
        </div>
      </div>
      <div class='col-lg-4 col-sm-12 col-12 mb-3' *ngFor='let mass of masses'>
        <app-mass [mass]='mass' [mass_id]='mass.mass_id'></app-mass>
      </div>
    </div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->

