import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {MainComponent} from '@modules/main/main.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {LoginComponent} from '@modules/login/login.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {AuthGuard} from '@guards/auth.guard';
import {NonAuthGuard} from '@guards/non-auth.guard';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {PrivacyPolicyComponent} from '@modules/privacy-policy/privacy-policy.component';
import {ProfileAccessLevelComponent} from '@pages/profile/profile-access-level/profile-access-level.component';
import {ProfileVolunteerGroupComponent} from '@pages/profile/profile-volunteer-group/profile-volunteer-group.component';
import {ProfileResolverService} from '@pages/profile/profile-resolver.service';
import {MassesResolverService} from '@pages/dashboard/masses-resolver.service';
import {MassDetailComponent} from '@pages/dashboard/mass-detail/mass-detail.component';
import {MassListComponent} from '@pages/dashboard/mass-list/mass-list.component';
import {MassEditComponent} from '@pages/dashboard/mass-edit/mass-edit.component';
import {SeatingZoneComponent} from '@pages/seating-zone/seating-zone.component';
import {UserManagementComponent} from '@pages/user-management/user-management.component';
import {VolunteerGroupComponent} from '@pages/volunteer-group/volunteer-group.component';
import {UserListComponent} from '@pages/user-management/user-list/user-list.component';
import {VolunteerGroupListComponent} from '@pages/volunteer-group/volunteer-group-list/volunteer-group-list.component';
import {SeatingZoneListComponent} from '@pages/seating-zone/seating-zone-list/seating-zone-list.component';
import {UserEditComponent} from '@pages/user-management/user-edit/user-edit.component';
import {VolunteerGroupEditComponent} from '@pages/volunteer-group/volunteer-group-edit/volunteer-group-edit.component';
import {GroupsResolverService} from '@pages/volunteer-group/groups-resolver.service';
import {AboutComponent} from '@pages/about/about.component';
import {SeatingZoneEditComponent} from '@pages/seating-zone/seating-zone-edit/seating-zone-edit.component';
import {ZonesResolverService} from '@pages/seating-zone/zones-resolver.service';
import {RegistrantEditComponent} from '@pages/dashboard/registrant-edit/registrant-edit.component';
import {RegistrantsResolver} from '@pages/dashboard/registrants-resolver';
import {RegistrantPreComponent} from '@pages/dashboard/mass-detail/registrant-pre/registrant-pre.component';
import {RegistrantCancelComponent} from '@pages/dashboard/mass-detail/registrant-cancel/registrant-cancel.component';
import {PublicRegisterComponent} from '@pages/public/public-register/public-register.component';
import {PublicComponent} from '@pages/public/public.component';
import {PublicStatusComponent} from '@pages/public/public-status/public-status.component';
import {PublicCancelComponent} from '@pages/public/public-cancel/public-cancel.component';
import {MassArchivedListComponent} from '@pages/dashboard/mass-archived-list/mass-archived-list.component';
import {ArchivedMassesResolverService} from '@pages/dashboard/archived-masses-resolver.service';
import {RegistrantImportComponent} from '@pages/dashboard/registrant-import/registrant-import.component';
import {TagsComponent} from '@pages/tags/tags.component';
import {TagsEditComponent} from '@pages/tags/tags-edit/tags-edit.component';
import {TagsListComponent} from '@pages/tags/tags-list/tags-list.component';
import {TagsResolverService} from '@pages/tags/tags-resolver.service';
import {PublicRetiredComponent} from '@pages/public/public-retired/public-retired.component';

const routes: Routes = [
  {path: '', redirectTo: 'retired', pathMatch: 'full'},
  {path: 'retired', component: PublicRetiredComponent},
  {path: 'register', component: PublicComponent, children: [
      {path: '', component: PublicRegisterComponent},
      {path: ':massid', component: PublicRegisterComponent}
    ]},
  {path: 'status', component: PublicComponent, children: [
      {path: '', component: PublicStatusComponent}
    ]},
  {path: 'cancel', component: PublicComponent, children: [
      {path: '', component: PublicStatusComponent},
      {path: ':regid', component: PublicCancelComponent}
    ]},
  {path: 'admin', component: MainComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
    children: [
      {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
      {path: 'dashboard', component: DashboardComponent, resolve: [MassesResolverService], children:[
          {path: '', component: MassListComponent},
          {path: 'new-mass', component: MassEditComponent},
          {path: ':massid', component: MassDetailComponent, children: [
              {path: '', redirectTo: 'pre', pathMatch: 'full'},
              {path: 'pre', component: RegistrantPreComponent},
              {path: 'cancelled', component: RegistrantCancelComponent}
            ]},
          {path: ':massid/import', component: RegistrantImportComponent},
          {path: ':massid/edit', component: MassEditComponent},
          {path: ':massid/new-registrant', component: RegistrantEditComponent},
          {path: ':massid/:type/:id', component: RegistrantEditComponent, resolve: [RegistrantsResolver]}
        ]},
      {path: 'archived', component: MassArchivedListComponent, resolve: [ArchivedMassesResolverService]},
      {path: 'profile', component: ProfileComponent, children :[
          {path: '', redirectTo: 'access', pathMatch: 'full'},
          {path: 'access', component: ProfileAccessLevelComponent},
          {path: 'group', component: ProfileVolunteerGroupComponent}
        ], resolve: [ProfileResolverService]
      },
      {path: 'blank', component: BlankComponent},
      {path: 'seating-zone', component: SeatingZoneComponent, children: [
          {path: '', component: SeatingZoneListComponent},
          {path: 'new-zone', component: SeatingZoneEditComponent},
          {path: ':id', component: SeatingZoneEditComponent, resolve:[ZonesResolverService]}
        ]},
      {path: 'tags', component: TagsComponent, children: [
          {path: '', component: TagsListComponent},
          {path: 'new-tag', component: TagsEditComponent},
          {path: ':id', component: TagsEditComponent, resolve:[TagsResolverService]}
        ]},
      {path: 'volunteer-group', component: VolunteerGroupComponent, children: [
          {path: '', component: VolunteerGroupListComponent},
          {path: 'new-group', component: VolunteerGroupEditComponent},
          {path: ':id', component: VolunteerGroupEditComponent, resolve:[GroupsResolverService]}
        ]},
      {path: 'user-management', component: UserManagementComponent, children:[
          {path: '', component: UserListComponent},
          {path: 'new-user', component: UserEditComponent},
          {path: ':userid', component: UserEditComponent}
        ]},
      {path: 'about', component: AboutComponent}
    ]
  },
  {path: 'login', component: LoginComponent, canActivate: [NonAuthGuard]},
  {path: 'forgot-password', component: ForgotPasswordComponent, canActivate: [NonAuthGuard]},
  {path: 'recover-password', component: RecoverPasswordComponent, canActivate: [NonAuthGuard]},
  {path: 'privacy-policy', component: PrivacyPolicyComponent, canActivate: [NonAuthGuard]},
  {path: 'about', component: AboutComponent},
  {path: '**', redirectTo: 'register'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
