<!-- Main content -->
<section class='content'>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-lg-12 col-12'>
        <div class='card'>
          <img [src]="'assets/img/volunteer-groups.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
          <div class="card-img-overlay">
            <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>Volunteer Groups</h1>
            <ol class='breadcrumb float-sm-left'>
              <li class='breadcrumb-item'><a [routerLink]="['/admin/dashboard']">Home</a></li>
              <li class='breadcrumb-item active'>Volunteer Groups</li>
            </ol>
          </div>
          <div class='card-body' style='z-index: 20; position: relative'>
            <app-loading-news *ngIf='isInitialLoading'></app-loading-news>
            <table datatable [dtOptions]='dtOptions' [dtTrigger]='dtTrigger'
                   class='table table-bordered table-striped dataTable dtr-inline'>
              <thead>
              <tr>
                <th>ID</th>
                <th>Group Name</th>
                <th>Description</th>
                <th>Coordinator</th>
                <th>No. of users assigned</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor='let group of groupList; let i = index'>
                <td>{{ group.vg_id }}</td>
                <td>{{ group.vg_name }}</td>
                <td>{{ group.vg_desc }}</td>
                <td>{{ group.vg_leader }}</td>
                <td>{{ group.vg_count }}</td>
                <td>
                  <button class='btn btn-primary' [routerLink]='[i]'>Edit</button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <button mat-fab color='primary' style='position: fixed; bottom: 5rem; right: 2rem; z-index: 1000'
            [routerLink]="['new-group']">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
