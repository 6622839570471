import {Component, OnInit} from '@angular/core';
import {ProfileService} from '@pages/profile/profile.service';


@Component({
  selector: 'app-profile-volunteer-group',
  templateUrl: './profile-volunteer-group.component.html',
  styleUrls: ['./profile-volunteer-group.component.scss']
})
export class ProfileVolunteerGroupComponent implements OnInit{
  profileAssignedGroups: string[];

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    this.profileAssignedGroups = this.profileService.profile.getValue().group;
  }


}
