import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from '@services/api.service';
import {TagsService} from '@pages/tags/tags.service';

@Injectable({providedIn: 'root'})
export class TagsResolverService implements Resolve<{tag_id: string, tag_name: string}[]>{
  constructor(private apiService: ApiService, private tagService: TagsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{tag_id: string, tag_name: string}[]> | Promise<{tag_id: string, tag_name: string}[]> | {tag_id: string, tag_name: string}[] {
    if(this.tagService.getTags().length == 0){
      return this.apiService.fetchAllTags()
    }else{
      return this.tagService.getTags();
    }

  }
}
