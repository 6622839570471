<div class="card mass-shadow" style='height: 100%'>
  <div class="ribbon-wrapper ribbon-lg">
    <div class="ribbon bg-{{ribbonColour()}}">
      {{ribbonText()}}
    </div>
  </div>
  <div class="card-body">
    <h5 class="card-title"><strong>{{ mass.mass_desc }}</strong></h5><br>
    <p class="card-text" *ngIf='mass.mass_selfcheckstartdatetime === mass.mass_selfcheckenddatetime'>
      <strong>{{ mass.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}</strong><br>
      <strong>Details</strong><br>
      Language: {{ mass.mass_lang }}<br>
      Maximum Capacity: {{ mass.mass_maxcap }}<br>
      Registered: {{ mass.mass_registered }}<br>
      Visibility:  {{ mass.mass_visibility == 'P' ? 'Public' : 'Specific Groups' }}<br>
      Prefix: {{ mass.mass_prefix }}
    </p>
    <p class="card-text" *ngIf='mass.mass_selfcheckstartdatetime != mass.mass_selfcheckenddatetime'>
      <strong>{{ mass.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}</strong><br>
      <strong>Details</strong><br>
      Language: {{ mass.mass_lang }}<br>
      Start: {{ mass.mass_selfcheckstartdatetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A'}}<br>
      End: {{ mass.mass_selfcheckenddatetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}<br>
      Checked-In: {{ mass.mass_registered }}<br>
      Prefix: {{ mass.mass_prefix }}
    </p>
    <div *ngIf='mass.mass_archived == "N"'>
      <label for="copy-link">Direct Form Link For This Mass</label>
      <input type="text" class="form-control form-control-sm mb-2" value="{{ mass.mass_shorturl }}" id="copy-link">
    </div>
    <div class="progress mb-1">
      <div class="progress-bar bg-warning" role="progressbar" [style.width]='calPercentage(mass.mass_registered, mass.mass_maxcap) + "%"' aria-valuenow="26" aria-valuemin="0" aria-valuemax="100">{{ calPercentage(mass.mass_registered, mass.mass_maxcap) + "%" }}</div>
    </div>
    <button class='btn btn-primary btn-block' [routerLink]="['/admin/dashboard', mass_id]">View More</button>
    <a *ngIf='mass.mass_archived == "Y"' class="btn btn-light btn-block"
       [href]="'https://api.visitationseremban.org/mrs/api/admin_mass_spreadsheet.php?jwt='+ user.token+'&massid='+mass.mass_id" role="button">
      Download Mass Report (Excel)</a>
  </div>
</div><!-- /.card -->
