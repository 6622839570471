<app-loading-news *ngIf='isLoading'></app-loading-news>
<div style="text-align: center;" *ngIf='registrantCancelledListError && !isLoading'>
  <div class='card'>
    <div class='card-body'>
      <img [src]="'assets/img/noresfound.gif'" height="80" class="mb-2" alt="" loading="lazy">
      <h5>Not Allowed To View Registrants List</h5>
    </div>
  </div>
</div>
<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped dataTable dtr-inline">
  <thead>
  <tr>
    <th>Code</th>
    <th>Cancellation Timestamp</th>
    <th>Reg. Method</th>
    <th>Name</th>
    <th>Phone</th>
    <th>Remarks</th>
    <th>Cancelled By</th>
    <th>Actions</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let registrant of registrantCancelledList; let i = index">
    <td>{{ registrant.reg_code }}</td>
    <td>{{ registrant.cancelled_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A'  }}</td>
    <td>{{ registerMethodConverter(registrant.reg_method) }}</td>
    <td>{{ registrant.reg_name }}</td>
    <td>{{ registrant.reg_phone }}</td>
    <td>{{ registrant.reg_remarks | shorten: 10 }}</td>
    <td>{{ registrant.cancelled_by}}</td>
    <td><button class='btn btn-primary mr-2 mb-1' [routerLink]="[i]">View</button><button class='btn btn-primary mr-2 mb-1' (click)='onRestore(registrant.reg_id)'>Restore</button></td>
  </tr>
  </tbody>
</table>
