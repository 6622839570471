import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Profile} from '@/model/profile.model';
import {ProfileService} from '@pages/profile/profile.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy{
  private profileSub: Subscription;
  profile: Profile;

  constructor(private profileService: ProfileService) {
  }

  ngOnInit(): void {
    this.profileSub = this.profileService.profile.subscribe(profile => {
      this.profile = profile;
    })
  }

  ngOnDestroy(): void {
    this.profileSub.unsubscribe();
  }


}
