<!-- Main content -->
<section class='content'>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-lg-12 col-12'>
        <form [formGroup]='editZoneForm' (ngSubmit)='onSubmit()' *ngIf='zone || !editMode' autocomplete='off'>
          <div class='row'>
            <div class='col-lg-12 col-12'>
              <div class='card card-primary'>
                <img [src]="'assets/img/seating-zone.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
                <div class="card-img-overlay">
                  <div class='row'>
                    <div class='col-lg-6 col-8'>
                      <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>{{ editMode ? 'Edit Seating Zone' : 'Add Seating Zone' }}</h1>
                    </div>
                    <div class='col-lg-6 col-4'>
                      <button type="button" class="btn btn-light fa-pull-right" title="Remove" (click)='onClose()'>
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div class='d-none d-sm-block'>
                    <ol class='breadcrumb float-sm-left'>
                      <li class='breadcrumb-item'><a [routerLink]="['/admin/dashboard']">Home</a></li>
                      <li class='breadcrumb-item'><a [routerLink]="['../']">Seating Zone</a></li>
                      <li class='breadcrumb-item active' *ngIf='editMode'>{{ zone.zone_colour }}</li>
                      <li class='breadcrumb-item active'>{{ editMode ? 'Edit' : 'Add Seating Zone' }}</li>
                    </ol>
                  </div>
                </div>
                <div class='card-body' style='display: block;'>
                  <h5>Basic Details</h5>
                  <div class='row'>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='zone_colour'>Zone Colour <span style='color: red'>*</span></label>
                        <input type='text' id='zone_colour' formControlName='zone_colour' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='zone_desc'>Zone Description <span style='color: red'>*</span></label>
                        <input type='text' id='zone_desc' formControlName='zone_desc' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='zone_nostart'>Seat Number Begin <span style='color: red'>*</span></label>
                        <input type='number' id='zone_nostart' formControlName='zone_nostart' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='zone_noend'>Seat Number End <span style='color: red'>*</span></label>
                        <input type='number' id='zone_noend' formControlName='zone_noend' class='form-control'>
                      </div>
                    </div>
                  </div>
                  <div class='alert alert-default-info' *ngIf='!editZoneForm.valid'>
                    Form is not valid. Please check the form before saving.
                  </div>
                  <div class='alert alert-default-info' *ngIf='editZoneForm.valid && !editZoneForm.dirty'>
                    There are no changes made yet.
                  </div>
                  <button type='button' class='btn btn-primary' style='z-index: 20; position: relative' (click)='onClose()'>Go Back</button>
                  <button *ngIf='editMode' type="button" class="btn btn-default ml-2" style='z-index: 20; position: relative' (click)='openGenerateSeatModal(generate)'>Generate Seats</button>
                  <a *ngIf='editMode' class="btn btn-default ml-2" style='z-index: 20; position: relative' target='_blank'
                     [href]="'https://api.visitationseremban.org/mrs/api/admin_seatqr_pdf.php?jwt='+ user.token+'&zoneid='+zone.zone_id" role="button">
                    Print Seat QR</a>
                  <div class='row fa-pull-right'>
                    <div class='col-lg-6 col-6'>
                      <app-button
                        [type]="'submit'"
                        [block]='false'
                        [color]="'success'"
                        [loading]='isUpdateLoading'
                        [disabled]='!editZoneForm.valid || !editZoneForm.dirty'>
                        Save
                      </app-button>
                    </div>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
            <div class='col-lg-12 col-12' *ngIf='editMode'>
              <div class='card'>
                <div class='card-body' style='z-index: 20; position: relative'>
                  <app-loading-news *ngIf='isInitialLoading'></app-loading-news>
                  <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped dataTable dtr-inline">
                    <thead>
                    <tr>
                      <th>Seat</th>
                      <th>Seat Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let seat of seatList; let i = index">
                      <td>{{ zone.zone_colour }} {{ seat.seat_no }}</td>
                      <td>
                        <i *ngIf='seat.seat_status == "A"; else clickableAvailable' class="fas fa-check" style="color: #5cb85c"></i>
                        <i *ngIf='seat.seat_status == "C"; else clickableClosed' class="fas fa-lock ml-2" style="color: red"></i>
                        <i *ngIf='seat.seat_status == "P"; else clickablePermanent' class="fas fa-minus-circle ml-2" style="color: red"></i>
                      </td>
                      <ng-template #clickableAvailable>
                        <i class="fas fa-check" style="cursor: pointer" (click)="changeStatusTo(seat.seat_id, 'A')"></i>
                      </ng-template>
                      <ng-template #clickableClosed>
                        <i class="fas fa-lock ml-2" style="cursor: pointer" (click)="changeStatusTo(seat.seat_id, 'C')"></i>
                      </ng-template>
                      <ng-template #clickablePermanent>
                        <i class="fas fa-minus-circle ml-2" style="cursor: pointer" (click)="changeStatusTo(seat.seat_id, 'P')"></i>
                      </ng-template>
                      <!--                  <td><button class='btn btn-primary mr-2 mb-1' (click)='open(content, tag.tag_id)'>Edit</button>-->
                      <!--                    <button class='btn btn-danger' (click)='onDeleteTag(tag.tag_id)'>-->
                      <!--                      <i class="fas fa-trash-alt"></i></button>-->
                      <!--                  </td>-->
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
<ng-template #generate let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modalTag">Generate Seats</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeGenerateSeatModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]='generateSeatForm' autocomplete='off'>
      <div class='form-group'>
        <label for='generate_startno'>Seat Number Begin <span style='color: red'>*</span></label>
        <input type='text' id='generate_startno' formControlName='generate_startno' class='form-control'>
      </div>
      <div class='form-group'>
        <label for='generate_endno'>Seat Number End <span style='color: red'>*</span></label>
        <input type='text' id='generate_endno' formControlName='generate_endno' class='form-control'>
      </div>
      <div class='form-group'>
        <label for='generate_defaultstatus'>Seat Status <span style='color: red'>*</span></label>
        <select class='custom-select' id='generate_defaultstatus' formControlName='generate_defaultstatus'>
          <option value='' disabled>Choose an option</option>
          <option value='A'>Available</option>
          <option value='C'>Closed</option>
        </select>
      </div>
      <div class='alert alert-default-info' *ngIf='!generateSeatForm.valid'>
        Form is not valid. Please check the form before saving.
      </div>
      <div class='alert alert-default-info' *ngIf='generateSeatForm.valid && !generateSeatForm.dirty'>
        There are no changes made yet.
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <app-button
      [type]="'button'"
      [block]='false'
      [color]="'primary'"
      [loading]='isUpdateLoading'
      [disabled]='!generateSeatForm.valid || !generateSeatForm.dirty'
      (click)='onGenerateSeats()'>
      Generate
    </app-button>
    <!--    <button type="button" class="btn btn-primary" (click)="onUpdateTag()" [disabled]='!updateTagForm.valid || !updateTagForm.dirty'>{{ editMode ? 'Update Tag' : 'Create Tag' }}</button>-->
    <button type="button" class="btn btn-outline-dark" (click)="closeGenerateSeatModal()">Close</button>
  </div>
</ng-template>

