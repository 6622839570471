<!-- Main content -->
<section class='content'>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-lg-12 col-12'>

          <div class='row'>
            <div class='col-lg-12 col-12'>
              <div class='card card-primary'>
                <img [src]="'assets/img/mass-management.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
                <div class="card-img-overlay">
                  <div class='row'>
                    <div class='col-lg-6 col-8'>
                      <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>Import Data</h1>
                    </div>
                    <div class='col-lg-6 col-4'>
                      <button type="button" class="btn btn-light fa-pull-right" title="Remove" [routerLink]="['../']">
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div class='d-none d-sm-block'>
                    <ol class='breadcrumb float-sm-left'>
                      <li class='breadcrumb-item'><a [routerLink]="['/admin/dashboard']">Home</a></li>
                      <li class='breadcrumb-item'><a [routerLink]="['../']">Mass</a></li>
                      <li class='breadcrumb-item active'>Import Data</li>
                    </ol>
                  </div>
                </div>
                <div class='card-body' style='display: block;'>
                  <form [formGroup]='uploadForm' (ngSubmit)='uploadFile()' autocomplete='off' *ngIf='uploadResult.data == null'>
                    <div class='row'>
                      <div class='col-lg-6 col-12'>
                        <div class="form-group">
                          <label for="import">Choose File</label>
                          <input type="file" id="import" name='import' formControlName='file' class='form-control' accept='.xls, .xlsx'
                                 (change)="onFileChange($event)">
                        </div>
                      </div>
                    </div>
                    <div class='alert alert-default-info' *ngIf='!uploadForm.valid'>
                      Form is not valid. Please check the form before saving.
                    </div>
                    <div class='alert alert-default-info' *ngIf='uploadForm.valid && !uploadForm.dirty'>
                      There are no changes made yet.
                    </div>
                    <button type='button' class='btn btn-primary' style='z-index: 20; position: relative' [routerLink]="['../']">Go Back</button>
                    <div class='row fa-pull-right'>
                      <div class='col-lg-6 col-6'>
                        <app-button
                          [type]="'submit'"
                          [block]='false'
                          [color]="'success'"
                          [loading]='isUploadLoading'
                          [disabled]='!uploadForm.valid || !uploadForm.dirty'>
                          Upload
                        </app-button>
                      </div>
                    </div>
                  </form>
                  <div [hidden]='uploadResult.data == null'>
                    <div class='row'>
                      <div class='col-12'>
                        <div class="alert alert-warning" role="alert">
                          <h4 class="alert-heading">Review and Confirm!</h4>
                          <p>Data import pending review. Please review and confirm the data below.</p>
                          <hr>
                          <p class="mb-0">Whenever you are ready, press <b>import</b>.</p>
                        </div>
                        <div class='mb-2'>
                          <button type='button' class='btn btn-danger' style='z-index: 20; position: relative' (click)='startOver(false)'>Start Over</button>
                          <div class='row fa-pull-right'>
                            <div class='col-lg-6 col-6'>
                              <app-button (click)='importData()'
                                [type]="'button'"
                                [block]='false'
                                [color]="'success'"
                                [loading]='isImportLoading'>
                                Import
                              </app-button>
                            </div>
                          </div>
                        </div>

                        <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped dataTable dtr-inline">
                          <thead>
                          <tr>
                            <th>No.</th>
                            <th>Name</th>
                            <th>Tag</th>
                            <th>Age</th>
                            <th>Phone</th>
                            <th>BEC</th>
                            <th>Zone/ Seat</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr *ngFor="let registrant of uploadResult.data; let i = index">
                            <td>{{ registrant.no }}</td>
                            <td>{{ registrant.reg_name }}</td>
                            <td>{{ registrant.reg_tag }}</td>
                            <td>{{ registrant.reg_birthdate | age}}</td>
                            <td>{{ registrant.reg_phone }}</td>
                            <td>{{ registrant.reg_bec }}</td>
                            <td>{{ registrant.reg_zone }} {{ registrant.reg_seat }}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->

