<app-header (toggleMenuSidebar)="toggleMenuSidebar()"></app-header>

<app-menu-sidebar
    class="main-sidebar sidebar-dark-primary elevation-4"
    style='background-color: #002540'
></app-menu-sidebar>

<div class="content-wrapper">
    <router-outlet></router-outlet>
</div>

<app-footer class="main-footer"></app-footer>
<aside class="control-sidebar control-sidebar-dark"></aside>
<div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>
