<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class='row'>
      <div class='col-lg-12 col-12'>
        <div class='card'>
          <img [src]="'assets/img/user-management.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
          <div class="card-img-overlay">
            <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>User Management</h1>
            <ol class='breadcrumb float-sm-left'>
              <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
              <li class="breadcrumb-item active">User Management</li>
            </ol>
          </div>
          <div class='card-body' style='z-index: 20; position: relative'>
            <app-loading-news *ngIf='isInitialLoading'></app-loading-news>
            <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="table table-bordered table-striped dataTable dtr-inline">
              <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Account Status</th>
                <th>Last Login</th>
                <th>Created By</th>
                <th>Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let user of userList">
                <td>{{ user.user_name }}</td>
                <td>{{ user.user_email }}</td>
                <td>{{ user.user_phone }}</td>
                <td>{{ user.user_accountstatus == 'A' ? 'Active' : 'Disabled' }}</td>
                <td>{{ user.user_lastlogin == '-' ? '-' : user.user_lastlogin | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}</td>
                <td>{{ user.createdby }}</td>
                <td><button class='btn btn-primary' [routerLink]="[user.user_id]">Edit</button></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <button mat-fab color="primary" style='position: fixed; bottom: 5rem; right: 2rem; z-index: 1000' [routerLink]="['new-user']">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
