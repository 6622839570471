<app-loading-spinner *ngIf='isLoading'></app-loading-spinner>
<div class='card-body' *ngIf='!isLoading && showForm'>
  <h3>Mass Registration Status Check</h3>
  <div class='alert alert-info mb-3' role='alert'>
    The name and phone number must be the same as per what you have submitted in the registration form earlier. Failure to do so, the system will return no results.
  </div>
  <form [formGroup]='publicRegistrationStatusForm' (ngSubmit)='onSubmit()' autocomplete='off'>
    <div class='pb-3'>
      <label for='reg_name'>Full Name</label>
      <input type='text' class='form-control form-control-lg' id='reg_name' placeholder='Enter Your Full Name'
             formControlName='reg_name'>
    </div>
    <div class='pb-3'>
      <label for='reg_phone' id='phoneInputLabel'>Phone Number</label>
      <input type='tel' class='form-control form-control-lg' id='reg_phone' value='+6'
             placeholder='Enter Your Phone Number' formControlName='reg_phone'>
    </div>
    <div class='form-check mt-3 mb-3'>
      <input type='checkbox' class='form-check-input' id='reg_pp' formControlName='reg_pp'>
      <label class='form-check-label' for='reg_pp'><p>
        By checking on this box, you hereby acknowledge that you agree to the processing of your Personal Data
        as stipulated in our Privacy Policy Notice, and that you agree to our Terms of Use.
      </p><a href='http://visitationseremban.org/index.php/privacy-policy' target='_blank'>Privacy Policy
        Notice</a> | <a href='http://visitationseremban.org/index.php/terms-of-use' target='_blank'>Terms of
        Use</a></label>
    </div>
    <app-button
      [type]="'submit'"
      [block]='true'
      [color]="'dark'"
      [loading]='isSubmitLoading'>
      Check
    </app-button>
  </form>
</div>
<div class='card-body' *ngIf='!isLoading && !showForm'>
  <div class="alert alert-info" role="alert">
    <h4>Alert!</h4>
    <p>If you are showing COVID-19 symptoms, please do not come to church. You can still follow our live-streamed Masses</p>
  </div>
  <div class="alert alert-warning d-block d-sm-block d-md-none" role="alert">
    Swipe the table to the left or right to view more.
  </div>
  <div class='table-responsive'>
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>No.</th>
        <th>Name</th>
        <th>Mass</th>
        <th>Date & Time</th>
        <th>Reg. Code</th>
        <th>Status</th>
      </tr>
      </thead>
      <tbody>
      <ng-template ngFor let-element [ngForOf]='statusCheckData' let-i='index'>
        <tr>
          <td>{{ i+1 }}</td>
          <td>{{ element.reg_name }}</td>
          <td>{{ element.mass_lang }}</td>
          <td>{{ element.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A'}}</td>
          <td>{{ element.reg_code }}</td>
          <td>{{ getStatus(element.cancelled, element.reg_checkindatetime, element.mass_datetime) }}</td>
        </tr>
        <tr style="border-bottom: 3px solid" *ngIf='getStatus(element.cancelled, element.reg_checkindatetime, element.mass_datetime) === "Confirmed"'>
          <td colspan="6">
            <button *ngIf='element.mass_allowcancel === "N"' class="btn btn-danger btn-block btn-sm disabled">↑↑ Self-Cancellation Not Available ↑↑</button>
            <button *ngIf='element.mass_allowcancel === "Y"' class="btn btn-danger btn-block btn-sm" [routerLink]="['/cancel', element.reg_id]">↑↑ Cancel The Above Registration ↑↑</button>
            <button class='btn btn-outline-dark btn-sm btn-block mb-2' (click)='open(content, element.reg_id)'><i class='fas fa-qrcode'></i> Show QR Code</button>
          </td>
        </tr>
      </ng-template>
      </tbody>
    </table>
  </div>
  <button (click)='navigateBack()' class='btn btn-primary btn-block'>Go Back</button>
</div>
<ng-template #content let-modal>
  <div class='modal-header'>
    <h4 class='modal-title' id='modalQR'>Registration QR Code</h4>
    <button type='button' class='close' aria-label='Close' (click)='modal.dismiss()'>
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class='modal-body' *ngIf='qrString'>
    <div style='text-align: center'>
      <qrcode [qrdata]='qrString.reg_id' [width]='256' [errorCorrectionLevel]="'H'"></qrcode>
      <h2>{{ qrString.reg_code }}</h2>
      <p>{{ qrString.reg_name }}</p>
    </div>
  </div>
  <div class='modal-footer'>
    <button type='button' class='btn btn-dark' (click)='modal.dismiss()'>Close</button>
  </div>
</ng-template>
