import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '@services/api.service';
import {ExcelRegistrant} from '@/model/excelRegistrant.model';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {ActivatedRoute, Params, Router} from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registrant-import',
  templateUrl: './registrant-import.component.html',
  styleUrls: ['./registrant-import.component.scss']
})
export class RegistrantImportComponent implements OnInit, OnDestroy, AfterViewInit {
  uploadForm: FormGroup;
  massid: string;
  isUploadLoading: boolean = false;
  isImportLoading: boolean = false;
  uploadResult: ExcelRegistrant = {
    file_name: null,
    data: null
  };
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => {
        this.massid = params.massid;
      });
    this.uploadForm = new FormGroup({
      file: new FormControl('', Validators.required),
      fileSource: new FormControl('', Validators.required)
    });
    this.dtOptions = {
      responsive: true,
      autoWidth: false,
      dom: 'Bfrtipl',
      buttons: ['copy',
        {
          extend: 'csvHtml5',
          title: 'MRS_Mass_Pre-Registrant_List'
        },
        {
          extend: 'excelHtml5',
          title: 'MRS_Mass_Pre-Registrant_List'
        },
        {
          extend: 'pdfHtml5',
          messageBottom: 'Church of the Visitation Seremban. All Rights Reserved.',
          title: 'MRS_Mass_Pre-Registrant_List'
        },
        'print',
        'colvis']
    };
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.patchValue({
        fileSource: file
      });
    }
  }

  uploadFile() {
    this.isUploadLoading = true;
    const formData = new FormData();
    formData.append('file', this.uploadForm.get('fileSource').value);
    this.apiService.uploadExcelFile(formData).subscribe(res => {
      this.isUploadLoading = false;
      this.uploadResult = res;
      this.rerender();
    }, errorMessage => {
      console.log(errorMessage);
    });
  }

  importData() {
    this.isImportLoading = true;
    const jsonBody: Object = {
      'file_name': this.uploadResult.file_name,
      'mass_id': this.massid
    };
    this.apiService.importExcelFile(jsonBody).subscribe(res => {
      Swal.fire({
        title: 'Done!',
        text: res.message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
      this.isImportLoading = false;
      this.router.navigate(['../'], {relativeTo: this.route});
    }, errorMessage => {
      this.isImportLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      }).then((result) => {
        this.startOver(true);
      });
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  startOver(byPassConfirm: boolean) {
    if (byPassConfirm) {
      this.uploadResult = {
        file_name: null,
        data: null
      };
      this.ngOnInit();
    } else {
      Swal.fire({
        title: 'Confirm start over?',
        text: 'You will not be able to revert this action.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, start over!',
        cancelButtonText: 'No'
      }).then((result) => {
        if (result.isConfirmed) {
          this.uploadResult = {
            file_name: null,
            data: null
          };
          this.ngOnInit();
        }
      });
    }
  }

}
