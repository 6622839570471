import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from '@services/api.service';
import {Registrant} from '@/model/registrant.model';
import {MassService} from '@pages/dashboard/mass.service';

@Injectable({providedIn: 'root'})
export class RegistrantsResolver implements Resolve<Registrant[]>{
  constructor(private apiService: ApiService, private massService: MassService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Registrant[]> | Promise<Registrant[]> | Registrant[] {
    let registrantList: Registrant[] = [];
    if(route.params.type === 'pre'){
      registrantList = this.massService.getRegistrants();
    }else if(route.params.type === 'cancelled'){
      registrantList = this.massService.getCancelledRegistrants();
    }
    if(registrantList.length == 0){
      if(route.params.type === 'pre'){
        return this.apiService.fetchAllRegistrants(route.params.massid);
      }else if(route.params.type === 'cancelled'){
        return this.apiService.fetchAllCancelledRegistrants(route.params.massid);
      }
    }else{
      return registrantList;
    }

  }
}
