import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {Registrant} from '@/model/registrant.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApiService} from '@services/api.service';
import {MassService} from '@pages/dashboard/mass.service';
import {DataTableDirective} from 'angular-datatables';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-registrant-cancel',
  templateUrl: './registrant-cancel.component.html',
  styleUrls: ['./registrant-cancel.component.scss']
})
export class RegistrantCancelComponent implements OnInit, OnDestroy {
  isLoading: boolean = false;
  massid: string;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  registrantCancelledList: Registrant[] = [];
  registrantCancelledListError: boolean = false;

  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  initialLoad: boolean = true;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private router: Router, private massService: MassService) { }

  ngOnInit(): void {
    this.isLoading = true;
    this.dtOptions ={
      responsive: true,
      autoWidth: false,
      columnDefs: [
        { responsivePriority: 0, targets: -1 },
        { responsivePriority: 0, targets: 3 },
        { responsivePriority: 1, targets: 4 }
      ],
      dom: 'Bfrtipl',
      buttons: ["copy",
        {
          extend: 'csvHtml5',
          title: 'MRS_Mass_Pre-Registrant-Cancelled_List'
        },
        {
          extend: 'excelHtml5',
          title: 'MRS_Mass_Pre-Registrant-Cancelled_List'
        },
        {
          extend: 'pdfHtml5',
          messageBottom: 'Church of the Visitation Seremban. All Rights Reserved.',
          title: 'MRS_Mass_Pre-Registrant-Cancelled_List'
        },
        "print",
        "colvis"]
    }
    this.route.parent.params.subscribe(
      (params: Params) =>{
        this.massid = params.massid;
        this.getCancelledRegistrants();
      }
    )
  }

  getCancelledRegistrants(){
    this.apiService.fetchAllCancelledRegistrants(this.massid).subscribe(resData =>{
      this.isLoading = false;
      this.massService.setCancelledRegistrants(resData);
      this.registrantCancelledList = this.massService.getCancelledRegistrants();
      if(this.initialLoad){
        this.dtTrigger.next();
        this.initialLoad = false;
      }else{
        this.rerender();
      }
    }, errorMessage => {
      this.isLoading = false;
      this.registrantCancelledListError = true;
    })
  }

  registerMethodConverter(reg_method: string): string{
    if(reg_method === 'A'){
      return 'ADMIN';
    }else if(reg_method === 'P'){
      return 'PRE-REG'
    }else if(reg_method === 'W'){
      return 'WALK-IN'
    }else if(reg_method === 'I'){
      return 'IMPORT'
    }
  }

  onRestore(reg_id: string){
    console.log(reg_id);
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, restore this registration!'
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonBody: Object ={
          "reg_id" : reg_id
        }
        this.apiService.restoreCancelledRegistrant(jsonBody).subscribe(resData => {
          Swal.fire({
            title: 'Good job!',
            text: 'Registration restored!',
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
          this.getCancelledRegistrants();
          this.massService.increaseMassRegistered();
        }, errorMessage => {
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        });
      }
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
}
