import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Mass} from '@/model/mass.model';
import {ApiService} from '@services/api.service';

@Injectable({providedIn: 'root'})
export class ArchivedMassesResolverService implements Resolve<Mass[]>{
  constructor(private apiService: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Mass[]> | Promise<Mass[]> | Mass[] {
    return this.apiService.fetchArchivedMasses();
  }
}
