import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {GroupService} from '@pages/volunteer-group/group.service';
import {ApiService} from '@services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-volunteer-group-edit',
  templateUrl: './volunteer-group-edit.component.html',
  styleUrls: ['./volunteer-group-edit.component.scss']
})
export class VolunteerGroupEditComponent implements OnInit {
  id: number;
  editMode: boolean = false;
  editGroupForm: FormGroup;
  isUpdateLoading: boolean = false;
  group: {vg_id: string, vg_name: string, vg_desc: string, vg_leader: string, vg_leaderid: string, vg_count: string};
  availableLeaders: {user_id: string, user_name: string}[];
  assignedUsers: {user_id: string, user_name: string}[] = [];

  constructor(private route: ActivatedRoute, private groupService: GroupService, private apiService: ApiService, private router: Router) {
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => {
        this.apiService.fetchGroupAvailableUsers().subscribe(resData => {
          this.availableLeaders = resData;
        }, errorMessage => {
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        });
        this.id = +params.id;
        this.editMode = params.id != null;
        this.initForm();
      });
  }

  initForm() {
    let vg_name = '';
    let vg_desc = '';
    let vg_leader = '';
    if (this.editMode) {
      this.group = this.groupService.getGroup(this.id);
      this.apiService.fetchGroupAssignedUsers(this.group.vg_id).subscribe(resData => {
        this.assignedUsers = resData;
      });
      vg_name = this.group.vg_name;
      vg_desc = this.group.vg_desc;
      vg_leader = this.group.vg_leaderid;
    }
    this.editGroupForm = new FormGroup({
      vg_name: new FormControl(vg_name, Validators.required),
      vg_desc: new FormControl(vg_desc, Validators.required),
      vg_leader: new FormControl(vg_leader, Validators.required)
    });
  }

  onSubmit() {
    this.isUpdateLoading = true;
    if (this.editMode) {
      const jsonBody: Object = {
        'group_name': this.editGroupForm.value.vg_name,
        'group_desc': this.editGroupForm.value.vg_desc,
        'group_leader': this.editGroupForm.value.vg_leader,
        'group_id': this.group.vg_id
      };
      this.apiService.updateSingleGroup(jsonBody).subscribe(resData => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: 'Group updated!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['../'], {relativeTo: this.route});
        });
      }, errorMessage => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      });
    } else {
      const jsonBody: Object = {
        'group_name': this.editGroupForm.value.vg_name,
        'group_desc': this.editGroupForm.value.vg_desc,
        'group_leader': this.editGroupForm.value.vg_leader
      };
      this.apiService.createSingleGroup(jsonBody).subscribe(resData => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: 'Group created!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['../'], {relativeTo: this.route});
        }, errorMessage => {
          this.isUpdateLoading = false;
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        });
      });
    }
  }

  onClose() {
    if (!this.editGroupForm.dirty) {
      this.router.navigate(['../'], {relativeTo: this.route});
    } else {
      Swal.fire({
        title: 'Leave without saving?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, leave without saving!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['../'], {relativeTo: this.route});
        }
      });
    }
  }

  onDelete() {

  }

}
