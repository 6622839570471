import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '@services/api.service';
import {Subject} from 'rxjs';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, OnDestroy {
  isInitialLoading: boolean = false;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  userList: {user_id: string, user_name: string; user_email: string; user_phone: string; user_accountstatus: string; createdby: string,
  user_lastlogin: string}[] = [];

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
    this.isInitialLoading = true;
    this.dtOptions ={
      responsive: true,
      autoWidth: false,
      columnDefs: [
        { responsivePriority: 0, targets: -1 }
      ],
      dom: 'Bfrtipl',
      buttons: ["copy",
        {
          extend: 'csvHtml5',
          title: 'MRS_All_Users'
        },
        {
          extend: 'excelHtml5',
          title: 'MRS_All_Users'
        },
        {
          extend: 'pdfHtml5',
          messageBottom: 'Church of the Visitation Seremban. All Rights Reserved.',
          title: 'MRS_All_Users'
        },
        "print",
        "colvis"]
    }
    this.apiService.fetchAllUsers().subscribe(resData =>{
      this.isInitialLoading = false;
      this.userList = resData;
      this.dtTrigger.next();
    }, errorMessage =>{
      this.isInitialLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onEdit(user_id: string){
    console.log(user_id);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

}
