<li class="nav-item dropdown user-menu">
    <a
        class="nav-link dropdown-toggle"
        data-toggle="dropdown"
        (click)="toggleDropdownMenu()"
    >
      <img class="app-avatar" ngxLetterImageAvatar="{{ user.name }}" alt="avatar" style='width: 30px; height: 30px'>
<!--      <img-->
<!--            [src]="'assets/img/default-profile.png'"-->
<!--            class="user-image img-circle elevation-2"-->
<!--            alt="User Image"-->
<!--        />-->
    </a>
    <ul
        #dropdownMenu
        class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
    >
        <!-- User image -->
        <li class="user-header bg-primary">
          <img class="app-avatar" ngxLetterImageAvatar="{{ user.name }}" alt="avatar" >
<!--            <img-->
<!--                [src]="'assets/img/default-profile.png'"-->
<!--                class="img-circle elevation-2"-->
<!--                alt="User Image"-->
<!--            />-->

            <p>
                <span>{{ user.name }}</span>
                <small>
                    <span>Thank you for serving in this parish. </span>
<!--                    <span>{{ formatDate(user.createdAt) }}</span>-->
                </small>
            </p>
        </li>
        <!-- Menu Footer-->
        <li class="user-footer">
            <a [routerLink]="['/admin/profile']" class="btn btn-default btn-flat">
                Profile
            </a>
            <a (click)="logout()" class="btn btn-default btn-flat float-right"
                >Sign out</a
            >
        </li>
    </ul>
</li>
