import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {PublicApiService} from '@services/public-api.service';
import {RegistrantStatusCheck} from '@/model/registrant-status-check.model';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {element} from 'protractor';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-public-status',
  templateUrl: './public-status.component.html',
  styleUrls: ['./public-status.component.scss']
})
export class PublicStatusComponent implements OnInit {
  isLoading: boolean = false;
  publicRegistrationStatusForm: FormGroup;
  isSubmitLoading: boolean = false;
  showForm: boolean = true;
  statusCheckData: RegistrantStatusCheck[] = [];
  qrString: {reg_id: string, reg_name: string, reg_code: string} = null;

  constructor(private publicApiService: PublicApiService, private modalService: NgbModal, private titleService: Title) {
    this.titleService.setTitle("Mass Registration Status Check | Visitation Seremban");
  }

  ngOnInit(): void {
    this.initForm();
  }

  sweetAlert(title: string, text: string) {
    Swal.fire({
      title: title,
      html: text,
      imageUrl: 'https://visitationseremban.org/images/11_OTHERS/MassRegistration/error-jesus.webp',
      imageHeight: 200,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    });
  }

  onSubmit(){
    let reg_name = this.publicRegistrationStatusForm.value.reg_name.trim();
    let reg_phone = this.publicRegistrationStatusForm.value.reg_phone.trim();
    let reg_pp = this.publicRegistrationStatusForm.value.reg_pp;

    if (!reg_name || reg_name.length < 6) {
      this.sweetAlert('Opps...', 'Please enter your FULL Name.');
      return;
    }
    if (/\d/g.test(reg_name)) {
      this.sweetAlert('Opps...', 'Name cannot contain numbers.<br><strong>Reminder:</strong><br> Please enter your Full Name (Not your NRIC Number)');
      return;
    }
    if (/\+60[0-9]{8,9}/g.test(reg_phone) == false) {
      this.sweetAlert('Opps...', 'Please enter a valid phone number. <br><strong>Guidelines:</strong><br> - Please do not include spaces<br> - Landline Phone Number Example: +6066045678<br> - Mobile Phone Number Example: +60123456789');
      return;
    }
    if (!reg_pp) {
      this.sweetAlert('Opps...', 'Please agree to our Privacy Policy Notice and Terms of Use <br>(Please tick the checkbox)');
      return;
    }
    this.isSubmitLoading = true;
    const jsonBody: Object = {
      'reg_name': reg_name.toUpperCase(),
      'reg_phone': reg_phone
    };
    this.publicApiService.statusSelfCheck(jsonBody).subscribe(resData => {
      this.isSubmitLoading = false;
      this.statusCheckData = resData;
      this.showForm = false;
    }, errorMessage =>{
      this.isSubmitLoading = false;
      this.sweetAlert('Opps...', errorMessage.error.message);
    })
  }

  private initForm(){
    this.publicRegistrationStatusForm = new FormGroup({
      reg_name: new FormControl(''),
      reg_phone: new FormControl('+6'),
      reg_pp: new FormControl(false)
    });
  }

  getStatus(cancelled: string, checkin_datetime: string, mass_datetime: string){
    if(checkin_datetime !== '-'){
      return 'Attended';
    }else if(cancelled === '1'){
      return 'Cancelled';
    }else if(new Date() > new Date(mass_datetime)){
      return 'Did Not Attend';
    }else{
      return 'Confirmed';
    }
  }

  navigateBack(){
    this.publicRegistrationStatusForm.reset();
    this.showForm = true;
  }

  open(content, reg_id) {
    let data: RegistrantStatusCheck = this.statusCheckData.find(element => element.reg_id === reg_id);
    this.qrString = {
      "reg_id": data.reg_id,
      "reg_name": data.reg_name,
      "reg_code": data.reg_code
    }
    this.modalService.open(content, {
      ariaLabelledBy: 'modalQR',
      size: 'lg',
      scrollable: true
    }).result.then((result) => {

    }, (reason) => {
      this.qrString = null;
    });
  }
}
