import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ToastrService} from 'ngx-toastr';
import {Gatekeeper} from 'gatekeeper-client-sdk';
import {tap} from 'rxjs/operators';
import {User} from '@/model/user.model';
import jwt_decode from 'jwt-decode';
import {BehaviorSubject} from 'rxjs';
import {ProfileService} from '@pages/profile/profile.service';

export interface AuthResponseData {
  message: string,
  jwt: string,
  user_id: string,
  expireAt: string
}

@Injectable({
  providedIn: 'root'
})
export class AppService {
  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;

  constructor(private router: Router, private toastr: ToastrService, private http: HttpClient, private profileService: ProfileService) {
  }

  login({email, hiddenEmail, password}) {
    if(!email && hiddenEmail){
      email = hiddenEmail;
    }
    return this.http.post<AuthResponseData>('https://api.visitationseremban.org/mrs/api/admin_login.php', {
      email: email,
      password: password
    }).pipe(tap(resData => {
      this.handleAuthentication(resData.user_id, resData.jwt, +resData.expireAt);
    }));
  }

  private handleAuthentication(user_id: string, jwt: string, expireAt: number): void {
    const expirationDate = new Date(expireAt*1000);
    const jwtDecoded = <any>jwt_decode(jwt);
    const user = new User(jwtDecoded.data.email, jwtDecoded.data.name, user_id, jwt, expirationDate);
    this.user.next(user);
    this.autoLogout(expirationDate.getTime() - new Date().getTime());
    localStorage.setItem('userData', JSON.stringify(user));
    localStorage.setItem('userLoginData', JSON.stringify({
      email: jwtDecoded.data.email,
      name: jwtDecoded.data.name
    }))
  }

  autoLogout(expirationDuration: number): void {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
      this.toastr.error('Session Expired');
    }, expirationDuration);
  }

  autoLogin(): void{
    const userData: {
      email: string,
      name: string,
      id: string,
      _token: string,
      _tokenExpirationDate: string
    } = JSON.parse(localStorage.getItem('userData'));
    if (!userData){
      return;
    }
    const loadedUser = new User(userData.email, userData.name, userData.id, userData._token, new Date(userData._tokenExpirationDate));

    if (loadedUser.token){
      this.user.next(loadedUser);
      const expirationDuration = new Date(userData._tokenExpirationDate).getTime() - new Date().getTime();
      this.autoLogout(expirationDuration);
    }else{
      localStorage.removeItem('userData');
      this.toastr.error('Session Expired');
    }
  }

  async loginByAuth({email, password}) {
    try {
      const token = await Gatekeeper.loginByAuth(email, password);
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
    } catch (error) {
      this.toastr.error(error.response.data.message);
    }
  }

  async registerByAuth({email, password}) {
    try {
      const token = await Gatekeeper.registerByAuth(email, password);
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
    } catch (error) {
      this.toastr.error(error.response.data.message);
    }
  }

  async loginByGoogle() {
    try {
      const token = await Gatekeeper.loginByGoogle();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
    } catch (error) {
      this.toastr.error(error.response.data.message);
    }
  }

  async registerByGoogle() {
    try {
      const token = await Gatekeeper.registerByGoogle();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
    } catch (error) {
      this.toastr.error(error.response.data.message);
    }
  }

  async loginByFacebook() {
    try {
      const token = await Gatekeeper.loginByFacebook();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
    } catch (error) {
      this.toastr.error(error.response.data.message);
    }
  }

  async registerByFacebook() {
    try {
      const token = await Gatekeeper.registerByFacebook();
      localStorage.setItem('token', token);
      await this.getProfile();
      this.router.navigate(['/']);
    } catch (error) {
      this.toastr.error(error.response.data.message);
    }
  }

  async getProfile() {
    // try {
    //   this.user = await Gatekeeper.getProfile();
    // } catch (error) {
    //   this.logout();
    //   throw error;
    // }
  }

  logout() {
    localStorage.removeItem('userData');
    this.user.next(null);
    this.profileService.profile.next(null);
    this.router.navigate(['/login']);
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }
}
