<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class='col-lg-12 col-12'>
        <div class="card card-primary">
          <img [src]="'assets/img/mass-management.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
          <div class="card-img-overlay" *ngIf='mass'>
            <div class='row'>
              <div class='col-lg-6 col-8'>
                <h1 class='m-0 text-light d-none d-sm-block' style='text-shadow: 2px 2px 8px black'>{{ mass.mass_desc }}</h1>
              </div>
              <div class='col-lg-6 col-4'>
                <button type="button" class="btn btn-light fa-pull-right" title="Remove" (click)='onClose()'>
                  <i class="fas fa-times"></i>
                </button>
              </div>
            </div>
            <div class='d-none d-sm-block'>
              <ol class='breadcrumb float-sm-left'>
                <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Dashboard (All Masses)</a></li>
                <li class="breadcrumb-item active">{{ mass.mass_desc }}</li>
              </ol>
            </div>
          </div>
          <app-loading-news *ngIf='!mass'></app-loading-news>
          <div class='card-body' style='z-index: 20; position: relative' *ngIf='mass'>
            <div class='row'>
              <div class='col-lg-5 col-12'>
                <strong><i class="fas fa-align-justify mr-1"></i> Description</strong>

                <p class="text-muted">
                  {{ mass.mass_desc }}
                </p>
                <hr>
              </div>

              <div class='col-lg-4 col-12'>
                <strong><i class="fas fa-language mr-1"></i> Language</strong>

                <p class="text-muted">
                  {{ mass.mass_lang }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-12'>
                <strong><i class="fas fa-calendar-alt mr-1"></i> Date & Time</strong>

                <p class="text-muted">
                  {{ mass.mass_datetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-eye mr-1"></i> Visibility</strong>

                <p class="text-muted">
                  {{ mass.mass_visibility == 'P' ? 'Public' : 'Specific Groups' }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-person-booth mr-1"></i> Maximum Capacity</strong>

                <p class="text-muted">
                  {{ mass.mass_maxcap }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-user-minus mr-1"></i> Minimum Allowed Age</strong>

                <p class="text-muted">
                  {{ mass.mass_minage }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-user-plus mr-1"></i> Maximum Allowed Age</strong>

                <p class="text-muted">
                  {{ mass.mass_maxage }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-pencil-alt mr-1"></i> Registration Status</strong>

                <p class="text-muted">
                  {{ mass.mass_available == 'Y' ? 'Available' : 'Not Available' }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-check mr-1"></i> Registered</strong>

                <p class="text-muted">
                  {{ massRegistered }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-ban mr-1"></i> Allow Self-Cancellation</strong>

                <p class="text-muted">
                  {{ mass.mass_allowcancel == 'Y' ? 'Yes' : 'No' }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-font mr-1"></i> Prefix</strong>

                <p class="text-muted">
                  {{ mass.mass_prefix }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-door-open mr-1"></i> Registration Start</strong>

                <p class="text-muted">
                  {{ mass.mass_startdatetime == '-' ? '-' : mass.mass_startdatetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A' }}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-door-closed mr-1"></i> Registration End</strong>

                <p class="text-muted">
                  {{ mass.mass_enddatetime == '-' ? '-' : mass.mass_enddatetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A'}}
                </p>
                <hr>
              </div>
              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-qrcode mr-1"></i> Self Check-in Start</strong>

                <p class="text-muted">
                  {{ mass.mass_startvol == '-' ? '-' : mass.mass_selfcheckstartdatetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A'}}
                </p>
                <hr>
              </div>
              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-qrcode mr-1"></i> Self Check-in End</strong>

                <p class="text-muted">
                  {{ mass.mass_endvol == '-' ? '-' : mass.mass_selfcheckenddatetime | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A'}}
                </p>
                <hr>
              </div>

              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-user-alt mr-1"></i> Check-In Start</strong>

                <p class="text-muted">
                  {{ mass.mass_startcheckin == '-' ? '-' : mass.mass_startcheckin | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A'}}
                </p>
                <hr>
              </div>
              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-user-alt-slash mr-1"></i> Check-In End</strong>

                <p class="text-muted">
                  {{ mass.mass_endcheckin == '-' ? '-' : mass.mass_endcheckin | dateTimeFormatFilter: 'DD MMM YYYY hh:mm A'}}
                </p>
                <hr>
              </div>
              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-walking mr-1"></i> Walk-In Capacity</strong>

                <p class="text-muted">
                  {{ mass.mass_lastmincap }}
                </p>
                <hr>
              </div>
              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-walking mr-1"></i> Check-In Buffer</strong>

                <p class="text-muted">
                  {{ mass.mass_checkinbuffer }}
                </p>
                <hr>
              </div>
              <div class='col-lg-5 col-6'>
                <strong><i class="fas fa-heading mr-1"></i> Registration Start Text</strong>

                <p class="text-muted">
                  {{ mass.mass_starttext }}
                </p>
                <hr>
              </div>
              <div class='col-lg-4 col-6'>
                <strong><i class="fas fa-heading mr-1"></i> Registration End Text</strong>

                <p class="text-muted">
                  {{ mass.mass_endtext }}
                </p>
                <hr>
              </div>
              <div class='col-lg-3 col-6'>
                <strong><i class="fas fa-mouse-pointer mr-1"></i> API Endpoint</strong>

                <p class="text-muted">
                  {{ mass.mass_api == 'Y' ? 'On' : 'Off' }}
                </p>
                <hr>
              </div>

            </div>
            <button type="button" class="btn btn-primary mr-3 mb-3" (click)='onClose()'>Go Back To View All Masses</button>
            <button type="button" class="btn btn-default mr-3 mb-3" (click)='onEditMass()'>Edit Mass Details</button>
            <button type="button" class="btn btn-default mr-3 mb-3 disabled" *ngIf='disableGenerateReport(mass.mass_datetime); else canGenerate'>Generate Mass Report (Excel)</button>
            <ng-template #canGenerate>
              <a class="btn btn-default mb-3"
                 [href]="'https://api.visitationseremban.org/mrs/api/admin_mass_spreadsheet.php?jwt='+ user.token+'&massid='+massid" role="button">
                Generate Mass Report (Excel)</a>
            </ng-template>
            <button type="button" class="btn btn-default mr-3 mb-3" [routerLink]="['import']">Import Data</button>
          </div>
          <!-- /.card-body -->
        </div>
      </div>
    </div>
    <div class='card'>
      <div class='card-header p-2'>
        <ul class='nav nav-pills'>
          <li class='nav-item'>
            <a
              class='nav-link'
              routerLink='pre'
              routerLinkActive='active'
            >Registered</a>
          </li>
          <li class='nav-item'>
            <a
              class='nav-link'
              routerLink='cancelled'
              routerLinkActive='active'
            >Cancelled</a>
          </li>
        </ul>
      </div>
      <!-- /.card-header -->
      <div class='card-body'>
        <div class='tab-content'>
          <router-outlet></router-outlet>
        </div>
        <!-- /.tab-content -->
      </div>
      <!-- /.card-body -->
    </div>
    <button mat-fab color="primary" style='position: fixed; bottom: 5rem; right: 2rem; z-index: 1000' [routerLink]="['new-registrant']">
      <mat-icon>add</mat-icon>
    </button>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
