import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApiService} from '@services/api.service';
import Swal from 'sweetalert2';
import {Registrant} from '@/model/registrant.model';
import {MassService} from '@pages/dashboard/mass.service';
import {formatDate} from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import {Mass} from '@/model/mass.model';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-registrant-edit',
  templateUrl: './registrant-edit.component.html',
  styleUrls: ['./registrant-edit.component.scss']
})
export class RegistrantEditComponent implements OnInit {
  id: number;
  type: string;
  massid: string;
  editMode: boolean = false;
  editRegistrantForm: FormGroup;
  isUpdateLoading: boolean = false;
  registrant: Registrant;
  mass: Mass;
  registrantQrString: string = '';

  registrantTagForm: FormGroup;
  registrantTagFormVisible: boolean = false;
  availableTags: {tag_id: string, tag_name: string}[] = [];
  assignedTags: {tag_id: string, tag_name: string}[] = [];
  isAssignLoading: boolean = false;

  sendEmailForm: FormGroup;
  isSendingEmail: boolean = false;

  constructor(private route: ActivatedRoute, private massService: MassService, private apiService: ApiService, private router: Router, private toastr: ToastrService) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params.id;
        this.massid = params.massid;
        this.type = params.type;
        this.editMode = params.id != null;
        this.initForm();
      });
    console.log(this.registrant);
  }

  initForm(){
    let reg_name = '';
    let reg_birthdate = '';
    let reg_phone = '+6';
    let reg_remarks = '';
    let reg_bec = '';
    let reg_email = '';
    if(this.editMode){
      if(this.type === 'pre'){
        this.registrant = this.massService.getRegistrant(this.id);
      }else if(this.type === 'cancelled'){
        this.registrant = this.massService.getCancelledRegistrant(this.id);
      }
      this.sendEmailForm = new FormGroup({
        send_email: new FormControl("", [Validators.required, Validators.email])
      });
      this.registrantQrString = this.registrant.reg_id;
      reg_name = this.registrant.reg_name;
      reg_birthdate = formatDate(this.registrant.reg_birthdate, 'yyyy-MM-dd', 'en');
      reg_phone = this.registrant.reg_phone;
      reg_remarks = this.registrant.reg_remarks;
      reg_email = this.registrant.reg_email;
      reg_bec = this.registrant.reg_bec;
      this.getRegistrantTags();
    }
    this.editRegistrantForm = new FormGroup({
      reg_name: new FormControl(reg_name, Validators.required),
      reg_birthdate: new FormControl(reg_birthdate, Validators.required),
      reg_phone: new FormControl(reg_phone, [Validators.required]),
      reg_remarks: new FormControl(reg_remarks),
      reg_email: new FormControl(reg_email, Validators.email),
      reg_bec: new FormControl(reg_bec, Validators.required)
    });
  }

  private getRegistrantTags(){
    this.apiService.fetchRegistrantTags(this.registrant.reg_id).subscribe(resData =>{
      this.assignedTags = resData;
      console.log(this.assignedTags);
    }, errorMessage => {
      Swal.fire('Opps...', errorMessage.error.message, 'error').then(() => {
        this.router.navigate(['/admin/dashboard']);
      })
      console.log(errorMessage.error.message);
    })
  }

  showRegistrantTagForm(){
    this.apiService.fetchAvailableRegistrantTags(this.registrant.reg_id).subscribe(resData => {
      this.registrantTagFormVisible = true;
      this.availableTags = resData;
      this.registrantTagForm = new FormGroup({
        tags: new FormControl(this.availableTags, Validators.required)
      });
    },errorMessage =>{
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onAssignRegistrantTag(){
    this.isAssignLoading = true;
    const jsonBody: Object ={
      "reg_id" : this.registrant.reg_id,
      "tag_id" : this.registrantTagForm.value.tags
    }
    this.apiService.assignRegistrantTag(jsonBody).subscribe(resData => {
      this.isAssignLoading = false;
      this.toastr.success('Tag assigned successfully');
      this.getRegistrantTags();
      this.onCloseRegistrantTagForm();
    },errorMessage =>{
      this.isAssignLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onCloseRegistrantTagForm(){
    this.registrantTagFormVisible = false;
  }

  onDeleteRegistrantTag(tag_id: string){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonBody: Object ={
          "reg_id" : this.registrant.reg_id,
          "tag_id" : tag_id
        }
        this.apiService.removeRegistrantTag(jsonBody).subscribe(resData => {
          this.toastr.success('Tag removed');
          this.getRegistrantTags();
        },errorMessage =>{
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        })
      }
    })
  }

  onSubmit(){
    if (/\+60[0-9]{8,9}/g.test(this.editRegistrantForm.value.reg_phone) == false) {
      Swal.fire({
        title: 'Opps...',
        html: 'Please enter a valid phone number. <br><strong>Guidelines:</strong><br> - Please do not include spaces<br> - Landline Phone Number Example: +6066045678<br> - Mobile Phone Number Example: +60123456789',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
      return;
    }
    this.isUpdateLoading = true;
    if(this.editMode){
      const jsonBody: Object ={
        "reg_name" : this.editRegistrantForm.value.reg_name.trim(),
        "reg_birthdate" : this.editRegistrantForm.value.reg_birthdate,
        "reg_phone" : this.editRegistrantForm.value.reg_phone,
        "reg_remarks" : this.editRegistrantForm.value.reg_remarks.trim(),
        "reg_email" : this.editRegistrantForm.value.reg_email,
        "reg_bec" : this.editRegistrantForm.value.reg_bec.trim(),
        "reg_id" : this.registrant.reg_id
      }
      this.apiService.updateSingleRegistrant(jsonBody).subscribe(resData => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: 'Registrant updated!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['../'], {relativeTo: this.route});
        });
      }, errorMessage => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      });
    }else{
      const jsonBody: Object ={
        "reg_name" : this.editRegistrantForm.value.reg_name,
        "reg_birthdate" : this.editRegistrantForm.value.reg_birthdate,
        "reg_phone" : this.editRegistrantForm.value.reg_phone,
        "reg_remarks" : this.editRegistrantForm.value.reg_remarks,
        "reg_email" : this.editRegistrantForm.value.reg_email,
        "reg_bec" : this.editRegistrantForm.value.reg_bec,
        "reg_id" : uuidv4(),
        "mass_id" : this.massid
      }
      this.apiService.createRegistrantAdmin(jsonBody).subscribe(resData => {
        this.isUpdateLoading = false;
        Swal.fire({
          html: '<h3>Registrant created!</h3><p>Registration Code is:</p><h3>' + resData.mass_prefix + resData.reg_code + '</h3>',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['../'], {relativeTo: this.route});
        });
      }, errorMessage => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      });
    }
  }

  onClose(){
    if(!this.editRegistrantForm.dirty){
      this.router.navigate(['../'], {relativeTo: this.route});
    }else{
      Swal.fire({
        title: 'Leave without saving?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, leave without saving!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['../'], {relativeTo: this.route});
        }
      })
    }
  }

  sendConfirmationEmail(){
    console.log('test')
    this.isSendingEmail = true;
    let email = this.sendEmailForm.value.send_email
    this.apiService.sendConfirmationEmail(email, this.registrant.reg_id).subscribe(resData => {
      this.isSendingEmail = false;
      this.sendEmailForm.reset()
      Swal.fire({
        html: '<h3>Confirmation Email Sent!</h3><p>' + email + '</p>',
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      })
    }, errorMessage => {
      this.isSendingEmail = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }


}
