import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {PublicMass} from '@/model/public-mass.model';
import {RegistrantStatusCheck} from '@/model/registrant-status-check.model';
import {RegistrantCancelData} from '@/model/registrant-cancel-data.model';

@Injectable({
  providedIn: 'root'
})
export class PublicApiService {
  url: string = 'https://api.visitationseremban.org/mrs/api/';

  constructor(private http: HttpClient) {
  }

  fetchAvailablePublicMasses(){
    return this.http.get<PublicMass[]>(this.url +'public_mass_view_all.php');
  }

  createRegistrantPublic(json: Object){
    return this.http.post<any>(this.url +'public_register_mass.php', json);
  }

  fetchSinglePublicMass(massid: string){
    return this.http.get<PublicMass[]>(this.url +'public_mass_view_single.php?massid='+massid);
  }

  emailRegistrationConfirm(reg_id: string, reg_email: string){
    return this.http.get<any>(this.url +'email_registration_confirm.php?reg_id=' + reg_id + '&email=' + reg_email);
  }

  statusSelfCheck(json: Object){
    return this.http.post<RegistrantStatusCheck[]>(this.url +'public_status_check.php', json);
  }

  cancelRegistrantData(reg_id: string){
    return this.http.get<RegistrantCancelData>(this.url +'public_cancel_data.php?reg_id=' + reg_id);
  }

  cancelRegistrant(json: Object){
    return this.http.post<any>(this.url +'public_cancel_registrant.php', json);
  }

  fetchSingleStatusCheck(reg_id: string){
    return this.http.get<RegistrantStatusCheck[]>(this.url +'public_individual_status_check.php?reg_id='+ reg_id);
  }
}
