import {Injectable} from '@angular/core';

@Injectable()
export class GroupService{
  private groupList: {vg_id: string, vg_name: string, vg_desc: string, vg_leader: string, vg_leaderid: string, vg_count: string}[] = [];

  setGroups(groups: {vg_id: string, vg_name: string, vg_desc: string, vg_leader: string, vg_leaderid: string, vg_count: string}[]){
    this.groupList = groups;
  }

  getGroups(){
    return this.groupList.slice();
  }

  getGroup(index: number){
    return this.groupList[index];
  }

}
