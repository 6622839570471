import {Injectable} from '@angular/core';

@Injectable()
export class ZoneService{
  private zoneList: {zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string}[] = [];

  setZones(groups: {zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string}[]){
    this.zoneList = groups;
  }

  getZones(){
    return this.zoneList.slice();
  }

  getZone(index: number){
    return this.zoneList[index];
  }

}
