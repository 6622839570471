<section class='content'>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-lg-12 col-12'>
        <div class='card'>
          <img [src]="'assets/img/profile-header.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
          <div class="card-img-overlay">
            <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>About</h1>
            <ol class='breadcrumb float-sm-left'>
              <li class='breadcrumb-item'><a [routerLink]="['/']">Home</a></li>
              <li class='breadcrumb-item active'>About</li>
            </ol>
          </div>
          <div class='card-body'>
            <div class='row'>
              <div class="col-lg-6 text-center d-flex align-items-center justify-content-center">
                <div class="">
                  <h2>MRS<strong>admin</strong></h2>
                  <p class="lead mb-5">Mass Registration System Admin Portal<br>
                    Version 2.0
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <h3>Main Features</h3>
                <p class="lead">- Mass and public registration management</p>
                <p class="lead">- Seating zone management</p>
                <p class="lead">- Volunteer groups management</p>
                <p class="lead">- User management</p>
              </div>
            </div>
            <hr>
            <div class='row mb-4 text-center d-flex align-items-center justify-content-center'>
              <div class='col-12 mt-3 mb-3'>
                This web application is developed by:
              </div>
              <div class='col-lg-2 col-12'>
                <img [src]="'assets/img/developer.jpg'" class="img-circle" >
              </div>
              <div class='col-lg-6 col-12'>
                <h3>Julian Yong Hao</h3>
                <p>Asst. Coordinator</p>
                <p>Parish Social Communications and Media Ministry</p>
                <p>Church of the Visitation Seremban</p>
                <p><i class="fas fa-envelope-open-text"></i> yhjulian@gmail.com | <a href='https://www.linkedin.com/in/julian-yong-hao/' target='_blank' class='btn btn-primary btn-sm'><i class="fab fa-linkedin"></i> Connect on LinkedIn</a></p>
              </div>
            </div>
            <hr>
            <div class='row mb-4 text-center d-flex align-items-center justify-content-center'>
              <div class='col-lg-12 mt-3 mb-5'>
                <p>This web application is written using:</p>
                <img class='mr-3 mb-3' [src]="'assets/img/angular_logo.png'" height='80px'>
                <img class='mb-3' [src]="'assets/img/rxjs_logo.png'" height='80px'>
              </div>
              <div class='col-lg-2 mb-3'>
                <img [src]="'assets/img/typescript_logo.png'" height='80px'>
              </div>
              <div class='col-lg-2 mb-3'>
                <img [src]="'assets/img/bootstrap_logo.png'" height='80px'>
              </div>
              <div class='col-lg-2 mb-3'>
                <h2>Admin<strong>LTE</strong></h2>
              </div>
              <div class='col-lg-2 mb-3'>
                <img [src]="'assets/img/sweetalert_logo.png'" height='35px'>
              </div>
              <div class='col-lg-2 mb-3'>
                <img [src]="'assets/img/php_logo.png'" height='80px'>
              </div>
              <div class='col-lg-2 mb-3'>
                <img [src]="'assets/img/mysql_logo.png'" height='80px'>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
