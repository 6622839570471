<!-- Main content -->
<section class="content">
  <div class="container-fluid">
    <div class='row'>
      <div class='col-lg-12 col-12' *ngIf='userProfile || !editMode'>
        <form [formGroup]='editUserForm' (ngSubmit)='onSubmit()' autocomplete='off'>
          <div class='card card-primary'>
            <img [src]="'assets/img/user-management.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
            <div class="card-img-overlay">
              <div class='row'>
                <div class='col-lg-6 col-8'>
                  <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>{{ editMode ? 'Edit User' : 'Add User' }}</h1>
                </div>
                <div class='col-lg-6 col-4'>
                  <button type="button" class="btn btn-light fa-pull-right" title="Remove" (click)='onClose()'>
                    <i class="fas fa-times"></i>
                  </button>
                </div>
              </div>
              <ol class='breadcrumb float-sm-left'>
                <li class="breadcrumb-item"><a [routerLink]="['/admin/dashboard']">Home</a></li>
                <li class="breadcrumb-item"><a [routerLink]="['../']">User Management</a></li>
                <li class="breadcrumb-item active">{{ editMode ? 'Edit User' : 'Add User' }}</li>
              </ol>
            </div>
            <div class='card-body' style='z-index: 20; position: relative'>
              <h5>User Details</h5>
              <div class='row'>
                <div class='col-lg-6 col-12'>
                  <div class='form-group'>
                    <label for='user_name'>Name <span style='color: red'>*</span></label>
                    <input type='text' id='user_name' formControlName='user_name' class='form-control'>
                  </div>
                </div>
                <div class='col-lg-6 col-12'>
                  <div class='form-group'>
                    <label for='user_phone'>Phone <span style='color: red'>*</span></label>
                    <input type='text' id='user_phone' formControlName='user_phone' class='form-control'>
                  </div>
                </div>
                <div class='col-lg-6 col-12'>
                  <div class='form-group'>
                    <label for='user_email'>Email <span style='color: red'>*</span></label>
                    <input type='text' id='user_email' formControlName='user_email' class='form-control'>
                  </div>
                </div>
                <div class='col-lg-6 col-12' *ngIf='!editMode'>
                  <div class='form-group'>
                    <label for='user_password'>Password <span style='color: red'>*</span>(Min. Length 6)</label>
                    <input type='password' id='user_password' formControlName='user_password' class='form-control'>
                  </div>
                </div>
                <div class='col-lg-6 col-12'>
                  <div class='form-group'>
                    <label for='user_accountstatus'>Account Status <span style='color: red'>*</span></label>
                    <select class='custom-select' id='user_accountstatus' formControlName='user_accountstatus'>
                      <option value='' disabled>Choose an option</option>
                      <option *ngFor='let status of accountstatus'
                              [ngValue]='status'>{{status == 'A' ? 'Active' : 'Disabled'}}</option>
                    </select>
                  </div>
                </div>

              </div>
              <div class='alert alert-default-info' *ngIf='!editUserForm.valid'>
                Form is not valid. Please check the form before saving.
              </div>
              <div class='alert alert-default-info' *ngIf='editUserForm.valid && !editUserForm.dirty'>
                There are no changes made yet.
              </div>
              <button type='button' class='btn btn-primary mr-2' (click)='onClose()'>Go Back</button>
              <button type='button' class='btn btn-default' (click)='open(content)' *ngIf='editMode'>Change User's Password</button>
              <div class='row fa-pull-right'>
                <div class='col-lg-6 col-6'>
                  <app-button
                    [type]="'submit'"
                    [block]='false'
                    [color]="'success'"
                    [loading]='isUpdateLoading'
                    [disabled]='!editUserForm.valid || !editUserForm.dirty'>
                    Save
                  </app-button>
                </div>
              </div>
              <!-- /.card-body -->
            </div>
          </div>
        </form>
      </div>
      <div class='col-lg-6 col-12' *ngIf='editMode; else warningMessage'>
        <div class='card'>
          <div class='card-body'>
            <h5>Granted Access</h5>
            <table class="table" *ngIf='userAccessLevel.length > 0'>
              <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Granted Access</th>
                <th style="width: 20px">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor='let level of userAccessLevel; let i = index'>
                <td>{{ i + 1 }}.</td>
                <td>{{ level.level_desc }}</td>
                <td><button class='btn btn-danger' (click)='onDeleteUserLevel(level.level_id)'><i class="fas fa-trash-alt"></i></button></td>
              </tr>
              </tbody>
            </table>
            <div style="text-align: center;" *ngIf='userAccessLevel.length == 0'>
              <img [src]="'assets/img/noresfound.gif'" height="80" class="mb-2" alt="" loading="lazy">
              <h5>User Has No Granted Access</h5>
            </div>
            <button class='btn btn-primary btn-block' (click)='showUserLevelForm()' *ngIf='!userLevelFormVisible'>Assign New Access Level</button>
            <form [formGroup]='assignUserLevelForm' (ngSubmit)='onAssignUserLevel()' *ngIf='userLevelFormVisible' autocomplete='off'>
              <div class='form-group'>
                <label for='user_accesslevel'>Please Select</label>
                <select class='custom-select' id='user_accesslevel' formControlName='user_accesslevel'>
                  <option value='' disabled>Choose an option</option>
                  <option *ngFor='let level of availableUserLevel'
                          [ngValue]='level.level_id'>{{level.level_desc}}</option>
                </select>
              </div>
              <div class='row fa-pull-right'>
                <div class='col-lg-6 col-6'>
                  <app-button
                    [type]="'submit'"
                    [block]='false'
                    [color]="'success'"
                    [loading]='isAssignLevelLoading'
                    [disabled]='!assignUserLevelForm.valid || !assignUserLevelForm.dirty'>
                    Save
                  </app-button>
                </div>
                <div class='col-lg-6 col-6'>
                  <button type='button' class='btn btn-danger' (click)='onCloseAssignLevel()'>Cancel</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      <div class='col-lg-6 col-12' *ngIf='editMode'>
        <div class='card'>
          <div class='card-body'>
            <h5>Volunteer Groups</h5>
            <table class="table" *ngIf='userVolunteerGroups.length > 0'>
              <thead>
              <tr>
                <th style="width: 10px">#</th>
                <th>Volunteer Group</th>
                <th style="width: 20px">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor='let group of userVolunteerGroups; let i = index'>
                <td>{{ i + 1 }}.</td>
                <td>{{ group.vg_name }}</td>
                <td><button class='btn btn-danger' (click)='onDeleteUserGroup(group.vg_id)'><i class="fas fa-trash-alt"></i></button></td>
              </tr>
              </tbody>
            </table>
            <div style="text-align: center;" *ngIf='userVolunteerGroups.length == 0'>
              <img [src]="'assets/img/noresfound.gif'" height="80" class="mb-2" alt="" loading="lazy">
              <h5>No Groups Assigned To User</h5>
            </div>
            <button class='btn btn-primary btn-block' (click)='showUserGroupForm()' *ngIf='!userGroupFormVisible'>Assign New Group</button>
            <form [formGroup]='assignUserGroupForm' (ngSubmit)='onAssignUserGroup()' *ngIf='userGroupFormVisible' autocomplete='off'>
              <div class='form-group'>
                <label for='user_volunteergroup'>Please Select</label>
                <select class='custom-select' id='user_volunteergroup' formControlName='user_volunteergroup'>
                  <option value='' disabled>Choose an option</option>
                  <option *ngFor='let group of availableUserGroup'
                          [ngValue]='group.vg_id'>{{group.vg_name}}</option>
                </select>
              </div>
              <div class='row fa-pull-right'>
                <div class='col-lg-6 col-6'>
                  <app-button
                    [type]="'submit'"
                    [block]='false'
                    [color]="'success'"
                    [loading]='isAssignGroupLoading'
                    [disabled]='!assignUserGroupForm.valid || !assignUserGroupForm.dirty'>
                    Save
                  </app-button>
                </div>
                <div class='col-lg-6 col-6'>
                  <button type='button' class='btn btn-danger' (click)='onCloseAssignGroup()'>Cancel</button>
                </div>
              </div>

            </form>
          </div>
        </div>
      </div>
      <ng-template #warningMessage>
        <div class='col-lg-12 col-12'>
          <div class='card'>
            <div class='card-body'>
              User Access Level and Volunteer Groups can only be assigned after user is created.
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modalChangePassword">Change Password</h4>
    <button type="button" class="close" aria-label="Close" (click)="closeChangePwdModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]='changeUserPasswordForm' autocomplete='off'>
      <div class='form-group'>
        <label for='user_changePassword'>Password <span style='color: red'>*</span>(Min. Length 6)</label>
        <input type='password' id='user_changePassword' formControlName='user_changePassword' class='form-control'>
      </div>
      <div class='form-group'>
        <label for='user_confirmPassword'>Confirm Password <span style='color: red'>*</span></label>
        <input type='password' id='user_confirmPassword' formControlName='user_confirmPassword' class='form-control'>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onChangePassword()">Update Password</button>
    <button type="button" class="btn btn-outline-dark" (click)="closeChangePwdModal()">Close</button>
  </div>
</ng-template>

