<!-- Main content -->
<section *ngIf='profile' class='content'>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-lg-12 col-12'>
        <div class='card'>
          <img [src]="'assets/img/profile-header.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
          <div class="card-img-overlay">
            <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>Profile</h1>
            <ol class='breadcrumb float-sm-left'>
              <li class='breadcrumb-item'><a [routerLink]="['/admin/dashboard']">Home</a></li>
              <li class='breadcrumb-item active'>User Profile</li>
            </ol>
          </div>
        </div>
      </div>
      <div class='col-md-3'>
        <!-- Profile Image -->
        <div class='card card-primary card-outline'>
          <div class='card-body box-profile'>
            <div class='text-center'>
              <img class="app-avatar" ngxLetterImageAvatar="{{ profile.name }}" alt="avatar">
            </div>

            <h3 class='profile-username text-center'>
              {{ profile.name }}
            </h3>

            <p class='text-muted text-center'>{{ profile.email }}</p>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->

        <!-- About Me Box -->
        <div class='card card-primary'>
          <div class='card-header'>
            <h3 class='card-title'>Details</h3>
          </div>
          <!-- /.card-header -->
          <div class='card-body'>
            <strong
            ><i class='fas fa-phone mr-1'></i> Phone Number</strong
            >

            <p class='text-muted'>
              {{ profile.phone }}
            </p>

            <hr />

            <strong
            ><i class='fas fa-calendar-check mr-1'></i>
              Last Login</strong
            >

            <p class='text-muted'>{{ profile.lastLogin }}</p>

            <hr />

            <strong
            ><i class='fas fa-calendar-alt mr-1'></i>
              Account Created</strong
            >

            <p class='text-muted'>
              {{ profile.createdDateTime }}
            </p>

            <hr />

            <strong
            ><i class='far fa-file-alt mr-1'></i> Created By</strong
            >

            <p class='text-muted'>
              {{ profile.createdBy }}
            </p>
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.card -->
      </div>
      <!-- /.col -->
      <div class='col-md-9'>
        <div class='card'>
          <div class='card-header p-2'>
            <ul class='nav nav-pills'>
              <li class='nav-item'>
                <a
                  class='nav-link'
                  routerLink='access'
                  routerLinkActive='active'
                >Granted Access</a>
              </li>
              <li class='nav-item'>
                <a
                  class='nav-link'
                  routerLink='group'
                  routerLinkActive='active'
                >Assigned Group</a>
              </li>
            </ul>
          </div>
          <!-- /.card-header -->
          <div class='card-body'>
            <div class='tab-content'>
              <router-outlet></router-outlet>
            </div>
            <!-- /.tab-content -->
          </div>
          <!-- /.card-body -->
        </div>
        <!-- /.nav-tabs-custom -->
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</section>
