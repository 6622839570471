import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApiService} from '@services/api.service';
import Swal from 'sweetalert2';
import {ZoneService} from '@pages/seating-zone/zone.service';

@Component({
  selector: 'app-tags-edit',
  templateUrl: './tags-edit.component.html',
  styleUrls: ['./tags-edit.component.scss']
})
export class TagsEditComponent implements OnInit {
  id: number;
  editMode: boolean = false;
  editZoneForm: FormGroup;
  isUpdateLoading: boolean = false;
  zone: {zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string};

  constructor(private route: ActivatedRoute, private zoneService: ZoneService, private apiService: ApiService, private router: Router) { }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params.id;
        this.editMode = params.id != null;
        this.initForm();
      });
  }

  initForm(){
    let zone_colour = '';
    let zone_desc = '';
    let zone_nostart = '';
    let zone_noend = '';
    if(this.editMode){
      this.zone = this.zoneService.getZone(this.id);
      zone_colour = this.zone.zone_colour;
      zone_desc = this.zone.zone_desc;
      zone_nostart = this.zone.zone_nostart;
      zone_noend = this.zone.zone_noend;
    }
    this.editZoneForm = new FormGroup({
      zone_colour: new FormControl(zone_colour, Validators.required),
      zone_desc: new FormControl(zone_desc, Validators.required),
      zone_nostart: new FormControl(zone_nostart, [Validators.required, Validators.min(0)]),
      zone_noend: new FormControl(zone_noend, [Validators.required, Validators.min(0)]),
    });
  }

  onSubmit(){
    this.isUpdateLoading = true;
    if(this.editMode){
      const jsonBody: Object ={
        "zone_colour" : this.editZoneForm.value.zone_colour,
        "zone_desc" : this.editZoneForm.value.zone_desc,
        "zone_nostart" : this.editZoneForm.value.zone_nostart,
        "zone_noend" : this.editZoneForm.value.zone_noend,
        "zone_id" : this.zone.zone_id
      }
      this.apiService.updateSingleZone(jsonBody).subscribe(resData => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: 'Group updated!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['../'], {relativeTo: this.route});
        });
      }, errorMessage => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      });
    }else{
      const jsonBody: Object ={
        "zone_colour" : this.editZoneForm.value.zone_colour,
        "zone_desc" : this.editZoneForm.value.zone_desc,
        "zone_nostart" : this.editZoneForm.value.zone_nostart,
        "zone_noend" : this.editZoneForm.value.zone_noend,
      }
      this.apiService.createSingleZone(jsonBody).subscribe(resData => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: 'Zone created!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['../'], {relativeTo: this.route});
        });
      }, errorMessage => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      });
    }
  }

  onClose(){
    if(!this.editZoneForm.dirty){
      this.router.navigate(['../'], {relativeTo: this.route});
    }else{
      Swal.fire({
        title: 'Leave without saving?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, leave without saving!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['../'], {relativeTo: this.route});
        }
      })
    }
  }

  onDelete(){

  }

}

