import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from '@services/api.service';
import {GroupService} from '@pages/volunteer-group/group.service';

@Injectable({providedIn: 'root'})
export class GroupsResolverService implements Resolve<{vg_id: string, vg_name: string, vg_desc: string, vg_leader: string, vg_leaderid: string, vg_count: string}[]>{
  constructor(private apiService: ApiService, private groupService: GroupService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{vg_id: string, vg_name: string, vg_desc: string, vg_leader: string, vg_leaderid: string, vg_count: string}[]> | Promise<{vg_id: string, vg_name: string, vg_desc: string, vg_leader: string, vg_leaderid: string,vg_count: string}[]> | {vg_id: string, vg_name: string, vg_desc: string, vg_leader: string, vg_leaderid: string, vg_count: string}[] {
    if(this.groupService.getGroups().length == 0){
      return this.apiService.fetchAllVolunteerGroups()
    }else{
      return this.groupService.getGroups();
    }

  }
}
