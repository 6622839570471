import {Injectable} from '@angular/core';

@Injectable()
export class TagsService{
  private tagList: {tag_id: string, tag_name: string}[] = [];

  setTags(groups: {tag_id: string, tag_name: string}[]){
    this.tagList = groups;
  }

  getTags(){
    return this.tagList.slice();
  }

  getTag(index: number){
    return this.tagList[index];
  }

}
