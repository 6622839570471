<nav class='navbar fixed-top navbar-light justify-content-start'
     style='background-color: rgb(255, 255, 255); border-bottom: 1px solid rgb(239, 239, 239); box-shadow: rgb(223, 223, 223) 1px 1px 4px; height: 65px'>
  <a class='navbar-brand' [routerLink]="['/']" style='cursor: pointer'>
    <img [src]="'assets/img/COV_Logo_3.png'" height='45' alt='' loading='lazy'>
  </a>
  <div>
    <button type='button' class='btn btn-secondary' (click)='open(content)'><i class='fa fa-history'
                                                                               aria-hidden='true'></i> History
    </button>
  </div>
</nav>
<body>
  <div class='container' style='margin-top: 85px'>
    <div class='row justify-content-center'>
      <div class='col-12 col-lg-6'>
        <div class='card'>
          <img [src]="'assets/img/church-bg.jpg'" class='card-img-top'>
          <router-outlet></router-outlet>
        </div>
        <p class='mb-3 text-muted' style='text-align: center'>Mass Registration System | Version: 2.2</p>
        <p class='mb-3 text-muted' style='text-align: center'>&copy; {{ currentYear }} Church of The Visitation Seremban. All Rights
          Reserved</p>
        <p class='mb-3 text-muted' style='text-align: center'>
          <a [routerLink]="['/']">Home</a> |
          <a href='https://visitationseremban.org/index.php/privacy-policy' target='_blank'>Privacy Policy</a> |
          <a href='https://forms.gle/1kRxdBsikPvsHF346' target='_blank'>Contact Us</a> |
          <a href='https://visitationseremban.org/index.php/mass-registration' target='_blank'>FAQ</a>
        </p>
      </div>
    </div>
  </div>
</body>

<ng-template #content let-modal>
  <div class='modal-header'>
    <h4 class='modal-title' id='modalHistory'>History</h4>
    <button type='button' class='close' aria-label='Close' (click)='modal.dismiss()'>
      <span aria-hidden='true'>&times;</span>
    </button>
  </div>
  <div class='modal-body'>
    <div *ngIf='historyData && !showQr && !showStatus'>
      <div class='alert alert-info' role='alert'>Registrations submitted using this device are displayed here.</div>
      <div *ngFor='let item of historyData'>
        <h5>>>>{{ item[0].mass }}</h5>
        <div class='row'>
          <div class='col-lg-6 col-sm-12' *ngFor='let mass of item'>
            <div class='card'>
              <div class='card-body' style='text-align: center'>
                <h3><strong>{{ mass.code }}</strong></h3>
                <p class='card-text'>{{ mass.name }}</p>
                <p class='card-text' style='font-size: smaller'>Mass Date/Time: {{ mass.date | dateTimeFormatFilter: 'DD MMMM YYYY hh:mm A' }}</p>
                <button class='btn btn-sm btn-outline-secondary' (click)='historyQR(mass.regId, mass.name, mass.code)'>
                  <i class='fas fa-qrcode'></i> Show QR Code
                </button><br>
                <button class='btn btn-sm btn-outline-success mt-1' (click)='historyStatus(mass.regId)'>
                  <i class='fas fa-mouse-pointer'></i> Status Check
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <app-loading-spinner *ngIf='historyStatusLoading'></app-loading-spinner>
    <div *ngIf='showStatus && statusCheckData.length === 1 && !historyStatusLoading'>
      <button class='btn btn-primary mb-3' (click)='historyGoBack()'>Go Back</button>
      <div class='card justify-content-center mb-0 rounded-0' style='background-color: #5cb85c; height: 80px; text-align: center'>
        <h4 style='color: white' class='mb-1'>{{ statusCheckData[0].reg_name }}</h4>
      </div>
      <div style='border: 2px solid #5cb85c'>
        <p class='text-center mb-0'><strong>This is your Registration Code:</strong></p>
        <h1 class='text-center mb-0'><span
          class='badge badge-secondary'>{{ statusCheckData[0].reg_code }}</span></h1>
        <hr>
        <p class='text-center mb-0'>
          <strong>Status: </strong>{{ getStatus(statusCheckData[0].cancelled, statusCheckData[0].reg_checkindatetime, statusCheckData[0].mass_datetime) }}
        </p>
        <div *ngIf='getStatus(statusCheckData[0].cancelled, statusCheckData[0].reg_checkindatetime, statusCheckData[0].mass_datetime) == "Attended"'>
          <p class='text-center mb-0'>
            <strong>Checked-In At: </strong>{{ statusCheckData[0].reg_checkindatetime }}
          </p>
          <p class='text-center mb-0'>
            <strong>Zone/Seat: </strong>{{ statusCheckData[0].zone_colour }} {{ statusCheckData[0].reg_checkinseat }}
          </p>
        </div>

      </div>
    </div>
    <div *ngIf='showStatus && statusCheckData.length !== 1 && !historyStatusLoading'>
      <button class='btn btn-primary mb-3' (click)='historyGoBack()'>Go Back</button>
      <div class='alert alert-warning' role='alert'>No Records Found</div>
    </div>
    <div *ngIf='showQr' style='text-align: center'>
      <button class='btn btn-primary' (click)='historyGoBack()'>Go Back</button>
      <qrcode [qrdata]='qrString.reg_id' [width]='256' [errorCorrectionLevel]="'H'"></qrcode>
      <h2>{{ qrString.reg_code }}</h2>
      <p>{{ qrString.reg_name }}</p>
    </div>
    <div style='text-align: center;' *ngIf='!historyData'>
      <img [src]="'assets/img/noresfound.gif'" height='80' class='mb-2' alt='' loading='lazy'>
      <h5><strong>No Records</strong></h5>
      <p>Your registrations submitted using this device will appear here</p>
    </div>
  </div>
  <div class='modal-footer'>
    <a [href]='' style='color: white;background: red;cursor: pointer' (click)='clearLocalStorage()' class='mr-auto'
       *ngIf='!showQr'><i class='fas fa-trash-alt'></i> Clear History</a>
    <button type='button' class='btn btn-dark' (click)='modal.dismiss()'>Close</button>
  </div>
</ng-template>
