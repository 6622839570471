import {Component, Input, OnInit} from '@angular/core';
import {Mass} from '@/model/mass.model';
import {AppService} from '@services/app.service';
import {User} from '@/model/user.model';

@Component({
  selector: 'app-mass',
  templateUrl: './mass.component.html',
  styleUrls: ['./mass.component.scss']
})
export class MassComponent implements OnInit {
  @Input() mass: Mass;
  @Input() mass_id: string;
  user: User;

  constructor(private appService: AppService) {}


  ngOnInit(): void {
    this.user = this.appService.user.getValue();
  }

  calPercentage(registered: string, max: string){
    let registeredInt = parseInt(registered);
    let maxInt = parseInt(max);

    return Math.round((registeredInt / maxInt)*100).toString();
  }

  ribbonColour(){
    if(this.mass.mass_selfcheckstartdatetime !== this.mass.mass_selfcheckenddatetime){
      return 'info';
    }else if(this.mass.mass_available == 'Y'){
      return 'success';
    }else{
      return 'danger';
    }
  }

  ribbonText(){
    if(this.mass.mass_selfcheckstartdatetime !== this.mass.mass_selfcheckenddatetime){
      return 'Self Check-in';
    }else if(this.mass.mass_available == 'Y'){
      return 'Available';
    }else{
      return 'Not Available';
    }
  }

}
