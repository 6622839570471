import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {Mass} from '@/model/mass.model';
import {Registrant} from '@/model/registrant.model';

@Injectable()
export class MassService{
  massesChanged = new BehaviorSubject<Mass[]>(null);
  archivedMassesChanged = new BehaviorSubject<Mass[]>(null);
  massRegistered = new BehaviorSubject<number>(0);
  private currentSelectedMass: Mass;
  private masses: Mass[] = [];
  private archivedMasses: Mass[] = [];
  private registrantList: Registrant[] = [];
  private registrantCancelledList: Registrant[] = [];

  setMasses(masses: Mass[]){
    this.masses = masses;
    this.massesChanged.next(this.masses.slice());
  }

  setArchivedMasses(masses: Mass[]){
    this.archivedMasses = masses;
    this.archivedMassesChanged.next(this.archivedMasses.slice());
  }

  getArchivedMasses(){
    return this.archivedMasses.slice();
  }

  getMasses(){
    return this.masses.slice();
  }

  setCurrentSelectedMass(mass: Mass){
    this.currentSelectedMass = mass;
    this.massRegistered.next(+mass.mass_registered);
  }

  getCurrentSelectedMass(){
    return this.currentSelectedMass;
  }

  setRegistrants(registrants: Registrant[]){
    this.registrantList = registrants;
  }

  getRegistrants(){
    return this.registrantList.slice();
  }

  getRegistrant(id: number){
    return this.registrantList[id];
  }

  setCancelledRegistrants(registrants: Registrant[]){
    this.registrantCancelledList = registrants;
  }

  getCancelledRegistrants(){
    return this.registrantCancelledList.slice();
  }

  getCancelledRegistrant(id: number){
    return this.registrantCancelledList[id];
  }

  increaseMassRegistered(){
    this.massRegistered.next(this.massRegistered.getValue() + 1);
  }

}
