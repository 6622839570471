<!-- Brand Logo -->
<a [routerLink]="['/']" class="brand-link">
    <img
        src="assets/img/logo.png"
        alt="COV Logo"
        class="brand-image img-circle elevation-3"
        style="opacity: 1"
    />
    <span class="brand-text font-weight-bold">MRSadmin</span>
</a>

<!-- Sidebar -->
<div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <img class="app-avatar" ngxLetterImageAvatar="{{ user.name }}" alt="avatar" >
<!--            <img-->
<!--                [src]="'assets/img/default-profile.png'"-->
<!--                class="img-circle elevation-2"-->
<!--                alt="User Image"-->
<!--            />-->
        </div>
        <div class="info">
            <a [routerLink]="['/admin/profile']" class="d-block">
                {{ user.name }}
            </a>
        </div>
    </div>

    <!-- Sidebar Menu -->
    <nav class="mt-2" style="overflow-y: hidden">
        <ul
            class="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
        >
            <!-- Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library -->
          <li class='nav-header'>
            MASS
          </li>
            <li class="nav-item">
                <a [routerLink]="['/admin/dashboard']" class="nav-link" routerLinkActive='active'>
                    <i class="nav-icon fas fa-tachometer-alt"></i>
                    <p>Dashboard</p>
                </a>
            </li>
          <li class="nav-item">
            <a [routerLink]="['/admin/archived']" class="nav-link" routerLinkActive='active'>
              <i class="nav-icon fas fa-archive"></i>
              <p>Archived</p>
            </a>
          </li>
          <li class='nav-header'>
            SYSTEM
          </li>
          <li class="nav-item">
            <a [routerLink]="['/admin/seating-zone']" class="nav-link" routerLinkActive='active'>
              <i class="nav-icon fas fa-chair"></i>
              <p>Seating Zone</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/admin/tags']" class="nav-link" routerLinkActive='active'>
              <i class="nav-icon fas fa-user-tag"></i>
              <p>Registrant Tags</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/admin/volunteer-group']" class="nav-link" routerLinkActive='active'>
              <i class="nav-icon fas fa-hands-helping"></i>
              <p>Volunteer Groups</p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/admin/user-management']" class="nav-link" routerLinkActive='active'>
              <i class="nav-icon fas fa-users"></i>
              <p>User Management</p>
            </a>
          </li>
          <li class="nav-item" style="position: fixed; bottom: 0px;">
            <a href='/register' target='_blank' class="nav-link">
              <i class="nav-icon fas fa-external-link-alt"></i>
              <p>Registration</p>
            </a>
          </li>
<!--            <li class="nav-item">-->
<!--                <a [routerLink]="['/admin/blank']" class="nav-link" routerLinkActive='active'>-->
<!--                    <i class="nav-icon fas fa-tachometer-alt"></i>-->
<!--                    <p>Blank</p>-->
<!--                </a>-->
<!--            </li>-->
        </ul>
    </nav>
</div>
