<!-- Main content -->
<section class='content'>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-lg-12 col-12'>
        <form [formGroup]='editZoneForm' (ngSubmit)='onSubmit()' *ngIf='zone || !editMode' autocomplete='off'>
          <div class='row'>
            <div class='col-lg-12 col-12'>
              <div class='card card-primary'>
                <img [src]="'assets/img/seating-zone.jpg'" class="card-img-top" style='width: 100%;height: 180px;object-fit: cover;'>
                <div class="card-img-overlay">
                  <div class='row'>
                    <div class='col-lg-6 col-8'>
                      <h1 class='m-0 text-light' style='text-shadow: 2px 2px 8px black'>{{ editMode ? 'Edit Seating Zone' : 'Add Seating Zone' }}</h1>
                    </div>
                    <div class='col-lg-6 col-4'>
                      <button type="button" class="btn btn-light fa-pull-right" title="Remove" (click)='onClose()'>
                        <i class="fas fa-times"></i>
                      </button>
                    </div>
                  </div>
                  <div class='d-none d-sm-block'>
                    <ol class='breadcrumb float-sm-left'>
                      <li class='breadcrumb-item'><a [routerLink]="['/admin/dashboard']">Home</a></li>
                      <li class='breadcrumb-item'><a [routerLink]="['../']">Seating Zone</a></li>
                      <li class='breadcrumb-item active' *ngIf='editMode'>{{ zone.zone_colour }}</li>
                      <li class='breadcrumb-item active'>{{ editMode ? 'Edit' : 'Add Seating Zone' }}</li>
                    </ol>
                  </div>
                </div>
                <div class='card-body' style='display: block;'>
                  <h5>Basic Details</h5>
                  <div class='row'>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='zone_colour'>Zone Colour <span style='color: red'>*</span></label>
                        <input type='text' id='zone_colour' formControlName='zone_colour' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='zone_desc'>Zone Description <span style='color: red'>*</span></label>
                        <input type='text' id='zone_desc' formControlName='zone_desc' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='zone_nostart'>Seat Number Begin <span style='color: red'>*</span></label>
                        <input type='number' id='zone_nostart' formControlName='zone_nostart' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='zone_noend'>Seat Number End <span style='color: red'>*</span></label>
                        <input type='number' id='zone_noend' formControlName='zone_noend' class='form-control'>
                      </div>
                    </div>
                  </div>
                  <div class='alert alert-default-info' *ngIf='!editZoneForm.valid'>
                    Form is not valid. Please check the form before saving.
                  </div>
                  <div class='alert alert-default-info' *ngIf='editZoneForm.valid && !editZoneForm.dirty'>
                    There are no changes made yet.
                  </div>
                  <button type='button' class='btn btn-primary' style='z-index: 20; position: relative' (click)='onClose()'>Go Back</button>
                  <div class='row fa-pull-right'>
                    <div class='col-lg-6 col-6'>
                      <app-button
                        [type]="'submit'"
                        [block]='false'
                        [color]="'success'"
                        [loading]='isUpdateLoading'
                        [disabled]='!editZoneForm.valid || !editZoneForm.dirty'>
                        Save
                      </app-button>
                    </div>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->

