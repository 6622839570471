import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {RegistrantStatusCheck} from '@/model/registrant-status-check.model';
import {PublicApiService} from '@services/public-api.service';
import {DateTime} from 'luxon';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.scss']
})
export class PublicComponent implements OnInit {
  LOCAL_STORAGE_KEY: string = 'VISITATION_MRS';
  historyData: any;
  qrString: {reg_id: string, reg_name: string, reg_code: string} = null;
  showQr: boolean = false;
  showStatus: boolean = false;
  statusCheckData: RegistrantStatusCheck[] = [];
  historyStatusLoading: boolean = false;
  currentYear: string = DateTime.now().toFormat('y');


  constructor(private modalService: NgbModal, private publicApiService: PublicApiService) { }

  ngOnInit(): void {
  }

  groupBy(objectArray, property) {
    let result = [];
    objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
        result.push(acc[key]);
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
    return result;
  }

  open(content) {
    if (typeof (Storage) !== 'undefined') {
      if (localStorage.getItem(this.LOCAL_STORAGE_KEY) !== null) {
        let data = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY));
        this.historyData = this.groupBy(data, 'mass');
      }
    }
    this.modalService.open(content, {
      ariaLabelledBy: 'modalHistory',
      size: 'lg',
      scrollable: true
    }).result.then((result) => {

    }, (reason) => {
      this.showQr = false;
      this.qrString = null;
    });
  }

  clearLocalStorage() {
    let clear = confirm('Confirm clear history?');
    if (clear == true) {
      localStorage.removeItem(this.LOCAL_STORAGE_KEY);
      this.historyData = null;
      alert('Cleared!');
    } else {

    }
  }

  historyQR(reg_id: string, reg_name: string, reg_code: string) {
    this.showQr = true;
    this.qrString = {reg_id, reg_name, reg_code};
  }

  historyGoBack() {
    this.showQr = false;
    this.qrString = null;
    this.showStatus = false;
    this.statusCheckData = [];
  }

  historyStatus(reg_id: string){
    this.historyStatusLoading = true;
    this.showStatus = true;
    this.publicApiService.fetchSingleStatusCheck(reg_id).subscribe(resData =>{
      this.historyStatusLoading = false;
      this.statusCheckData = resData;
    }, errorMessage => {
      this.historyStatusLoading = false;
    })
  }

  getStatus(cancelled: string, checkin_datetime: string, mass_datetime: string){
    if(checkin_datetime !== '-'){
      return 'Attended';
    }else if(cancelled === '1'){
      return 'Cancelled';
    }else if(new Date() > new Date(mass_datetime)){
      return 'Did Not Attend';
    }else{
      return 'Confirmed';
    }
  }
}
