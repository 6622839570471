import {Injectable} from '@angular/core';
import {Profile} from '@/model/profile.model';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class ProfileService{
  profile = new BehaviorSubject<Profile>(null);

  initProfile(name: string, email: string, password: string, phone: string, createdBy: string, lastLogin: string, createdDateTime: string, accessLevel: string[], group: string[]): void{
    this.profile.next(new Profile(name, email, password, phone, createdBy, lastLogin, createdDateTime, accessLevel, group));
  }
}
