import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subject} from 'rxjs';
import {ApiService} from '@services/api.service';
import Swal from 'sweetalert2';
import {GroupService} from '@pages/volunteer-group/group.service';

@Component({
  selector: 'app-volunteer-group-list',
  templateUrl: './volunteer-group-list.component.html',
  styleUrls: ['./volunteer-group-list.component.scss']
})
export class VolunteerGroupListComponent implements OnInit, OnDestroy {
  isInitialLoading: boolean = false;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  groupList: {vg_id: string, vg_name: string, vg_desc: string, vg_leader: string, vg_leaderid: string, vg_count: string}[] = [];

  constructor(private apiService: ApiService, private groupService: GroupService) { }

  ngOnInit(): void {
    this.isInitialLoading = true;
    this.dtOptions ={
      responsive: true,
      autoWidth: false,
      columnDefs: [
        { responsivePriority: 0, targets: -1 }
      ],
      dom: 'Bfrtipl',
      buttons: ["copy",
        {
          extend: 'csvHtml5',
          title: 'MRS_All_Volunteer_Groups'
        },
        {
          extend: 'excelHtml5',
          title: 'MRS_All_Volunteer_Groups'
        },
        {
          extend: 'pdfHtml5',
          messageBottom: 'Church of the Visitation Seremban. All Rights Reserved.',
          title: 'MRS_All_Volunteer_Groups'
        },
        "print",
        "colvis"]
    }
    this.apiService.fetchAllVolunteerGroups().subscribe(resData =>{
      this.isInitialLoading = false;
      this.groupService.setGroups(resData);
      this.groupList = this.groupService.getGroups();
      this.dtTrigger.next();
    }, errorMessage => {
      this.isInitialLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onEdit(user_id: string){
    console.log(user_id);
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
}
