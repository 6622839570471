<!-- Main content -->
<section class='content'>
  <div class='container-fluid'>
    <div class='row'>
      <div class='col-lg-12 col-12'>
        <form [formGroup]='editRegistrantForm' (ngSubmit)='onSubmit()' *ngIf='registrant || !editMode'
              autocomplete='off'>
          <div class='row'>
            <div class='col-lg-12 col-12'>
              <div class='card card-primary'>
                <img [src]="'assets/img/mass-management.jpg'" class='card-img-top'
                     style='width: 100%;height: 180px;object-fit: cover;'>
                <div class='card-img-overlay'>
                  <div class='row'>
                    <div class='col-lg-6 col-8'>
                      <h1 class='m-0 text-light'
                          style='text-shadow: 2px 2px 8px black'>{{ editMode ? 'Edit Registrant' : 'Add Registrant' }}</h1>
                    </div>
                    <div class='col-lg-6 col-4'>
                      <button type='button' class='btn btn-light fa-pull-right' title='Remove' (click)='onClose()'>
                        <i class='fas fa-times'></i>
                      </button>
                    </div>
                  </div>
                  <div class='d-none d-sm-block'>
                    <ol class='breadcrumb float-sm-left'>
                      <li class='breadcrumb-item'><a [routerLink]="['/admin/dashboard']">Home</a></li>
                      <li class='breadcrumb-item'><a [routerLink]="['../']">Mass</a></li>
                      <li class='breadcrumb-item active' *ngIf='editMode'>{{ registrant.reg_name }}</li>
                      <li class='breadcrumb-item active'>{{ editMode ? 'Edit' : 'Register' }}</li>
                    </ol>
                  </div>
                </div>
                <div class='card-body' style='display: block;'>
                  <h5>Basic Details</h5>
                  <div class='row'>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='reg_name'>Name <span style='color: red'>*</span></label>
                        <input type='text' id='reg_name' formControlName='reg_name' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='reg_birthdate'>Birthdate <span style='color: red'>*</span></label>
                        <input type='date' id='reg_birthdate' formControlName='reg_birthdate' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='reg_phone'>Phone Number <span style='color: red'>*</span></label>
                        <input type='tel' id='reg_phone' formControlName='reg_phone' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='reg_bec'>Zone/BEC/Area <span style='color: red'>*</span></label>
                        <input type='text' id='reg_bec' formControlName='reg_bec' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-6 col-12'>
                      <div class='form-group'>
                        <label for='reg_email'>Email </label>
                        <input type='email' id='reg_email' formControlName='reg_email' class='form-control'>
                      </div>
                    </div>
                    <div class='col-lg-12 col-12'>
                      <div class='form-group'>
                        <label for='reg_remarks'>Remarks </label>
                        <textarea cols='20' rows='4' id='reg_remarks' class='form-control'
                                  formControlName='reg_remarks'></textarea>
                      </div>
                    </div>
                  </div>
                  <div class='alert alert-default-info' *ngIf='!editRegistrantForm.valid'>
                    Form is not valid. Please check the form before saving.
                  </div>
                  <div class='alert alert-default-info' *ngIf='editRegistrantForm.valid && !editRegistrantForm.dirty'>
                    There are no changes made yet.
                  </div>
                  <button type='button' class='btn btn-primary' style='z-index: 20; position: relative'
                          (click)='onClose()'>Go Back
                  </button>
                  <div class='row fa-pull-right'>
                    <div class='col-lg-6 col-6'>
                      <app-button
                        [type]="'submit'"
                        [block]='false'
                        [color]="'success'"
                        [loading]='isUpdateLoading'
                        [disabled]='!editRegistrantForm.valid || !editRegistrantForm.dirty'>
                        Save
                      </app-button>
                    </div>
                  </div>
                  <!-- /.card-body -->
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class='col-lg-6 col-12' *ngIf='editMode'>
        <div class='row'>
          <div class='col-12'>
            <div class='card'>
              <div class='card-body'>
                <h5>Check-In & Seat Details</h5>
                <div class='row'>
                  <div class='col-lg-6 col-12'>
                    <strong><i class='fas fa-chair mr-1'></i> Zone & Seat</strong>
                    <p class='text-muted'>{{ registrant.reg_zone }} {{ registrant.reg_checkinseat }}</p>
                    <hr>
                  </div>
                  <div class='col-lg-6 col-12'>
                    <strong><i class='fas fa-clock mr-1'></i> Checked-In At</strong>
                    <p class='text-muted'>{{ registrant.reg_checkindatetime }}</p>
                    <hr>
                  </div>
                  <div class='col-lg-6 col-12'>
                    <strong><i class='fas fa-user-tie mr-1'></i> Checked-In By</strong>
                    <p class='text-muted'>{{ registrant.reg_checkinname }}</p>
                    <hr>
                  </div>
                </div>
                <button type='button' class='btn btn-primary btn-block' style='z-index: 20; position: relative' disabled>
                  Pre-allocate Seat
                </button>
              </div>
            </div>
          </div>
          <div class='col-12'>
            <div class='card'>
              <div class='card-body'>
                <h5>QR Code</h5>
                <div style='text-align: center'>
                  <qrcode [qrdata]='registrantQrString' [width]='256' [errorCorrectionLevel]="'H'"></qrcode>
                  <!--              <h3><span class="badge badge-secondary">{{ mass.mass_prefix }} {{ registrant.reg_code }}</span></h3>-->
                  <h3>{{ registrant.reg_code }}</h3>
                  <h3>{{ registrant.reg_name }}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-6 col-12' *ngIf='editMode'>
        <div class='col-12'>
          <div class='card'>
            <div class='card-body'>
              <h5>Tags</h5>
              <table class='table' *ngIf='assignedTags.length > 0'>
                <thead>
                <tr>
                  <th style='width: 10px'>#</th>
                  <th>Tag</th>
                  <th style='width: 20px'>Actions</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor='let tag of assignedTags; let i = index'>
                  <td>{{ i + 1 }}.</td>
                  <td>{{ tag.tag_name }}</td>
                  <td>
                    <button class='btn btn-danger' (click)='onDeleteRegistrantTag(tag.tag_id)'><i
                      class='fas fa-trash-alt'></i></button>
                  </td>
                </tr>
                </tbody>
              </table>
              <div style='text-align: center;' *ngIf='assignedTags.length == 0'>
                <img [src]="'assets/img/noresfound.gif'" height='80' class='mb-2' alt='' loading='lazy'>
                <h5>No tags assigned</h5>
              </div>
              <button class='btn btn-primary btn-block' (click)='showRegistrantTagForm()'
                      *ngIf='!registrantTagFormVisible'>Assign New Tag
              </button>
              <form [formGroup]='registrantTagForm' (ngSubmit)='onAssignRegistrantTag()' *ngIf='registrantTagFormVisible'
                    autocomplete='off'>
                <div class='form-group'>
                  <label for='tags'>Please Select</label>
                  <select class='custom-select' id='tags' formControlName='tags'>
                    <option value='' disabled>Choose an option</option>
                    <option *ngFor='let tag of availableTags'
                            [ngValue]='tag.tag_id'>{{ tag.tag_name }}</option>
                  </select>
                </div>
                <div class='row fa-pull-right'>
                  <div class='col-lg-6 col-6'>
                    <app-button
                      [type]="'submit'"
                      [block]='false'
                      [color]="'success'"
                      [loading]='isAssignLoading'
                      [disabled]='!registrantTagForm.valid || !registrantTagForm.dirty'>
                      Save
                    </app-button>
                  </div>
                  <div class='col-lg-6 col-6'>
                    <button type='button' class='btn btn-danger' (click)='onCloseRegistrantTagForm()'>Cancel</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
        <div class='col-12'>
          <div class='card'>
            <div class='card-body'>
              <h5>Send Confirmation Email</h5>
              <form [formGroup]='sendEmailForm' (ngSubmit)='sendConfirmationEmail()'
                    autocomplete='off'>
                <div class='form-group'>
                  <label for='send_email'>Email </label>
                  <input type='email' id='send_email' formControlName='send_email' class='form-control'>
                </div>
                <app-button
                  [type]="'submit'"
                  [block]='true'
                  [color]="'primary'"
                  [loading]='isSendingEmail'
                  [disabled]='!sendEmailForm.valid || !sendEmailForm.dirty'>
                  Send
                </app-button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class='col-lg-6 col-12' *ngIf='editMode'>

      </div>
    </div>
  </div>
  <!-- Main row -->
</section>
<!-- /.content -->

