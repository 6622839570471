import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgxLetterImageAvatarModule} from 'ngx-letter-image-avatar';

import {AppRoutingModule} from '@/app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from '@modules/main/main.component';
import {LoginComponent} from '@modules/login/login.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProfileComponent} from '@pages/profile/profile.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RegisterComponent} from '@modules/register/register.component';
import {DashboardComponent} from '@pages/dashboard/dashboard.component';
import {ToastrModule} from 'ngx-toastr';
import {MessagesDropdownMenuComponent} from '@modules/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import {NotificationsDropdownMenuComponent} from '@modules/main/header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import {AppButtonComponent} from './components/app-button/app-button.component';
import {MatButtonModule} from '@angular/material/button';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {DataTablesModule} from 'angular-datatables';
import {QRCodeModule} from 'angularx-qrcode';

import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {UserDropdownMenuComponent} from '@modules/main/header/user-dropdown-menu/user-dropdown-menu.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {LanguageDropdownComponent} from '@modules/main/header/language-dropdown/language-dropdown.component';
import {PrivacyPolicyComponent} from './modules/privacy-policy/privacy-policy.component';
import {ProfileAccessLevelComponent} from './pages/profile/profile-access-level/profile-access-level.component';
import {ProfileVolunteerGroupComponent} from './pages/profile/profile-volunteer-group/profile-volunteer-group.component';
import {ApiInterceptorService} from '@services/api-interceptor.service';
import {ProfileService} from '@pages/profile/profile.service';
import {MassComponent} from './pages/dashboard/mass-list/mass/mass.component';
import {MassService} from '@pages/dashboard/mass.service';
import {MassDetailComponent} from './pages/dashboard/mass-detail/mass-detail.component';
import {MassListComponent} from './pages/dashboard/mass-list/mass-list.component';
import {MatIconModule} from '@angular/material/icon';
import {MassEditComponent} from './pages/dashboard/mass-edit/mass-edit.component';
import {SeatingZoneComponent} from './pages/seating-zone/seating-zone.component';
import {VolunteerGroupComponent} from './pages/volunteer-group/volunteer-group.component';
import {UserManagementComponent} from './pages/user-management/user-management.component';
import {UserListComponent} from './pages/user-management/user-list/user-list.component';
import {VolunteerGroupListComponent} from './pages/volunteer-group/volunteer-group-list/volunteer-group-list.component';
import {LoadingSpinnerComponent} from '@components/loading-spinner/loading-spinner.component';
import {SeatingZoneListComponent} from './pages/seating-zone/seating-zone-list/seating-zone-list.component';
import {UserEditComponent} from './pages/user-management/user-edit/user-edit.component';
import {VolunteerGroupEditComponent} from './pages/volunteer-group/volunteer-group-edit/volunteer-group-edit.component';
import {GroupService} from '@pages/volunteer-group/group.service';
import {AboutComponent} from './pages/about/about.component';
import {
  GeneratedSeatsModalContent,
  SeatingZoneEditComponent
} from './pages/seating-zone/seating-zone-edit/seating-zone-edit.component';
import {ZoneService} from '@pages/seating-zone/zone.service';
import {RegistrantEditComponent} from './pages/dashboard/registrant-edit/registrant-edit.component';
import {ShortenPipe} from '@components/shorten.pipe';
import {AgePipe} from '@components/age.pipe';
import {RegistrantPreComponent} from './pages/dashboard/mass-detail/registrant-pre/registrant-pre.component';
import {RegistrantCancelComponent} from './pages/dashboard/mass-detail/registrant-cancel/registrant-cancel.component';
import {PublicRegisterComponent} from './pages/public/public-register/public-register.component';
import {PublicRegisterService} from '@pages/public/public-register/public-register.service';
import {DateTimeFormatPipe} from '@components/dateTimeFormatFilter.pipe';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PublicComponent } from './pages/public/public.component';
import { PublicStatusComponent } from './pages/public/public-status/public-status.component';
import { PublicCancelComponent } from './pages/public/public-cancel/public-cancel.component';
import { MassArchivedListComponent } from './pages/dashboard/mass-archived-list/mass-archived-list.component';
import { RegistrantImportComponent } from './pages/dashboard/registrant-import/registrant-import.component';
import { TagsComponent } from './pages/tags/tags.component';
import { TagsEditComponent } from './pages/tags/tags-edit/tags-edit.component';
import { TagsListComponent } from './pages/tags/tags-list/tags-list.component';
import { LoadingNewsComponent } from './components/loading-news/loading-news.component'
import {TagsService} from '@pages/tags/tags.service';
import { PublicRetiredComponent } from './pages/public/public-retired/public-retired.component';

registerLocaleData(localeEn, 'en-EN');

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    MenuSidebarComponent,
    BlankComponent,
    ProfileComponent,
    RegisterComponent,
    DashboardComponent,
    MessagesDropdownMenuComponent,
    NotificationsDropdownMenuComponent,
    AppButtonComponent,
    UserDropdownMenuComponent,
    ForgotPasswordComponent,
    RecoverPasswordComponent,
    LanguageDropdownComponent,
    PrivacyPolicyComponent,
    ProfileAccessLevelComponent,
    ProfileVolunteerGroupComponent,
    MassComponent,
    MassDetailComponent,
    MassListComponent,
    MassEditComponent,
    SeatingZoneComponent,
    VolunteerGroupComponent,
    UserManagementComponent,
    UserListComponent,
    VolunteerGroupListComponent,
    LoadingSpinnerComponent,
    SeatingZoneListComponent,
    UserEditComponent,
    VolunteerGroupEditComponent,
    AboutComponent,
    SeatingZoneEditComponent,
    RegistrantEditComponent,
    ShortenPipe,
    AgePipe,
    RegistrantPreComponent,
    RegistrantCancelComponent,
    PublicRegisterComponent,
    DateTimeFormatPipe,
    PublicComponent,
    PublicStatusComponent,
    PublicCancelComponent,
    MassArchivedListComponent,
    RegistrantImportComponent,
    TagsComponent,
    TagsEditComponent,
    TagsListComponent,
    LoadingNewsComponent,
    GeneratedSeatsModalContent,
    PublicRetiredComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right',
      preventDuplicates: true
    }),
    NgxLetterImageAvatarModule,
    MatButtonModule,
    MatIconModule,
    SweetAlert2Module.forRoot(),
    DataTablesModule,
    QRCodeModule,
    DragDropModule,
    NgxMaskModule.forRoot(),
    NgbModule
  ],
  providers: [ProfileService, MassService, GroupService, ZoneService, TagsService, PublicRegisterService, {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
