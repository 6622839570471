import { Component, OnInit } from '@angular/core';
import {ProfileService} from '@pages/profile/profile.service';
import {Profile} from '@/model/profile.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-profile-access-level',
  templateUrl: './profile-access-level.component.html',
  styleUrls: ['./profile-access-level.component.scss']
})
export class ProfileAccessLevelComponent implements OnInit {
  profileAccessLevels: string[];

  constructor(private profileService: ProfileService) { }

  ngOnInit(): void {
    this.profileAccessLevels = this.profileService.profile.getValue().accessLevel;
  }

}
