import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import Swal from 'sweetalert2';
import {ApiService} from '@services/api.service';
import {TagsService} from '@pages/tags/tags.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {DataTableDirective} from 'angular-datatables';

@Component({
  selector: 'app-tags-list',
  templateUrl: './tags-list.component.html',
  styleUrls: ['./tags-list.component.scss']
})
export class TagsListComponent implements OnInit, OnDestroy {
  isInitialLoading: boolean = false;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  tagList: {tag_id: string, tag_name: string}[] = [];

  initialLoad: boolean = true;

  editMode: boolean = false;
  selectedTagId: string = null;
  updateTagForm: FormGroup;
  updateTagModalRef : NgbModalRef;
  isUpdateLoading: boolean = false;


  constructor(private apiService: ApiService, private tagService: TagsService, private modalService: NgbModal, private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.isInitialLoading = true;
    this.dtOptions ={
      responsive: true,
      autoWidth: false,
      columnDefs: [
        { responsivePriority: 0, targets: -1 }
      ],
      dom: 'Bfrtipl',
      buttons: ["copy",
        {
          extend: 'csvHtml5',
          title: 'MRS_All_Tags'
        },
        {
          extend: 'excelHtml5',
          title: 'MRS_All_Tags'
        },
        {
          extend: 'pdfHtml5',
          messageBottom: 'Church of the Visitation Seremban. All Rights Reserved.',
          title: 'MRS_All_Tags'
        },
        "print",
        "colvis"]
    }
    this.updateTagForm = new FormGroup({
      tag_name: new FormControl('', Validators.required)
    })
    this.fetchTags();
  }

  fetchTags(){
    this.apiService.fetchAllTags().subscribe(resData =>{
      this.isInitialLoading = false;
      this.tagService.setTags(resData);
      this.tagList = this.tagService.getTags();
      if(this.initialLoad){
        this.dtTrigger.next();
        this.initialLoad = false;
      }else{
        this.rerender();
      }
    }, errorMessage => {
      this.isInitialLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  open(content, id: string) {
    if(id !== '-1'){
      this.editMode = true;
      this.selectedTagId = id;
      let found = this.tagList.find(element => element.tag_id === id);
      if(found){
        this.updateTagForm.patchValue({
          tag_name: found.tag_name
        })
      }else{
        this.toastr.error('An Error Occurred!');
      }
    }
    this.updateTagModalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modalUpdateTag',
      scrollable: true
    });
    this.updateTagModalRef.hidden.subscribe(event => {
      this.updateTagForm.reset();
      this.editMode = false;
      this.selectedTagId = null;
    })
  }

  onUpdateTag(){
    let tag_name = this.updateTagForm.value.tag_name.trim();
    if(this.editMode){
      const jsonBody: Object = {
        "tag_id" : this.selectedTagId,
        "tag_name" : tag_name
      }
      this.apiService.updateSingleTag(jsonBody).subscribe(resData =>{
        Swal.fire({
          title: 'Done!',
          html: 'Tag updated.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
        this.closeUpdateTagModal();
        this.fetchTags();
      }, errorMessage =>{
        Swal.fire({
          title: 'Opps...',
          html: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      })
    }else{
      const jsonBody: Object = {
        "tag_name" : tag_name
      }
      this.apiService.createSingleTag(jsonBody).subscribe(resData =>{
        Swal.fire({
          title: 'Done!',
          html: 'Tag created.',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
        this.closeUpdateTagModal();
        this.fetchTags();
      }, errorMessage =>{
        Swal.fire({
          title: 'Opps...',
          html: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      })
    }
  }

  closeUpdateTagModal(){
    this.updateTagModalRef.close();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  onDeleteTag(id: string){
    Swal.fire({
      title: 'Delete Tag?',
      text: "You can't undo this action later.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!',
      cancelButtonText: 'No'
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonBody: Object ={
          "tag_id" : id,
        }
        this.apiService.deleteSingleTag(jsonBody).subscribe(resData => {
          this.toastr.success("Tag deleted successfully.")
          this.fetchTags();
        },errorMessage =>{
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        })
      }
    })
  }


}
