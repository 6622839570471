import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ApiService} from '@services/api.service';
import {ZoneService} from '@pages/seating-zone/zone.service';

@Injectable({providedIn: 'root'})
export class ZonesResolverService implements Resolve<{zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string}[]>{
  constructor(private apiService: ApiService, private zoneService: ZoneService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string}[]> | Promise<{zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string}[]> | {zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string}[] {
    if(this.zoneService.getZones().length == 0){
      return this.apiService.fetchAllSeatingZones()
    }else{
      return this.zoneService.getZones();
    }

  }
}
