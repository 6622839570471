import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppService} from '@services/app.service';
import {ProfileService} from '@pages/profile/profile.service';
import {Mass} from '@/model/mass.model';
import {map, tap} from 'rxjs/operators';
import {MassService} from '@pages/dashboard/mass.service';
import {GroupService} from '@pages/volunteer-group/group.service';
import {ZoneService} from '@pages/seating-zone/zone.service';
import {Registrant} from '@/model/registrant.model';
import {ExcelRegistrant} from '@/model/excelRegistrant.model';
import {TagsService} from '@pages/tags/tags.service';
import {Seat} from '@/model/seat.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  url: string = 'https://api.visitationseremban.org/mrs/api/';

  constructor(private http: HttpClient, private appService: AppService, private profileService: ProfileService,
              private massService: MassService, private groupService: GroupService, private zoneService: ZoneService, private tagService: TagsService) {
  }

  fetchProfileDetails(){
    this.http.get<any>(this.url +'admin_sys_user_view_single.php?userid='+this.appService.user.getValue().id).subscribe(resData =>{
      this.profileService.initProfile(
        resData.user_name,
        resData.user_email,
        resData.user_password,
        resData.user_phone,
        resData.user_createdby,
        resData.user_lastlogin,
        resData.user_createddatetime,
        resData.access_level,
        resData.group
      )
    })
  }

  fetchAllMasses(){
    return this.http.get<Mass[]>(this.url +'admin_mass_view_all.php?archived=N').pipe(
      map(masses => {
        return masses.map(mass => {
          return {...mass};
        });
      }),
      tap(masses => {
        this.massService.setMasses(masses);
      })
    );
  }

  fetchArchivedMasses(){
    return this.http.get<Mass[]>(this.url +'admin_mass_view_all.php?archived=Y').pipe(
      map(masses => {
        return masses.map(mass => {
          return {...mass};
        });
      }),
      tap(masses => {
        this.massService.setArchivedMasses(masses);
      })
    );
  }

  fetchSingleMass(massid: string){
    return this.http.get<Mass>(this.url +'admin_mass_view_single.php?massid='+massid);
  }

  updateSingleMass(massJson: Object){
    return this.http.post(this.url +'admin_mass_update.php', massJson);
  }

  createSingleMass(massJson: Object){
    return this.http.post(this.url +'admin_mass_create.php', massJson);
  }

  fetchAllUsers(){
    return this.http.get<{user_id: string, user_name: string, user_email: string, user_phone: string, user_accountstatus: string, createdby: string,
    user_lastlogin: string}[]>
    (this.url +'admin_sys_user_view_all.php');
  }

  fetchAllVolunteerGroups(){
    return this.http.get<{vg_id: string, vg_name: string, vg_desc: string, vg_leader: string, vg_leaderid: string, vg_count: string}[]>
    (this.url +'admin_volunteer_group_view_all.php').pipe(tap(resData =>{
      this.groupService.setGroups(resData);
    }));
  }

  fetchAllSeatingZones(){
    return this.http.get<{zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string}[]>
    (this.url +'admin_zone_view_all.php').pipe(tap(resData =>{
      this.zoneService.setZones(resData);
    }));
  }

  fetchAllRegistrants(massid: string){
    return this.http.get<Registrant[]>
    (this.url +'admin_registrant_view_all.php?massid='+massid).pipe(
      map(registrants => {
        return registrants.map(registrant => {
          return {...registrant};
        });
      }),
      tap(registrants => {
        this.massService.setRegistrants(registrants);
      })
    );
  }

  createSingleUser(userJson: Object){
    return this.http.post(this.url +'admin_register_sys_user.php', userJson);
  }

  fetchSingleUserDetails(userid: string){
    return this.http.get<any>(this.url +'admin_sys_user_view_single.php?userid='+userid);
  }

  fetchSingleUserAccessLevels(userid: string){
    return this.http.get<{level_id: string, level_desc: string}[]>(this.url +'admin_sys_user_access_level_view.php?userid='+userid);
  }

  fetchSingleUserVolunteerGroups(userid: string){
    return this.http.get<{vg_id: string, vg_name: string, vg_desc: string}[]>(this.url +'admin_sys_user_volunteer_group_view.php?userid='+userid);
  }

  fetchSingleUserAvailableAccessLevels(userid: string){
    return this.http.get<{level_id: string, level_desc: string}[]>(this.url +'admin_sys_user_available_access_level.php?userid='+userid);
  }

  assignSingleUserAccessLevel(json: Object){
    return this.http.post(this.url +'admin_access_level_assign.php', json);
  }

  removeSingleUserAccessLevel(json: Object){
    return this.http.post(this.url +'admin_access_level_remove_assign.php', json);
  }

  fetchSingleUserAvailableGroups(userid: string){
    return this.http.get<{vg_id: string, vg_name: string}[]>(this.url +'admin_sys_user_available_volunteer_group.php?userid='+userid);
  }

  assignSingleUserGroup(json: Object){
    return this.http.post(this.url +'admin_volunteer_group_assign.php', json);
  }

  removeSingleUserGroup(json: Object){
    return this.http.post(this.url +'admin_volunteer_group_remove_assign.php', json);
  }

  updateSingleUser(json: Object){
    return this.http.post(this.url +'admin_sys_user_update.php', json);
  }

  fetchGroupAvailableUsers(){
    return this.http.get<{user_id: string, user_name: string}[]>(this.url +'admin_volunteer_group_available_users.php');
  }

  fetchGroupAssignedUsers(vg_id: string){
    return this.http.get<{user_id: string, user_name: string}[]>(this.url +'admin_volunteer_group_assigned_users.php?vgid='+ vg_id);
  }

  updateSingleGroup(json: Object){
    return this.http.post(this.url +'admin_volunteer_group_update.php', json);
  }

  createSingleGroup(json: Object){
    return this.http.post(this.url +'admin_volunteer_group_create.php', json);
  }

  updateSingleZone(json: Object){
    return this.http.post(this.url +'admin_zone_update.php', json);
  }

  createSingleZone(json: Object){
    return this.http.post(this.url +'admin_zone_create.php', json);
  }

  createRegistrantAdmin(json: Object){
    return this.http.post<any>(this.url +'admin_register_mass.php', json);
  }

  cancelPreRegistrant(json: Object){
    return this.http.post(this.url +'admin_mass_registrant_cancel.php', json);
  }

  fetchAllCancelledRegistrants(massid: string){
    return this.http.get<Registrant[]>
    (this.url +'admin_registrant_cancelled_view_all.php?massid='+massid).pipe(
      map(registrants => {
        return registrants.map(registrant => {
          return {...registrant};
        });
      }),
      tap(registrants => {
        this.massService.setCancelledRegistrants(registrants);
      })
    );
  }

  updateSingleRegistrant(json: Object){
    return this.http.post(this.url +'admin_registrant_update.php', json);
  }

  restoreCancelledRegistrant(json: Object){
    return this.http.post(this.url +'admin_registrant_cancelled_restore.php', json);
  }

  deleteMass(json: Object){
    return this.http.post(this.url +'admin_mass_delete.php', json);
  }

  changeUserPassword(json: Object){
    return this.http.post(this.url + 'admin_sys_user_changepwd.php', json);
  }

  fetchMassRestrictions(massid: string){
    return this.http.get<{restrict_mass_id: string, mass_desc: string, mass_lang: string, mass_datetime: string}[]>(this.url +'admin_mass_restriction_view.php?massid='+massid);
  }

  removeMassRestriction(json: Object){
    return this.http.post(this.url +'admin_mass_restriction_remove.php', json);
  }

  fetchAvailableMassRestrictions(massid: string){
    return this.http.get<{mass_id: string, mass_desc: string, mass_lang: string, mass_datetime: string}[]>(this.url +'admin_mass_available_restriction.php?massid='+massid);
  }

  assignMassRestriction(json: Object){
    return this.http.post(this.url +'admin_mass_restriction_assign.php', json);
  }

  uploadExcelFile(formData: FormData){
    return this.http.post<ExcelRegistrant>(this.url +'admin_upload_xlsx.php', formData);
  }

  importExcelFile(json: Object){
    return this.http.post<{error: string, message: string}>(this.url +'admin_import_xlsx.php', json);
  }

  fetchRegistrantTags(regid: string){
    return this.http.get<{tag_id: string, tag_name: string}[]>(this.url +'admin_registrant_tag_view.php?regid='+regid);
  }

  removeRegistrantTag(json: Object){
    return this.http.post(this.url +'admin_registrant_tag_remove.php', json);
  }

  fetchAvailableRegistrantTags(regid: string){
    return this.http.get<{tag_id: string, tag_name: string}[]>(this.url +'admin_registrant_available_tag.php?regid='+regid);
  }

  assignRegistrantTag(json: Object){
    return this.http.post(this.url +'admin_registrant_tag_assign.php', json);
  }

  fetchAllTags(){
    return this.http.get<{tag_id: string, tag_name: string}[]>
    (this.url +'admin_tag_view_all.php').pipe(tap(resData =>{
      this.tagService.setTags(resData);
    }));
  }

  updateSingleTag(json: Object){
    return this.http.post(this.url +'admin_tag_update.php', json);
  }

  createSingleTag(json: Object){
    return this.http.post(this.url +'admin_tag_create.php', json);
  }

  deleteSingleTag(json: Object){
    return this.http.post(this.url +'admin_tag_remove.php', json);
  }

  insertGeneratedSeats(json: Object){
    return this.http.post<any>(this.url + 'admin_seats_insert.php', json);
  }

  fetchSeats(zoneid: string){
    return this.http.get<Seat[]>(this.url +'admin_zone_view_seats.php?zoneid='+zoneid);
  }

  updateSeatStatus(json: Object){
    return this.http.post<any>(this.url + 'admin_seats_update_status.php', json);
  }

  sendConfirmationEmail(email: string, reg_id: string){
    return this.http.get(this.url +'email_registration_confirm.php?reg_id='+reg_id+'&email='+email);
  }
}
