import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {RegistrantCancelData} from '@/model/registrant-cancel-data.model';
import {PublicApiService} from '@services/public-api.service';
import Swal from 'sweetalert2';
import {FormControl, FormGroup} from '@angular/forms';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-public-cancel',
  templateUrl: './public-cancel.component.html',
  styleUrls: ['./public-cancel.component.scss']
})
export class PublicCancelComponent implements OnInit {
  regid: string;
  registrantCancelData: RegistrantCancelData;
  publicRegistrationCancelForm: FormGroup;
  isSubmitLoading: boolean = false;
  isLoading: boolean = false;

  constructor(private route: ActivatedRoute, private publicApiService: PublicApiService, private router: Router, private titleService: Title) {
    this.titleService.setTitle("Mass Registration Self-Cancellation | Visitation Seremban");
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.subscribe(
      (params: Params) => {
        this.regid = params.regid;
        this.publicApiService.cancelRegistrantData(this.regid).subscribe(resData =>{
          this.isLoading = false;
          this.registrantCancelData = resData;
        }, errorMessage =>{
          this.isLoading = false;
          this.sweetAlert('Opps...', errorMessage.error.message);
          this.router.navigate(['/status'], {relativeTo: this.route});
        })
      });
    this.initForm();
  }

  sweetAlert(title: string, text: string) {
    Swal.fire({
      title: title,
      html: text,
      imageUrl: 'https://visitationseremban.org/images/11_OTHERS/MassRegistration/error-jesus.webp',
      imageHeight: 200,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    });
  }

  onSubmit() {
    let reg_pp = this.publicRegistrationCancelForm.value.reg_pp;
    let reg_tnc = this.publicRegistrationCancelForm.value.reg_tnc;
    if (!reg_pp) {
      this.sweetAlert('Opps...', 'Please agree to our Privacy Policy Notice and Terms of Use <br>(Please tick the checkbox)');
      return;
    }
    if (!reg_tnc) {
      this.sweetAlert('Opps...', 'Please agree to our Terms and Conditions <br>(Please tick the checkbox)');
      return;
    }
    this.isSubmitLoading = true;
    const jsonBody: Object = {
      'reg_id': this.registrantCancelData.reg_id,
      'mass_id': this.registrantCancelData.mass_id
    };
    this.publicApiService.cancelRegistrant(jsonBody).subscribe(resData =>{
      this.isSubmitLoading = false;
      Swal.fire({
        title: 'Successful!',
        text: resData.message,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
      this.router.navigate(['/status'], {relativeTo: this.route});
    }, errorMessage =>{
      this.isSubmitLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  private initForm(){
    this.publicRegistrationCancelForm = new FormGroup({
      reg_pp: new FormControl(false),
      reg_tnc: new FormControl(false)
    });
  }
}
