import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {User} from '@/model/user.model';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, OnDestroy {
  private userSub: Subscription;
  user: User;

  constructor(public appService: AppService) {
  }

  ngOnInit() {
    this.userSub = this.appService.user.subscribe(user => {
      this.user = user;
    });
  }

  ngOnDestroy(): void {
    this.userSub.unsubscribe();
  }
}
