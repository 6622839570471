import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-public-retired',
  templateUrl: './public-retired.component.html',
  styleUrls: ['./public-retired.component.scss']
})
export class PublicRetiredComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    setTimeout(function(){
      window.location.href = 'https://www.visitationseremban.org';
    }, 10000);
  }

}
