import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Profile} from '@/model/profile.model';
import {ApiService} from '@services/api.service';
import {ProfileService} from '@pages/profile/profile.service';

@Injectable({providedIn: 'root'})
export class ProfileResolverService implements Resolve<Profile>{
  constructor(private profileService: ProfileService, private apiService: ApiService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Profile> | Promise<Profile> | Profile {
    if (!this.profileService.profile.getValue()){
      this.apiService.fetchProfileDetails();
    } else{
      return this.profileService.profile.getValue();
    }
  }
}
