import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MassService} from '@pages/dashboard/mass.service';
import {Mass} from '@/model/mass.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {map, tap} from 'rxjs/operators';
import Swal from 'sweetalert2';
import {ApiService} from '@services/api.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { formatDate } from '@angular/common';
import { v4 as uuidv4 } from 'uuid';
import {ToastrService} from 'ngx-toastr';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mass-edit',
  templateUrl: './mass-edit.component.html',
  styleUrls: ['./mass-edit.component.scss']
})
export class MassEditComponent implements OnInit {
  @ViewChild('content', {static: true}) copyMassRef: ElementRef;
  isLoading: boolean = false;
  isUpdateLoading: boolean = false;
  massid: string;
  mass: Mass;
  editMassForm: FormGroup;
  editMode = false;
  visibilities = ['P', 'S'];
  yesNo = ['Y', 'N'];

  massRestrictionForm: FormGroup;
  massRestrictionFormVisible: boolean = false;
  availableMassRestriction: {mass_id: string, mass_desc: string, mass_lang: string, mass_datetime: string}[] = [];
  massRestrictions: {restrict_mass_id: string, mass_desc: string, mass_lang: string, mass_datetime: string}[] = [];
  isAssignLoading: boolean = false;

  copyMassForm: FormGroup;
  copyMassModalRef : NgbModalRef;
  allMasses: Mass[] = [];
  copyMassMode: boolean = false;

  constructor(private massService: MassService, private route: ActivatedRoute, private apiService: ApiService, private router: Router, private toastr: ToastrService, private modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.route.params.subscribe(
      (params: Params) => {
        this.massid = params.massid;
        this.editMode = params.massid != null;
        if(this.editMode){
          this.apiService.fetchSingleMass(this.massid).pipe(map(
            mass => {
              return {...mass};
            }
            ),
            tap(mass => {
              this.mass = mass;
            })).subscribe(resData => {
              this.isLoading = false;
              this.initForm();
              this.getMassRestrictions();
          }, errorMessage => {
            this.isLoading = false;
            Swal.fire('Opps...', errorMessage.error.message, 'error').then(() => {
              this.router.navigate(['/admin/dashboard']);
            });
          });
        }else{
          this.isLoading = false;
          this.getAllMasses();
          this.initForm();
        }
      }
    )
  }

  private getMassRestrictions(){
    this.apiService.fetchMassRestrictions(this.massid).subscribe(resData =>{
      this.massRestrictions = resData;
    }, errorMessage => {
      Swal.fire('Opps...', errorMessage.error.message, 'error').then(() => {
        this.router.navigate(['/admin/dashboard']);
      })
      console.log(errorMessage.error.message);
    })
  }

  showRestrictionForm(){
    this.apiService.fetchAvailableMassRestrictions(this.massid).subscribe(resData => {
      this.massRestrictionFormVisible = true;
      this.availableMassRestriction = resData;
      this.massRestrictionForm = new FormGroup({
        mass_restriction: new FormControl(this.availableMassRestriction, Validators.required)
      });
    },errorMessage =>{
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onAssignMassRestriction(){
    this.isAssignLoading = true;
    const jsonBody: Object ={
      "mass_id" : this.massid,
      "restrict_mass_id" : this.massRestrictionForm.value.mass_restriction
    }
    this.apiService.assignMassRestriction(jsonBody).subscribe(resData => {
      this.isAssignLoading = false;
      this.toastr.success('Restriction assigned successfully');
      this.getMassRestrictions();
      this.onCloseMassRestriction();
    },errorMessage =>{
      this.isAssignLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  onCloseMassRestriction(){
    this.massRestrictionFormVisible = false;
  }

  onDeleteMassRestriction(restrict_mass_id: string){
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, remove it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const jsonBody: Object ={
          "mass_id" : this.massid,
          "restrict_mass_id" : restrict_mass_id
        }
        this.apiService.removeMassRestriction(jsonBody).subscribe(resData => {
          this.toastr.success('Restriction removed');
          this.getMassRestrictions();
        },errorMessage =>{
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        })
      }
    })
  }

  onSubmit() {
    this.isUpdateLoading = true;
    const jsonBody: Object ={
      "mass_id" : this.editMode ? this.massid: uuidv4(),
      "mass_datetime" : this.editMassForm.value.mass_datetime,
      "mass_desc" : this.editMassForm.value.mass_desc,
      "mass_visibility" : this.editMassForm.value.mass_visibility,
      "mass_available" : this.editMassForm.value.mass_available,
      "mass_allowcancel" : this.editMassForm.value.mass_allowcancel,
      "mass_prefix" : this.editMassForm.value.mass_prefix,
      "mass_lang" : this.editMassForm.value.mass_lang,
      "mass_maxcap" : this.editMassForm.value.mass_maxcap,
      "mass_minage" : this.editMassForm.value.mass_minage,
      "mass_maxage" : this.editMassForm.value.mass_maxage,
      "mass_lastmincap" : this.editMassForm.value.mass_lastmincap,
      "mass_starttext" : this.editMassForm.value.mass_starttext,
      "mass_endtext" : this.editMassForm.value.mass_endtext,
      "mass_startdatetime" : this.editMassForm.value.mass_startdatetime,
      "mass_enddatetime" : this.editMassForm.value.mass_enddatetime,
      "mass_startcheckin" : this.editMassForm.value.mass_startcheckin,
      "mass_endcheckin" : this.editMassForm.value.mass_endcheckin,
      "mass_api" : this.editMassForm.value.mass_api,
      "mass_archived" : this.editMassForm.value.mass_archived,
      "mass_selfcheckstartdatetime" : this.editMassForm.value.mass_selfcheckstartdatetime,
      "mass_selfcheckenddatetime" : this.editMassForm.value.mass_selfcheckenddatetime,
      "mass_checkin" : this.editMassForm.value.mass_checkin,
      "mass_volunteer" : this.editMassForm.value.mass_volunteer,
      "mass_checkinbuffer" : this.editMassForm.value.mass_checkinbuffer,
    }
    if(this.editMode){
      this.apiService.updateSingleMass(jsonBody).subscribe(resData =>{
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: "Mass is updated!",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.apiService.fetchAllMasses().subscribe();
          this.router.navigate(['../'], {relativeTo: this.route});
        })
      }, errorMessage =>{
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      })
    }else{
      console.log(jsonBody);
      this.apiService.createSingleMass(jsonBody).subscribe(resData =>{
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: "Mass is created!",
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.apiService.fetchAllMasses().subscribe();
          this.router.navigate(['/admin/dashboard']);
        })
      }, errorMessage =>{
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      })
    }

  }

  onClose() {
    if(!this.editMassForm.dirty){
      this.router.navigate(['../'], {relativeTo: this.route});
    }else{
      Swal.fire({
        title: 'Leave without saving?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, leave without saving!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['../'], {relativeTo: this.route});
        }
      })
    }
  }

  private initForm() {
    let mass_desc = '';
    let mass_lang = '';
    let mass_datetime = '';
    let mass_visibility = '';
    let mass_available = '';
    let mass_allowcancel = '';
    let mass_prefix = '';
    let mass_maxcap = '';
    let mass_lastmincap = '';
    let mass_minage = '';
    let mass_maxage = '';
    let mass_api = '';
    let mass_startdatetime = '';
    let mass_enddatetime = '';
    let mass_selfcheckstartdatetime = '';
    let mass_selfcheckenddatetime = '';
    let mass_startcheckin = '';
    let mass_endcheckin = '';
    let mass_starttext = '';
    let mass_endtext = '';
    let mass_checkin = '';
    let mass_volunteer = '';
    let mass_archived = '';
    let mass_checkinbuffer = '';

    if(this.editMode){
      mass_desc = this.mass.mass_desc;
      mass_lang = this.mass.mass_lang;
      mass_datetime = formatDate(this.mass.mass_datetime, 'yyyy-MM-ddTHH:mm', 'en');
      mass_visibility = this.mass.mass_visibility;
      mass_available = this.mass.mass_available;
      mass_allowcancel = this.mass.mass_allowcancel;
      mass_prefix = this.mass.mass_prefix;
      mass_maxcap = this.mass.mass_maxcap;
      mass_lastmincap = this.mass.mass_lastmincap;
      mass_minage = this.mass.mass_minage;
      mass_maxage = this.mass.mass_maxage;
      mass_api = this.mass.mass_api;
      mass_startdatetime = this.mass.mass_startdatetime == '-' ? null : formatDate(this.mass.mass_startdatetime, 'yyyy-MM-ddTHH:mm', 'en');
      mass_enddatetime = this.mass.mass_enddatetime == '-' ? null : formatDate(this.mass.mass_enddatetime, 'yyyy-MM-ddTHH:mm', 'en');
      mass_selfcheckstartdatetime = this.mass.mass_selfcheckstartdatetime == '-' ? null : formatDate(this.mass.mass_selfcheckstartdatetime, 'yyyy-MM-ddTHH:mm', 'en');
      mass_selfcheckenddatetime = this.mass.mass_selfcheckenddatetime == '-' ? null : formatDate(this.mass.mass_selfcheckenddatetime, 'yyyy-MM-ddTHH:mm', 'en');
      mass_startcheckin = this.mass.mass_startcheckin == '-' ? null : formatDate(this.mass.mass_startcheckin, 'yyyy-MM-ddTHH:mm', 'en');
      mass_endcheckin = this.mass.mass_endcheckin == '-' ? null : formatDate(this.mass.mass_endcheckin, 'yyyy-MM-ddTHH:mm', 'en');
      mass_starttext = this.mass.mass_starttext;
      mass_endtext = this.mass.mass_endtext;
      mass_checkin = this.mass.mass_checkin;
      mass_volunteer = this.mass.mass_volunteer;
      mass_archived = this.mass.mass_archived;
      mass_checkinbuffer = this.mass.mass_checkinbuffer;
    }
    this.editMassForm = new FormGroup({
      mass_desc: new FormControl(mass_desc, Validators.required),
      mass_lang: new FormControl(mass_lang, Validators.required),
      mass_datetime: new FormControl(mass_datetime, Validators.required),
      mass_visibility: new FormControl(mass_visibility, Validators.required),
      mass_available: new FormControl(mass_available, Validators.required),
      mass_allowcancel: new FormControl(mass_allowcancel, Validators.required),
      mass_prefix: new FormControl(mass_prefix, Validators.required),
      mass_maxcap: new FormControl(mass_maxcap, Validators.required),
      mass_lastmincap: new FormControl(mass_lastmincap, Validators.required),
      mass_minage: new FormControl(mass_minage, [Validators.required, Validators.min(0), Validators.max(109)]),
      mass_maxage: new FormControl(mass_maxage, [Validators.required, Validators.min(1), Validators.max(110)]),
      mass_api: new FormControl(mass_api, Validators.required),
      mass_startdatetime: new FormControl(mass_startdatetime, Validators.required),
      mass_enddatetime: new FormControl(mass_enddatetime, Validators.required),
      mass_selfcheckstartdatetime: new FormControl(mass_selfcheckstartdatetime, Validators.required),
      mass_selfcheckenddatetime: new FormControl(mass_selfcheckenddatetime, Validators.required),
      mass_startcheckin: new FormControl(mass_startcheckin, Validators.required),
      mass_endcheckin: new FormControl(mass_endcheckin, Validators.required),
      mass_starttext: new FormControl(mass_starttext),
      mass_endtext: new FormControl(mass_endtext),
      mass_checkin: new FormControl(mass_checkin, Validators.required),
      mass_volunteer: new FormControl(mass_volunteer, Validators.required),
      mass_archived: new FormControl(mass_archived, Validators.required),
      mass_checkinbuffer: new FormControl(mass_checkinbuffer, Validators.required),
    })
  }

  onDelete(){
    Swal.fire({
      title: 'Delete this Mass?',
      text: "All registrants' data will be deleted and you won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        const jsonBody: Object = {
          "mass_id" : this.massid
        }
        this.apiService.deleteMass(jsonBody).subscribe(resData =>{
          this.isLoading = false;
          Swal.fire({
            title: 'Good job!',
            text: "Mass is deleted!",
            icon: 'success',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          }).then((result) => {
            this.apiService.fetchAllMasses().subscribe();
            this.router.navigate(['/admin/dashboard']);
          });
        }, errorMessage =>{
          this.isLoading = false;
          Swal.fire({
            title: 'Opps...',
            text: errorMessage.error.message,
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK'
          });
        })
      }
    })

  }

  getAllMasses(){
    this.apiService.fetchArchivedMasses().subscribe(resData => {
      this.allMasses = resData;
      this.copyMassForm = new FormGroup({
        archived_mass: new FormControl(this.allMasses, Validators.required)
      });
      this.open();
    },errorMessage =>{
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  closeCopyMassModal(){
    this.copyMassModalRef.close();
  }

  onCopyMass(){
    let copyMassIndex = this.copyMassForm.value.archived_mass;
    this.copyMassMode = true;
    this.mass = this.allMasses[copyMassIndex];
    this.editMassForm.patchValue({
      mass_desc : this.mass.mass_desc,
      mass_lang : this.mass.mass_lang,
      mass_datetime : formatDate(this.mass.mass_datetime, 'yyyy-MM-ddTHH:mm', 'en'),
      mass_visibility : this.mass.mass_visibility,
      mass_available : this.mass.mass_available,
      mass_allowcancel : this.mass.mass_allowcancel,
      mass_prefix : this.mass.mass_prefix,
      mass_maxcap : this.mass.mass_maxcap,
      mass_lastmincap : this.mass.mass_lastmincap,
      mass_minage : this.mass.mass_minage,
      mass_maxage : this.mass.mass_maxage,
      mass_api : this.mass.mass_api,
      mass_startdatetime : this.mass.mass_startdatetime == '-' ? null : formatDate(this.mass.mass_startdatetime, 'yyyy-MM-ddTHH:mm', 'en'),
      mass_enddatetime : this.mass.mass_enddatetime == '-' ? null : formatDate(this.mass.mass_enddatetime, 'yyyy-MM-ddTHH:mm', 'en'),
      mass_selfcheckstartdatetime : this.mass.mass_selfcheckstartdatetime == '-' ? null : formatDate(this.mass.mass_selfcheckstartdatetime, 'yyyy-MM-ddTHH:mm', 'en'),
      mass_selfcheckenddatetime : this.mass.mass_selfcheckenddatetime == '-' ? null : formatDate(this.mass.mass_selfcheckenddatetime, 'yyyy-MM-ddTHH:mm', 'en'),
      mass_startcheckin : this.mass.mass_startcheckin == '-' ? null : formatDate(this.mass.mass_startcheckin, 'yyyy-MM-ddTHH:mm', 'en'),
      mass_endcheckin : this.mass.mass_endcheckin == '-' ? null : formatDate(this.mass.mass_endcheckin, 'yyyy-MM-ddTHH:mm', 'en'),
      mass_starttext : this.mass.mass_starttext,
      mass_endtext : this.mass.mass_endtext,
      mass_checkin : this.mass.mass_checkin,
      mass_volunteer : this.mass.mass_volunteer,
      mass_archived : 'N',
      mass_checkinbuffer: this.mass.mass_checkinbuffer
    })
    this.closeCopyMassModal();
  }

  open(){
    this.copyMassModalRef = this.modalService.open(this.copyMassRef, {
      ariaLabelledBy: 'modalCopyMassDetails',
      scrollable: true
    });
    this.copyMassModalRef.hidden.subscribe(event => {
      this.copyMassForm.reset();
    })
  }
}
