import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApiService} from '@services/api.service';
import Swal from 'sweetalert2';
import {ZoneService} from '@pages/seating-zone/zone.service';
import {Subject} from 'rxjs';
import {DataTableDirective} from 'angular-datatables';
import {NgbActiveModal, NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {v4 as uuidv4} from 'uuid';
import {Seat} from '@/model/seat.model';
import {User} from '@/model/user.model';
import {AppService} from '@services/app.service';

@Component({
  selector: 'generated-seats-modal-content',
  template: `
    <div class="modal-header">
      <h4 class="modal-title">Generated Seats</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <table class="table table-bordered table-striped">
        <thead>
        <tr>
          <th>UUID</th>
          <th>Seat Number</th>
          <th>Status</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor='let seat of seats'>
          <td>{{ seat.seat_id }}</td>
          <td>{{ seat.seat_no }}</td>
          <td>{{ seat.seat_status }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="activeModal.close('generate')">Submit</button>
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
    </div>
  `
})
export class GeneratedSeatsModalContent {
  @Input() seats: Seat[];

  constructor(public activeModal: NgbActiveModal) {}


}

@Component({
  selector: 'app-seating-zone-edit',
  templateUrl: './seating-zone-edit.component.html',
  styleUrls: ['./seating-zone-edit.component.scss']
})
export class SeatingZoneEditComponent implements OnInit, OnDestroy {
  user: User;
  id: number;
  editMode: boolean = false;
  editZoneForm: FormGroup;
  isUpdateLoading: boolean = false;
  zone: {zone_id: string, zone_colour: string, zone_desc: string, zone_nostart: string, zone_noend: string};

  isInitialLoading: boolean = false;
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, {static: false})
  dtElement: DataTableDirective;
  seatList: Seat[] = [];

  initialLoad: boolean = true;

  generateSeatForm: FormGroup;
  generateSeatModalRef : NgbModalRef;

  constructor(private route: ActivatedRoute, private zoneService: ZoneService, private apiService: ApiService, private router: Router,private modalService: NgbModal, private appService: AppService) { }

  ngOnInit(): void {
    this.user = this.appService.user.getValue();
    this.isInitialLoading = true;
    this.route.params.subscribe(
      (params: Params) => {
        this.id = +params.id;
        this.editMode = params.id != null;
        this.initForm();
      });
    this.dtOptions ={
      responsive: true,
      autoWidth: false,
      columnDefs: [
        { responsivePriority: 0, targets: -1 }
      ],
      dom: 'Bfrtipl',
      order: [],
      buttons: ["copy",
        {
          extend: 'csvHtml5',
          title: 'MRS_Seats'
        },
        {
          extend: 'excelHtml5',
          title: 'MRS_Seats'
        },
        {
          extend: 'pdfHtml5',
          messageBottom: 'Church of the Visitation Seremban. All Rights Reserved.',
          title: 'MRS_All_Tags'
        },
        "print",
        "colvis"]
    }
  }

  initForm(){
    let zone_colour = '';
    let zone_desc = '';
    let zone_nostart = '';
    let zone_noend = '';
    if(this.editMode){
      this.zone = this.zoneService.getZone(this.id);
      zone_colour = this.zone.zone_colour;
      zone_desc = this.zone.zone_desc;
      zone_nostart = this.zone.zone_nostart;
      zone_noend = this.zone.zone_noend;
      this.fetchSeats();
      this.initGenerateSeatsForm();
    }
    this.editZoneForm = new FormGroup({
      zone_colour: new FormControl(zone_colour, Validators.required),
      zone_desc: new FormControl(zone_desc, Validators.required),
      zone_nostart: new FormControl(zone_nostart, [Validators.required, Validators.min(0)]),
      zone_noend: new FormControl(zone_noend, [Validators.required, Validators.min(0)]),
    });
  }

  initGenerateSeatsForm(){
    this.generateSeatForm = new FormGroup({
      generate_startno: new FormControl(this.zone.zone_nostart, [Validators.required, Validators.min(0)]),
      generate_endno: new FormControl(this.zone.zone_noend, [Validators.required, Validators.min(0)]),
      generate_defaultstatus: new FormControl('', Validators.required)
    })
  }

  onSubmit(){
    this.isUpdateLoading = true;
    if(this.editMode){
      const jsonBody: Object ={
        "zone_colour" : this.editZoneForm.value.zone_colour,
        "zone_desc" : this.editZoneForm.value.zone_desc,
        "zone_nostart" : this.editZoneForm.value.zone_nostart,
        "zone_noend" : this.editZoneForm.value.zone_noend,
        "zone_id" : this.zone.zone_id
      }
      this.apiService.updateSingleZone(jsonBody).subscribe(resData => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: 'Group updated!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['../'], {relativeTo: this.route});
        });
      }, errorMessage => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      });
    }else{
      const jsonBody: Object ={
        "zone_colour" : this.editZoneForm.value.zone_colour,
        "zone_desc" : this.editZoneForm.value.zone_desc,
        "zone_nostart" : this.editZoneForm.value.zone_nostart,
        "zone_noend" : this.editZoneForm.value.zone_noend,
      }
      this.apiService.createSingleZone(jsonBody).subscribe(resData => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Good job!',
          text: 'Zone created!',
          icon: 'success',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['../'], {relativeTo: this.route});
        });
      }, errorMessage => {
        this.isUpdateLoading = false;
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        });
      });
    }
  }

  onClose(){
    if(!this.editZoneForm.dirty){
      this.router.navigate(['../'], {relativeTo: this.route});
    }else{
      Swal.fire({
        title: 'Leave without saving?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, leave without saving!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigate(['../'], {relativeTo: this.route});
        }
      })
    }
  }

  openGenerateSeatModal(content) {
    this.generateSeatModalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modalGenerateSeat',
      scrollable: true
    });
  }

  closeGenerateSeatModal(){
    this.generateSeatModalRef.close();
  }

  onGenerateSeats(){
    let seatNumberBegin = +this.generateSeatForm.value.generate_startno;
    let seatNumberEnd = +this.generateSeatForm.value.generate_endno;
    let seatStatus = this.generateSeatForm.value.generate_defaultstatus;
    let newSeats: Seat[] = [];
    for(let i = seatNumberBegin; i <= seatNumberEnd; i++){
      let newSeat =  new Seat(uuidv4(), i, seatStatus);
      newSeats.push(newSeat);
    }
    this.closeGenerateSeatModal();
    const modalRef = this.modalService.open(GeneratedSeatsModalContent, { size: 'xl',scrollable: true });
    modalRef.componentInstance.seats = newSeats;

    modalRef.result.then(
      (data: any) => {
        if(data == 'generate'){
          const jsonBody: Object = {
            "generated_seats" : newSeats,
            "zone_id" : this.zone.zone_id
          }
          this.apiService.insertGeneratedSeats(jsonBody).subscribe(resData =>{
            Swal.fire({
              title: 'Done!',
              html: resData.message,
              icon: 'success',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            });
            this.closeGenerateSeatModal();
            this.fetchSeats();
          }, errorMessage =>{
            Swal.fire({
              title: 'Opps...',
              html: errorMessage.error.message,
              icon: 'error',
              showCancelButton: false,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'OK'
            });
          })
        }
      },
      (reason: any) => { });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  fetchSeats(){
    this.apiService.fetchSeats(this.zone.zone_id).subscribe(resData =>{
      this.isInitialLoading = false;
      this.seatList = resData;
      if(this.initialLoad){
        this.dtTrigger.next();
        this.initialLoad = false;
      }else{
        this.rerender();
      }
    }, errorMessage => {
      this.isInitialLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }

  changeStatusTo(seat_id: string, status: string){
    const jsonBody: Object = {
      "seat_id" : seat_id,
      "seat_status" : status
    }
    this.apiService.updateSeatStatus(jsonBody).subscribe(resData =>{
      this.fetchSeats();
    }, errorMessage => {
      this.isInitialLoading = false;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    })
  }

}
