import { Component, OnInit } from '@angular/core';
import {Mass} from '@/model/mass.model';
import {Subscription} from 'rxjs';
import {MassService} from '@pages/dashboard/mass.service';
import {ApiService} from '@services/api.service';

@Component({
  selector: 'app-mass-list',
  templateUrl: './mass-list.component.html',
  styleUrls: ['./mass-list.component.scss']
})
export class MassListComponent implements OnInit {
  masses: Mass[] = [];
  massSub: Subscription;
  private massRefreshTimer: any;

  constructor(private massService: MassService, private apiService: ApiService) {
  }

  ngOnInit(): void {
    this.massSub = this.massService.massesChanged.subscribe(
      (masses: Mass[]) =>{
        this.masses = masses;
      }
    )
    this.refreshMasses()
  }

  ngOnDestroy(): void {
    this.massSub.unsubscribe();
    clearInterval(this.massRefreshTimer);
  }

  refreshMasses(){
    this.massRefreshTimer = setInterval(() => {
      this.apiService.fetchAllMasses().subscribe();
    }, 30000);
  }

}
