import {Component, OnInit} from '@angular/core';
import {Form, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PublicApiService} from '@services/public-api.service';
import {map, tap} from 'rxjs/operators';
import {PublicMass} from '@/model/public-mass.model';
import Swal from 'sweetalert2';
import {v4 as uuidv4} from 'uuid';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-public-register',
  templateUrl: './public-register.component.html',
  styleUrls: ['./public-register.component.scss']
})
export class PublicRegisterComponent implements OnInit {
  publicRegistrationForm: FormGroup;
  publicEmailForm: FormGroup;
  isEmailLoading: boolean = false;
  isSuccess: boolean = false;
  isLoading: boolean = true;
  isSubmitLoading: boolean = false;
  specificMode: boolean = false;
  errorMessage: string = '';
  massList: PublicMass[] = [];
  selectedPublicMass: PublicMass;
  massid: string;
  regString: {regCode: string, regId: string, regName: string, email: string} = {regCode: 'E23', regId: '123456789', regName: 'string', email: 'string'};
  LOCAL_STORAGE_KEY: string = 'VISITATION_MRS';
  massLabel: string = 'Please select a Mass time';


  constructor(private route: ActivatedRoute, private publicApiService: PublicApiService, private router: Router, private titleService: Title) {
    this.titleService.setTitle("Mass Registration | Visitation Seremban");
  }

  ngOnInit(): void {
    this.route.params.subscribe(
      (params: Params) => {
        this.massid = params.massid;
        this.specificMode = params.massid != null;
        this.initForm();
        if (this.specificMode) {
          this.massLabel = 'Selected Mass';
          this.prepareSingleMassList();
        } else {
          this.preparePublicMassList();
        }
      });
  }

  messageBeforeInit() {
    Swal.fire({
      title: 'Alert!',
      html: '<div style=\'text-align: left\'><h4><strong>Criteria to attend Mass:</strong></h4><ul>' +
        '<li>If you are 12 years old and above: You <b>must be vaccinated</b> with 2 doses of Pfizer/ Astrazeneca/ Sinovac vaccine and completed at least 14 days from the date of the second dose OR 1 dose of Johnson & Johnson/ Cansino vaccine and completed at least 28 days from the date of vaccination.' +
        ' </li><li>Children below 12 years old and not vaccinated MUST be accompanied by at least one fully vaccinated parent.</li>' +
        '</ul><p class="text-center"><strong>Online pre-registration is required for all attendess including children who are below 12 years old.</strong></p></div>',
      showDenyButton: true,
      confirmButtonColor: '#3085d6',
      denyButtonColor: '#d33',
      denyButtonText: 'Go Back',
      confirmButtonText: 'Continue'
    }).then((result) => {
      if (!result.isConfirmed) {
        window.location.href = 'http://visitationseremban.org';
      }else{
        Swal.fire({
          title: 'Alert!',
          html: '<div style=\'text-align: left\'><h4 class="text-center"><strong>Check your MySejahtera Now!</strong></h4>' +
            '<img class="img-fluid" src="https://visitationseremban.org/images/11_OTHERS/MassRegistration/RiskCategory.jpg"></div>',
          showDenyButton: true,
          confirmButtonColor: '#3085d6',
          denyButtonColor: '#d33',
          denyButtonText: 'Go Back',
          confirmButtonText: 'Continue'
        }).then((result) => {
          if (!result.isConfirmed) {
            window.location.href = 'http://visitationseremban.org';
          }
        })
      }
    });
  }

  sweetAlert(title: string, text: string) {
    Swal.fire({
      title: title,
      html: text,
      imageUrl: 'https://visitationseremban.org/images/11_OTHERS/MassRegistration/error-jesus.webp',
      imageHeight: 200,
      showCancelButton: false,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK'
    });
  }

  preparePublicMassList() {
    this.publicApiService.fetchAvailablePublicMasses().pipe(
      map(masses => {
        return masses.map(mass => {
          return {...mass};
        });
      }),
      tap(masses => {
        this.massList = masses;
      })
    ).subscribe(resData => {
      this.isLoading = false;
      if (this.massList.length == 0) {
        this.errorMessage = "Registration is closed!<br>";
      } else {
        this.messageBeforeInit();
      }
    }, errorMessage => {
      this.isLoading = false;
      this.errorMessage = errorMessage.error.message;
      Swal.fire({
        title: 'Opps...',
        text: errorMessage.error.message,
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
    });
  }

  prepareSingleMassList() {
    this.publicApiService.fetchSinglePublicMass(this.massid).pipe(
      map(masses => {
        return masses.map(mass => {
          return {...mass};
        });
      }),
      tap(masses => {
        this.massList = masses;
      })
    ).subscribe(resData => {
      this.isLoading = false;
      if (this.massList.length == 0) {
        Swal.fire({
          title: 'Opps...',
          html: '<strong>Sorry, It seems that the mass you are trying to register is invalid. </strong><br>Please select another mass time.',
          imageUrl: 'https://visitationseremban.org/images/11_OTHERS/MassRegistration/error-jesus.webp',
          imageHeight: 200,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['/register']);
        });
      } else {
        this.messageBeforeInit();
        this.publicRegistrationForm.patchValue({
          reg_mass: this.massid
        });
      }
    }, errorMessage => {
      this.isLoading = false;
      this.errorMessage = errorMessage.error.message;
      if(errorMessage.error.error == '421'){
        this.router.navigate(['/register']);
      }else{
        Swal.fire({
          title: 'Opps...',
          text: errorMessage.error.message,
          imageUrl: 'https://visitationseremban.org/images/11_OTHERS/MassRegistration/error-jesus.webp',
          imageHeight: 200,
          showCancelButton: false,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.router.navigate(['/register']);
        });
      }

    });
  }

  displayEstimateSeats(mass_registered: number, mass_maxcap: number) {
    let numberRegister = mass_maxcap - mass_registered;
    if (numberRegister < 15) {
      return 'less than 15';
    } else if (numberRegister < 50) {
      return 'less than 50';
    } else if (numberRegister < 100) {
      return 'less than 100';
    } else {
      return 'more than 100';
    }
  }

  onSubmit() {
    let reg_mass = this.publicRegistrationForm.value.reg_mass;
    let reg_name = this.publicRegistrationForm.value.reg_name.trim();
    let reg_birthdate = this.publicRegistrationForm.value.reg_birthdate;
    let reg_phone = this.publicRegistrationForm.value.reg_phone.trim();
    let reg_bec = this.publicRegistrationForm.value.reg_bec.trim();
    let reg_email = this.publicRegistrationForm.value.reg_email;
    let reg_remarks = this.publicRegistrationForm.value.reg_remarks.trim();
    let reg_pp = this.publicRegistrationForm.value.reg_pp;
    let reg_tnc = this.publicRegistrationForm.value.reg_tnc;
    let reg_health = this.publicRegistrationForm.value.reg_health;
    // let reg_vaccine = this.publicRegistrationForm.value.reg_vaccine;

    if (!reg_mass) {
      this.sweetAlert('Opps...', 'Please select a Mass first.');
      return;
    }
    this.selectedPublicMass = this.massList.find(element => element.mass_id == reg_mass);
    if (!reg_name || reg_name.length < 6) {
      this.sweetAlert('Opps...', 'Please enter your FULL Name.');
      return;
    }
    if (/\d/g.test(reg_name)) {
      this.sweetAlert('Opps...', 'Name cannot contain numbers.<br><strong>Reminder:</strong><br> Please enter your Full Name (Not your NRIC Number)');
      return;
    }
    if (reg_name.split(' ').length === 1) {
      this.sweetAlert('Opps...', 'Name cannot contain only one word<br><strong>Reminder:</strong><br>Please enter your FULL Name (Include your Surname or Last Name)');
      return;
    }
    if (!reg_birthdate) {
      this.sweetAlert('Opps...', 'Please enter your birthdate.');
      return;
    }
    if (!this.validatedate(reg_birthdate)) {
      this.sweetAlert('Opps...', 'Please enter a valid birthdate.<br><strong>Reminder:</strong><br> Format: YYYY/MM/DD');
      return;
    }
    const convertAge = new Date(reg_birthdate);
    const timeDiff = Math.abs(Date.now() - convertAge.getTime());
    const age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365);
    if (age < +this.selectedPublicMass.mass_minage || age > +this.selectedPublicMass.mass_maxage) {
      this.sweetAlert('Opps...', 'Please enter a valid birthdate.<br><strong>Reminder:</strong><br> Format: YYYY/MM/DD');
      return;
    }
    if (/\+60[0-9]{8,9}/g.test(reg_phone) == false) {
      this.sweetAlert('Opps...', 'Please enter a valid phone number. <br><strong>Guidelines:</strong><br> - Please do not include spaces<br> - Landline Phone Number Example: +6066045678<br> - Mobile Phone Number Example: +60123456789');
      return;
    }
    if (!reg_bec) {
      this.sweetAlert('Opps...', 'Please enter Zone/BEC/Area.<br><strong>Examples</strong><br> - BEC: St. Raphael<br> - Zone: Zone 3:St. Jude <br> - Area: Seremban 2');
      return;
    }
    if (!this.publicRegistrationForm.controls.reg_email.valid) {
      this.sweetAlert('Opps...', 'Please enter a valid email.');
      return;
    }
    if (!reg_pp) {
      this.sweetAlert('Opps...', 'Please agree to our Privacy Policy Notice and Terms of Use <br>(Please tick the checkbox)');
      return;
    }
    if (!reg_tnc) {
      this.sweetAlert('Opps...', 'Please agree to our Terms and Conditions <br>(Please tick the checkbox)');
      return;
    }
    if (!reg_health) {
      this.sweetAlert('Opps...', 'Please declare that you are physically healthy <br>(Please tick the checkbox)');
      return;
    }
    // if (!reg_vaccine) {
    //   this.sweetAlert('Opps...', 'Please declare that you have received your vaccination <br>(Please tick the checkbox)');
    //   return;
    // }
    this.isSubmitLoading = true;
    const jsonBody: Object = {
      'reg_name': reg_name.toUpperCase(),
      'reg_birthdate': reg_birthdate,
      'reg_phone': reg_phone,
      'reg_remarks': reg_remarks,
      'reg_email': reg_email,
      'reg_bec': reg_bec,
      'reg_id': uuidv4(),
      'mass_id': reg_mass
    };
    this.publicApiService.createRegistrantPublic(jsonBody).subscribe(resData => {
      this.isSubmitLoading = false;
      this.isSuccess = true;
      $("html, body").animate({ scrollTop: 0 }, 1000);
      this.regString = {
        'regCode': resData.reg_code,
        'regId': resData.reg_id,
        'regName': reg_name.toUpperCase(),
        'email': reg_email
      };

      if (reg_email !== '') {
        this.publicApiService.emailRegistrationConfirm(resData.reg_id, reg_email).subscribe();
      }

      try {
        if (localStorage.getItem(this.LOCAL_STORAGE_KEY) !== null) {
          let localStorageData = JSON.parse(localStorage.getItem(this.LOCAL_STORAGE_KEY));
          localStorageData.unshift({
            date: this.selectedPublicMass.mass_datetime,
            name: reg_name.toUpperCase(),
            code: (this.selectedPublicMass.mass_prefix + resData.reg_code),
            regId: resData.reg_id,
            mass: this.selectedPublicMass.mass_desc + ' - ' + this.selectedPublicMass.mass_lang
          });
          localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(localStorageData));
        } else {
          localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify([{
            date: this.selectedPublicMass.mass_datetime,
            name: reg_name.toUpperCase(),
            code: (this.selectedPublicMass.mass_prefix + resData.reg_code),
            regId: resData.reg_id,
            mass: this.selectedPublicMass.mass_desc + ' - ' + this.selectedPublicMass.mass_lang
          }]));
        }
      } catch (e) {
        console.log(e);
      }

    }, errorMessage => {
      this.isSubmitLoading = false;
      this.sweetAlert('Opps', errorMessage.error.message);
    });
    // Swal.fire({
    //   title: 'Keeping our Church clean',
    //   html: '<div style=\'text-align: center\'><h4><strong>Please DO NOT stick your seat allocation sticker on the pews!</strong></h4>' +
    //     '<img class="img-fluid" src="/assets/img/NoStick.jpg"></div><p>*Please dispose your sticker properly after Mass.</p>',
    //   showDenyButton: true,
    //   confirmButtonColor: '#3085d6',
    //   denyButtonColor: '#d33',
    //   denyButtonText: 'Who cares?',
    //   confirmButtonText: 'I will keep the church clean'
    // }).then((result) => {
    //   if (!result.isConfirmed) {
    //     this.isSubmitLoading = false;
    //     this.sweetAlert('Opps...', 'Your registration is not processed.<br>You must agree to keep our Church clean.');
    //     return;
    //   }else{
    //
    //   }
    // });

  }

  private initForm() {
    let reg_mass = '';
    let reg_name = '';
    let reg_birthdate = '';
    let reg_phone = '+6';
    let reg_bec = '';
    let reg_email = '';
    let reg_remarks = '';
    let reg_pp = false;
    let reg_tnc = false;
    let reg_health = false;
    // let reg_vaccine = false;
    this.publicRegistrationForm = new FormGroup({
      reg_mass: new FormControl(reg_mass),
      reg_name: new FormControl(reg_name),
      reg_birthdate: new FormControl(reg_birthdate),
      reg_phone: new FormControl(reg_phone),
      reg_bec: new FormControl(reg_bec),
      reg_email: new FormControl(reg_email, Validators.email),
      reg_remarks: new FormControl(reg_remarks),
      reg_pp: new FormControl(reg_pp),
      reg_tnc: new FormControl(reg_tnc),
      reg_health: new FormControl(reg_health)
    });
    this.publicEmailForm = new FormGroup({
      email_confirm: new FormControl('', [Validators.required, Validators.email])
    });
  }

  onEmailSubmit() {
    this.isEmailLoading = true;
    this.publicApiService.emailRegistrationConfirm(this.regString.regId, this.publicEmailForm.value.email_confirm).subscribe(resData => {
      Swal.fire({
        title: 'Email Sent!',
        text: 'Confirmation email sent to ' + this.publicEmailForm.value.email_confirm,
        icon: 'success',
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK'
      });
      this.isEmailLoading = false;
      this.publicEmailForm.reset();
    }, errorMessage => {
      this.isEmailLoading = false;
    });
  }

  reload(){
    window.location.reload();
  }

  validatedate(dateString){
    let dateformat = /^\d{4}[\/](0[1-9]|1[012])[\/](0[1-9]|[12][0-9]|3[01])$/;

    // Match the date format through regular expression
    if(dateString.match(dateformat)){
      let operator = dateString.split('/');

      // Extract the string into month, date and year
      let datepart = [];
      if (operator.length>1){
        datepart = dateString.split('/');
      }
      let month= parseInt(datepart[1]);
      let day = parseInt(datepart[2]);
      let year = parseInt(datepart[0]);

      // Create list of days of a month
      let ListofDays = [31,28,31,30,31,30,31,31,30,31,30,31];
      if (month==1 || month>2){
        if (day>ListofDays[month-1]){
          ///This check is for Confirming that the date is not out of its range
          return false;
        }
      }else if (month==2){
        let leapYear = false;
        if ( (!(year % 4) && year % 100) || !(year % 400)) {
          leapYear = true;
        }
        if ((leapYear == false) && (day>=29)){
          return false;
        }else
        if ((leapYear==true) && (day>29)){
          return false;
        }
      }
    }else{
      return false;
    }
    return true;
  }

}
